import React from "react";
// import "./WelcomePage.scss";
import { Link } from "react-router-dom";
// import { cn } from "../../lib/utils";
// import ShimmerButton from "../../magicui/shimmer-button";
// import { BorderBeam } from "../../magicui/border-beam";
import Logo from "../../Assets/ZOOLY LOGO 2/JUSTX LOGO/JUSTX LOGO.png";
import { motion } from "framer-motion";
import background from "../../Assets/Image/Rectangle 40256.png"

interface WelcomePageProps {
  onNext: () => void
}

const WelcomePage: React.FC<WelcomePageProps> = ({ onNext }) => {
  
    return (
        <motion.div
          className="bg-gradient-to-br from-gray-900 via-gray-950 to-black min-h-screen overflow-hidden relative"
          initial="initial"
          animate="in"
          exit="out"
        >
          {/* Network Visualization Background */}
          <div className="absolute inset-0 opacity-60">
            {/* Add a canvas or SVG here for the network visualization */}
            <img src={background} alt="Background" className="object-cover w-full h-full" />

          </div>
          {/* Main Content */}
          <div className="flex flex-col min-h-screen relative z-10">
            {/* Header */}
            <header className="flex justify-between items-center p-6">
              <img src={Logo} alt="JUSTX" className="w-24" />
              <Link to="/getstarted" onClick={onNext}>
                <button className="bg-teal-300 text-gray-800 font-semibold text-lg px-7 py-2 rounded-md">
                  Launch App
                </button>
              </Link>
            </header>
    
          <div className="absolute top-20 left-0 right-0 h-px bg-gradient-to-r from-transparent via-teal-500 to-transparent opacity-60"></div>
            {/* Centered Content */}
            <main className="flex-grow flex flex-col items-center justify-center text-center px-4">
              <motion.h1 
                className="text-4xl md:text-6xl lg:text-7xl font-bold text-white mb-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                Simplify Crypto with AI
              </motion.h1>
              <motion.p 
                className="text-gray-300 text-xl mb-8 max-w-2xl"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
              >
                The AI-powered SuperApp for seamless, secure interactions across multiple chains and protocols.
              </motion.p>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 }}
              >
                <Link to="/getstarted" onClick={onNext}>
                <button className="bg-teal-300 text-gray-800 font-semibold text-lg px-7 py-2 rounded-md">
                  Launch App
                </button>
                </Link>
              </motion.div>
            </main>

            <div className="absolute bottom-16 left-0 right-0 h-px bg-gradient-to-r from-transparent via-teal-500 to-transparent opacity-60"></div>
    
            {/* Footer */}
            <footer className="text-center p-6 text-gray-400">
              <nav className="space-x-4">
                <a href="/" className="hover:text-white transition-colors">Contact Us</a>
                <a href="/" className="hover:text-white transition-colors">Docs</a>
                <a href="/" className="hover:text-white transition-colors">Privacy Policy</a>
              </nav>
            </footer>
          </div>
        </motion.div>
      );
    };
    
    export default WelcomePage;