import React, { useState, useEffect,useCallback } from 'react';
import { useTheme } from './contexts/ThemeContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider } from './contexts/ThemeContext';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigationType, useLocation, useNavigate } from 'react-router-dom';
import OnboardingPage2 from "./pages/OnboardingPage3/OnboardingPage2";
import './App.css';
import coins from "./Assets/Image/download-removebg-preview.png";
import { getOrGenerateProfilePic } from './utils/profilePicManager';
import ProfilePage from './pages/Profilepage/ProfilePage';
import NewSidebar from './Components/NewSidebar/NewSidebar';
import WelcomePage from './Components/WelcomePage/NewWelcomePage';
import Onboarding1 from './Components/Onboarding1/Onboarding1';
// import Onboarding2 from './Components/Onboarding2/Onboarding2';
// import Onboarding3 from './Components/Onboarding3/Onboarding3';
import UserInfoPage from './Components/UserInfo/UserInfoPage';
import WalletLogin from './Components/walletLogin/WalletLogin';
// import BlockchainDashboard from './Components/NewChatWindows/BlockchainDashboard';
// import Home from './Components/Main/Main';
import MarketPlace from './Components/MarketPlace/MarketPlace';
import ChatPage from './Components/NewChatWindows/UiChatWindowcopy';
// import ChatPage from './Components/NewChatWindows/ServerLoadTest';
// import SolanaChatApp from './Components/NewChatWindows/solana';
// import SendToken from './Components/NewChatWindows/solanasendtokend';
// import cosmos from "./Assets/Image/Consortium_Blockchain-removebg-preview.png";
import WalletPage from './Components/WalletPage/WalletPage';
import Dashboard from './Components/Main/Dashboard';
import SettingsPage from './Components/SettingPage/SettingPage';
// import ChatPage from './pages/ChatPage/ChatPage';
// import AgentComponent from './Components/NewChatWindows/AgentComponents';
import solanaIcon from "./Assets/Image/solana.svg";
import { toast } from 'sonner'; // Assuming you're using Sonner for toasts
interface Agent {
  id: string;
  name: string;
  type: string;
  action: string;
  icon: string;
  description: string;
  longDescription: string;
  version: string;
  developer: string;
  downloadCount: number;
  rating: number;
  reviews: Review[];
  categories: string[];
  releaseDate: string;
  lastUpdate: string;
  size: string;
  compatibility: string[];
  features: string[];
}
export interface Review {
  id: string;
  user: string;
  rating: number;
  comment: string;
  date: string;
}

// Constants
const INACTIVITY_TIMEOUT = 60 * 60 * 1000; // 1 hour in milliseconds
const PUBLIC_ROUTES = ['/welcome', '/getstarted', '/signup', '/login'];

// Custom hook for authentication and inactivity check
const useAuthCheck = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const checkAuth = useCallback(() => {
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    const lastActivityTime = parseInt(localStorage.getItem('lastActivityTime') || '0', 10);
    const currentTime = Date.now();

    if (isAuthenticated && (currentTime - lastActivityTime > INACTIVITY_TIMEOUT)) {
      // User has been inactive for too long
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('lastActivityTime');
      toast.error('Your session has expired due to inactivity. Please log in again.');
      navigate('/login', { replace: true });
      return false;
    }

    if (!isAuthenticated && !PUBLIC_ROUTES.includes(location.pathname)) {
      navigate('/login', { replace: true });
      return false;
    }

    return isAuthenticated;
  }, [navigate, location]);

  useEffect(() => {
    const isAuth = checkAuth();
    if (isAuth) {
      // Update last activity time
      localStorage.setItem('lastActivityTime', Date.now().toString());
    }
  }, [checkAuth]);

  // Set up event listeners for user activity
  useEffect(() => {
    const updateActivity = () => {
      localStorage.setItem('lastActivityTime', Date.now().toString());
    };

    // List of events to listen for
    const events = ['mousedown', 'keydown', 'scroll', 'touchstart'];

    events.forEach(event => window.addEventListener(event, updateActivity));

    return () => {
      events.forEach(event => window.removeEventListener(event, updateActivity));
    };
  }, []);

  return checkAuth;
};

// const useAuthCheck = () => {
//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
//     const publicRoutes = ['/welcome', '/getstarted', '/signup', '/login'];

//     if (!isAuthenticated && !publicRoutes.includes(location.pathname)) {
//       navigate('/login', { replace: true });
//     }
//   }, [navigate, location]);
// };

const App: React.FC = () => {
  const [isOnboardingComplete, setIsOnboardingComplete] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [onboardingStep, setOnboardingStep] = useState<number>(1);
  const [profilePic, setProfilePic] = useState<string>('');
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  
  const checkAuth = useAuthCheck(); // Use the custom hook for authentication check

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);


  useEffect(() => {
    const completedOnboarding = localStorage.getItem('onboardingComplete');
    const loggedIn = localStorage.getItem('isAuthenticated');
    if (completedOnboarding) {
      setIsOnboardingComplete(true);
    }
    if (loggedIn === 'true') {
      setIsAuthenticated(true);
    }
    // Get or generate profile pic
    const userProfilePic = getOrGenerateProfilePic();
    setProfilePic(userProfilePic);
  }, []);

   const completeOnboarding = useCallback(() => {
    setIsOnboardingComplete(true);
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
    localStorage.setItem('onboardingComplete', 'true');
    localStorage.setItem('lastActivityTime', Date.now().toString());
  }, []);

  const handleNextStep = () => {
    setOnboardingStep((prevStep) => (prevStep < 3 ? prevStep + 1 : prevStep));
  };

  const handleLogin = useCallback(() => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
    localStorage.setItem('lastActivityTime', Date.now().toString());
  }, []);

  const handleLogout = useCallback(() => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('lastActivityTime');
  }, []);



  return (
    <ThemeProvider>
    <Routes>
      {!isOnboardingComplete && !isAuthenticated && (
        <>
         {/* <Route path="/" element={<Navigate to="/welcome" />} /> */}
          <Route path="/welcome" element={<WelcomePage onNext={handleNextStep} />} />
          <Route path="/getstarted" element={<Onboarding1 onNext={handleNextStep} />} />
          {/* <Route path="/onboarding2" element={<Onboarding2 onNext={handleNextStep} />} /> */}
          <Route path="/signup" element={<UserInfoPage onNext={completeOnboarding} />} />
          {/* <Route path="/onboarding4" element={<Onboarding3 onNext={handleNextStep} />} /> */}
          <Route path="*" element={<Navigate to="/welcome" />} />

          {/* <Route path="/home" element={<Home onLogout={handleLogout} />} /> */}
        </>
      )}
      {isOnboardingComplete && !isAuthenticated && (
        <>
         <Route path="/login" element={<WalletLogin onLogin={handleLogin} />} />
         <Route path="*" element={<Navigate to="/login" />} />
        </>
      )}
      {isOnboardingComplete && isAuthenticated && (
        <>
          {/* <Route path="/home" element={<Home onLogout={handleLogout} />} /> */}
          <Route path="/pin" element={<OnboardingPage2 onNext={completeOnboarding} />} />
          <Route 
            path="*" 
            element={
              checkAuth() ? (
                <MainApp onLogout={handleLogout} profilePic={profilePic}/>
              ) : (
                <Navigate to="/login" />
              )
            } 
          />
        </>
      )}
    </Routes>
    </ThemeProvider>
  );
};

interface MainAppProps {
  onLogout: () => void;
  profilePic: string;
}
interface Account {
  name: string;
  mnemonic: string;
  address: string;
  balance: string;
  network: string;
}

const MainApp: React.FC<MainAppProps> = ({ onLogout, profilePic }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [defaultAccountIndex, setDefaultAccountIndex] = useState<number>(0);
  const { darkMode } = useTheme();
  // const [AllConnectAgents, setAllConnectAgents] = useState<Agent[]>([]);

  // const handleSelectAgent = (agent: Agent) => {
  //   if (!AllConnectAgents.some(a => a.name === agent.name)) {
  //     setAllConnectAgents([...AllConnectAgents, agent]);
  //   }
  // };
  // In App.tsx


  // Add a periodic check for inactivity
  useEffect(() => {
    const intervalId = setInterval(() => {
      const lastActivityTime = parseInt(localStorage.getItem('lastActivityTime') || '0', 10);
      const currentTime = Date.now();
      if (currentTime - lastActivityTime > INACTIVITY_TIMEOUT) {
        onLogout();
        toast.error('Your session has expired due to inactivity. Please log in again.');
      }
    }, 60000); // Check every minute

    return () => clearInterval(intervalId);
  }, [onLogout]);

  
  useEffect(() => {
    const loadAccounts = async () => {
      const storedAccounts = localStorage.getItem('accounts');
      if (storedAccounts) {
        const parsedAccounts = JSON.parse(storedAccounts);
        setAccounts(parsedAccounts);
        const storedDefaultIndex = localStorage.getItem('defaultAccountIndex');
        setDefaultAccountIndex(storedDefaultIndex ? parseInt(storedDefaultIndex, 10) : 0);
      }
    };

    loadAccounts();
  }, []);
  // const getPersonalAgent = (): Agent => ({
  //   id: '2',
  //   name: 'Personal',
  //   type: 'Agent',
  //   action: 'Added',
  //   icon: cosmos,
  //   description: 'Cosmos is an ever-expanding ecosystem of interconnected apps and services, built for a decentralized future.',
  //   longDescription: 'A detailed description of the personal AI assistant',
  //   version: '1.0',
  //   developer: 'Zooly',
  //   downloadCount: 0,
  //   rating: 5,
  //   reviews: [],
  //   categories: ['AI', 'Personal Assistant'],
  //   releaseDate: '2023-07-13',
  //   lastUpdate: '2023-07-13',
  //   size: '10MB',
  //   compatibility: ['All platforms'],
  //   features: ['AI assistance', 'Task management']
  // });
//   const getCosmosAgent = (): Agent => ({
//   id: '1',
//   name: 'Cosmos',
//   type: 'Productivity',
//   action: 'Added',
//   icon: coins, 
//   description: 'Cosmos is an ever-expanding ecosystem of interconnected apps and services, built for a decentralized future.',
//   longDescription: 'Cosmos is a decentralized network of independent parallel blockchains, each powered by classical BFT consensus algorithms like Tendermint consensus. It aims to create an Internet of Blockchains, a network of blockchains able to communicate with each other in a decentralized way.',
//     version: '1.0',
//     developer: 'Zooly',
//     downloadCount: 0,
//     rating: 5,
//     reviews: [],
//     categories: ['Blockchain', 'Development'],
//     releaseDate: '2023-07-13',
//     lastUpdate: '2023-07-13',
//     size: '10MB',
//     compatibility: ['All platforms'],
//     features: ['AI assistance',  'Smart contracts']

// });
const getSolanaAgent = (): Agent => ({
  id: '3',
  name: 'Solana',
  type: 'Blockchain',
  action: 'Added',
  icon: solanaIcon,
  description: 'Solana is a high-performance blockchain supporting builders around the world creating crypto apps that scale.',
  longDescription: 'Solana is a decentralized blockchain built to enable scalable, user-friendly apps for the world. Its known for its fast transaction speeds and low costs.',
  version: '1.0',
  developer: 'Solana Foundation',
  downloadCount: 0,
  rating: 5,
  reviews: [],
  categories: ['Blockchain', 'DeFi', 'Smart Contracts'],
  releaseDate: '2023-07-13',
  lastUpdate: '2023-07-13',
  size: '15MB',
  compatibility: ['All platforms'],
  features: ['Fast transactions', 'Low fees']
});

  const [AllConnectAgents, setAllConnectAgents] = useState<Agent[]>(() => {
    const storedAgents = localStorage.getItem('AllConnectAgents');
    if (storedAgents) {
      const parsedAgents = JSON.parse(storedAgents);
      return parsedAgents.length > 0 ? parsedAgents : [
        // getCosmosAgent(), 
        // getPersonalAgent(),
        getSolanaAgent()];
  } else {
    const defaultAgents = [
      // getCosmosAgent(), 
      // getPersonalAgent(),
       getSolanaAgent()];
    localStorage.setItem('AllConnectAgents', JSON.stringify(defaultAgents));
    return defaultAgents;
    }
  });
  
  // Helper function to get the Personal agent
  

const handleSelectAgent = (agent: Agent) => {
  setAllConnectAgents(prevAgents => {
    // Check if the agent is already selected
    if (prevAgents.some(a => a.id === agent.id)) {
      return prevAgents; // Don't add if already present
    }
    
    const newAgents = [...prevAgents, agent];
    localStorage.setItem('AllConnectAgents', JSON.stringify(newAgents));
    return newAgents;
  });
};
  
const handleRemoveAgent = (agentId: string) => {
  setAllConnectAgents(prevAgents => {
    const updatedAgents = prevAgents.filter(agent => agent.id !== agentId);
    // Ensure at least the Personal agent remains
    const finalAgents = updatedAgents.length > 0 ? updatedAgents : [getSolanaAgent()];
    localStorage.setItem('AllConnectAgents', JSON.stringify(finalAgents));
    return finalAgents;
  });
};


  const onToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // const toggleDarkMode = () => {
  //   setIsDarkMode(!isDarkMode);
  //   document.body.classList.toggle('dark');
  // };

  return (
    <div className={`app-container ${darkMode ? 'dark' : ''}`}>
      <NewSidebar isOpen={isSidebarOpen} onToggleSidebar={onToggleSidebar} onLogout={onLogout} profilePic={profilePic}/>
      <div className={`main-content content ${isSidebarOpen ? 'expanded' : 'collapsed'} ${isDarkMode ? "dark" : "light"}`}>
        <Routes>
          {/* <Route path="/home" element={<Home onLogout={onLogout} />} /> */}
          <Route path="/home" element={<Dashboard onLogout={onLogout} profilePic={profilePic} AllConnectAgents={AllConnectAgents} currentAccount={accounts[defaultAccountIndex]} />} />
          <Route path="/chat" element={<ChatPage AllConnectAgents={AllConnectAgents} removeAgent={handleRemoveAgent}   profilePic={profilePic}
    />} />
          {/* <Route path="/chat" element={<BlockchainDashboard  />} /> */}
          <Route path="/marketplace" element={<MarketPlace 
      onSelectAgent={handleSelectAgent}
      AllConnectAgents={AllConnectAgents} // Pass this prop
    />} />
          
          <Route path="/profile" element={<ProfilePage profilePic={profilePic}/>} />
          <Route path="/wallet" element={<WalletPage profilePic={profilePic}/>} />
          <Route path="/setting" element={<SettingsPage/>} />

          <Route path="/*" element={<Navigate to="/home" />} />
        </Routes>
      </div>
    </div>
  );
};

const RootApp: React.FC = () => (
  <GoogleOAuthProvider clientId="939360077091-sqbk38cnuepbe96b455kcb61hjdstkep.apps.googleusercontent.com">
  <Router>
    <App />
  </Router>
  </GoogleOAuthProvider>
);

export default RootApp;
