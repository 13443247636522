import React, { useState, useEffect,useRef } from 'react';
import { FiChevronDown, FiCopy, FiExternalLink } from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'sonner';
import { Account } from "./types";
import { shortenAddress, getWalletBalance } from './walletUtils';
import walletpic from "../../Assets/profilePics/images.png";
import { useTheme } from '../../contexts/ThemeContext';

// interface Account {
//   name: string;
//   mnemonic: string;
//   address: string;
//   balance: string;
//   icon?: string;
// }

interface YourWalletProps {
  accounts: Account[];
  defaultAccountIndex: number;
  setDefaultAccountIndex: (index: number) => void;
  updateAccounts: (updatedAccounts: Account[]) => void;
  currentNetwork: string; // New prop to indicate the current network
}

const YourWallet: React.FC<YourWalletProps> = ({
  accounts,
  defaultAccountIndex,
  setDefaultAccountIndex,
  updateAccounts,
  currentNetwork, // Add this prop
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

// Inside the component, add this line near the top
const { darkMode } = useTheme();
  const walletDropRef = useRef<HTMLDivElement>(null);
  const filteredAccounts = accounts.filter(account => 
    account.network.toLowerCase() === currentNetwork.toLowerCase() &&
    (account.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    account.address.toLowerCase().includes(searchTerm.toLowerCase()))
  );
  useEffect(() => {
    if (filteredAccounts.length > 0 && !filteredAccounts.some((_, index) => index === defaultAccountIndex)) {
      const newIndex = accounts.findIndex(account => account.network.toLowerCase() === currentNetwork.toLowerCase());
      if (newIndex !== -1) {
        setDefaultAccountIndex(newIndex);
      }
    }
  }, [currentNetwork, accounts, defaultAccountIndex, setDefaultAccountIndex, filteredAccounts]);

useEffect(() => {
  const handleClickOutside = (event: MouseEvent) => {
    if (walletDropRef.current && !walletDropRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
},[]);

  const handleSwitchWallet = async (index: number) => {
    const selectedAccount = filteredAccounts[index];
    const globalIndex = accounts.findIndex(account => account.address === selectedAccount.address);
    
    if (globalIndex !== -1) {
      setDefaultAccountIndex(globalIndex);
      localStorage.setItem('defaultAccountIndex', globalIndex.toString());
      localStorage.setItem("mnemonic", selectedAccount.mnemonic);
      localStorage.setItem("account", selectedAccount.address);
      localStorage.setItem("balance", selectedAccount.balance);

      // Update balance in real-time
      const updatedBalance = await getWalletBalance(selectedAccount.address, selectedAccount.network);
      const updatedAccounts = accounts.map((account, i) => 
        i === globalIndex ? { ...account, balance: updatedBalance } : account
      );
      updateAccounts(updatedAccounts);

      toast.success(`Switched to ${selectedAccount.name}`);
      setIsOpen(false);
    } else {
      toast.error("Failed to switch wallet. Please try again.");
    }
  };


  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success('Address copied to clipboard');
  };

  const viewOnExplorer = (address: string) => {
    if(address.startsWith('cosmos')){
      window.open(`https://www.mintscan.io/cosmos/address/${address}`, '_blank');
    }else{
      window.open(`https://solscan.io/account/${address}`, '_blank');
    }
  };

  return (
    <div className="relative" ref={walletDropRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`flex items-center space-x-2 ${darkMode ? 'bg-teal-800 text-white' : 'bg-white text-gray-700'} hover:bg-opacity-90 px-4 py-2 rounded-lg duration-200 shadow-sm`}
        >
        
        <img src={accounts[defaultAccountIndex]?.icon || walletpic} alt="Wallet" className="w-6 h-6 rounded-full" />
        <span className={`font-medium ${darkMode ? 'bg-teal-900 text-white' : 'bg-white text-gray-700'} `}>{accounts[defaultAccountIndex]?.name || 'Select Wallet'}</span>
        <FiChevronDown className={`transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''} text-gray-500`} />
      </button>

      {/* {accounts[defaultAccountIndex] && (
        <div className="mt-2 flex items-center space-x-2">
          <span className="text-sm text-gray-600">{shortenAddress(accounts[defaultAccountIndex].address)}</span>
          <button
            onClick={() => copyToClipboard(accounts[defaultAccountIndex].address)}
            className="text-blue-500 hover:text-blue-600 transition-colors"
            title="Copy address"
          >
            <FiCopy size={16} />
          </button>
        </div>
      )} */}

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className={`absolute right-0 mt-2 w-96 ${darkMode ? 'bg-teal-950 text-white' : 'bg-white text-black'} rounded-lg shadow-xl z-10 border border-teal-200`}
            >
            <div className="p-3 border-b border-teal-200">
              <input
                type="text"
                placeholder="Search wallets..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
              />
            </div>
            <div className="max-h-60 overflow-y-auto">
              {filteredAccounts.map((account, index) => (
                <div key={account.address} className={`p-3 ${darkMode ? 'hover:bg-teal-600 text-white' : 'hover:bg-gray-50'} transition-colors`}>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                      {/* <img src={account.icon || walletpic} alt={account.name} className="w-8 h-8 rounded-full" /> */}
                      <div>
                        <p className="font-medium ">{account.name}</p>
                        <p className="text-sm text-gray-400">{shortenAddress(account.address)}</p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <button onClick={() => copyToClipboard(account.address)} className="text-gray-400 hover:text-gray-500 transition-colors">
                        <FiCopy size={16} />
                      </button>
                      <button onClick={() => viewOnExplorer(account.address)} className="text-gray-500 hover:text-gray-400 transition-colors">
                        <FiExternalLink size={16} />
                      </button>
                      <button
                        onClick={() => handleSwitchWallet(index)}
                        className={`px-3 py-1 rounded-full text-sm ${
                          account.address === accounts[defaultAccountIndex].address
                            ? 'bg-green-500 text-white'
                            : 'bg-gray-200 text-gray-400 hover:bg-gray-300'
                        }`}
                      >
                        {account.address === accounts[defaultAccountIndex].address ? 'Active' : 'Select'}
                      </button>
                    </div>
                  </div>
                  <p className="text-sm text-gray-400 mt-1">Balance: {account.balance} ATOM
                  <span className="ml-2 text-green-500">
                (${(parseFloat(account.balance || '0') * 0.1).toFixed(2)})
              </span>
                  </p>
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default YourWallet;