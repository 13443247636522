import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
  XAxis,
  YAxis,
  // Area,
  // BarChart,
  // Bar,
} from "recharts";
import {
  
  FiTrendingUp,
  FiTrendingDown,
  // FiActivity,
  // FiClock,
} from "react-icons/fi";
import axios from "axios";
// import { getRecentTransactions } from "../NewChatWindows/walletUtils";
interface WalletBannerProps {
  profilePic: string;
  profileName: string;
  publicKey: string;
  atomBalance: number;
  dollarValue: number;
  recentTransactions: any[];
}

interface PriceData {
  date: string;
  price: number;
}

interface MarketData {
  marketCap: number;
  volume24h: number;
  circulatingSupply: number;
}

const WalletBanner: React.FC<WalletBannerProps> = ({
  profilePic,
  profileName,
  publicKey,
  atomBalance,
  dollarValue,
  // recentTransactions,
}) => {
  const [priceData, setPriceData] = useState<PriceData[]>([]);
  const [marketData, setMarketData] = useState<MarketData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [priceChange, setPriceChange] = useState<{
    percentage: number;
    isPositive: boolean;
  }>({ percentage: 0, isPositive: true });
  // const [recentTransactions, setRecentTransactions] = useState<{ date: string; amount: number }[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch price data
        const priceResponse = await axios.get(
          "https://api.coingecko.com/api/v3/coins/cosmos/market_chart?vs_currency=usd&days=365"
        );
        const formattedPriceData = priceResponse.data.prices.map(
          (item: [number, number]) => ({
            date: new Date(item[0]).toLocaleDateString(),
            price: item[1],
          })
        );
        setPriceData(formattedPriceData);

        // Calculate price change
        const firstPrice = formattedPriceData[0].price;
        const lastPrice =
          formattedPriceData[formattedPriceData.length - 1].price;
        const change = ((lastPrice - firstPrice) / firstPrice) * 100;
        setPriceChange({
          percentage: Math.abs(change),
          isPositive: change >= 0,
        });

        // Fetch market data
        const marketResponse = await axios.get(
          "https://api.coingecko.com/api/v3/coins/cosmos"
        );
        setMarketData({
          marketCap: marketResponse.data.market_data.market_cap.usd,
          volume24h: marketResponse.data.market_data.total_volume.usd,
          circulatingSupply: marketResponse.data.market_data.circulating_supply,
        });
        //   const transactions = await getRecentTransactions(publicKey);
        // setRecentTransactions(transactions);
      } catch (err) {
        setError("Failed to fetch data");
        console.error("Error fetching data:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [publicKey, atomBalance]);

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-2 rounded shadow text-gray-800 text-xs">
          <p className="font-bold">{`$${payload[0].value.toFixed(2)}`}</p>
          <p>{payload[0].payload.date}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="bg-gray-900 text-white p-3 md:p-6 rounded-xl mb-6 shadow-lg">
      <div className="flex flex-col md:flex-row justify-between items-start">
        <div className="w-full md:w-1/3">
          <div className="flex items-center mb-4">
            <img
              src={profilePic}
              alt="Profile"
              className="w-14 h-14 rounded-full mr-4 border-2 border-blue-500"
            />
            <div>
              <h2 className="text-xl font-bold">{profileName}</h2>
              <p className="text-sm text-gray-400 truncate">{`${publicKey.slice(
                0,
                8
              )}...${publicKey.slice(-8)}`}</p>
            </div>
          </div>
          <div className="flex justify-start align-center items-start space-x-5 h-20 md:h-24">
             <div className="bg-gray-800 py-2 md:py-6 px-4 md:px-6 w-1/2 h-full rounded-lg mb-4 shadow-lg">
            <p className="text-sm text-gray-400">Wallet Value</p>
            <p className="text-xl font-bold">
              <span className="text-blue-500">{atomBalance.toFixed(3)}{" "}</span>
              <span className="text-sm font-medium text-gray-500">ATOM</span>
            </p>
            {/* <p className="text-lg">
              <FiDollarSign className="inline mr-1" />
              {dollarValue < 0.01 ? " $0.0" : `$${dollarValue.toFixed(2)}`}
            </p> */}
          </div>
          <div className="bg-gray-800 h-full py-2 md:py-6 px-4 md:px-6 rounded-lg w-1/2">
            <p className="text-sm text-gray-400">Price Change</p>
            <p
              className={`text-xl font-bold ${
                priceChange.isPositive ? "text-green-500" : "text-red-500"
              }`}
            >
              {priceChange.isPositive ? (
                <FiTrendingUp className="inline mr-1" />
              ) : (
                <FiTrendingDown className="inline mr-1" />
              )}
              {priceChange.percentage.toFixed(2)}%
            </p>
          </div>
          </div>
         
        </div>
        <div className="w-full md:w-2/3 mt-6 md:mt-0">
          <h3 className="text-lg font-semibold mb-2 text-center text-slate-500">
            ATOM Price Chart (30 days)
          </h3>
          <div className="h-40">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={priceData}>
                <CartesianGrid strokeDasharray="3 3" stroke="#333333" />
                <XAxis dataKey="date" tick={{ fill: "#9ca3af" }} />
                <YAxis tick={{ fill: "#9ca3af" }} />
                <Tooltip content={<CustomTooltip />} />
                <Line
                  type="monotone"
                  dataKey="price"
                  stroke="#3b82f6"
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      {/* <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-gray-800 p-4 rounded-lg">
          <h4 className="text-lg font-semibold mb-2">Market Cap</h4>
          <p className="text-2xl font-bold">${marketData?.marketCap.toLocaleString()}</p>
        </div>
        <div className="bg-gray-800 p-4 rounded-lg">
          <h4 className="text-lg font-semibold mb-2">24h Volume</h4>
          <p className="text-2xl font-bold">${marketData?.volume24h.toLocaleString()}</p>
        </div>
        <div className="bg-gray-800 p-4 rounded-lg">
          <h4 className="text-lg font-semibold mb-2">Circulating Supply</h4>
          <p className="text-2xl font-bold">{marketData?.circulatingSupply.toLocaleString()} ATOM</p>
        </div>
      </div> */}
      {/* <div className="mt-8">
        <h3 className="text-lg font-semibold mb-4">Recent Transactions</h3>
        <div className="bg-gray-800 p-4 rounded-lg">
          <div className="h-48">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={recentTransactions}>
                <CartesianGrid strokeDasharray="3 3" stroke="#333333" />
                <XAxis dataKey="date" tick={{ fill: "#9ca3af" }} />
                <YAxis tick={{ fill: "#9ca3af" }} />
                <Tooltip
                  content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      const data = payload[0].payload;
                      return (
                        <div className="bg-gray-800 p-2 rounded shadow">
                          <p className="text-white">{`${
                            data.type === "send" ? "Sent" : "Received"
                          }: ${data.amount.toFixed(6)} ATOM`}</p>
                          <p className="text-gray-400">{`Height: ${data.height}`}</p>
                          <p className="text-gray-400">{`Hash: ${data.hash.slice(
                            0,
                            10
                          )}...`}</p>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
               
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default WalletBanner;
