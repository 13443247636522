// File: src/Components/NewChatWindows/ChatPage.tsx
import SolanaTransactionHandler from "./Solanatransaction";
import React, { useState, useRef, useEffect, useCallback } from "react";
// import {getKeypairFromMnemonic} from "./SolanaTransactionHandler"
import FeatureTour from "./FeatureTour";
import { v4 as uuidv4 } from "uuid";
// import { SigningStargateClient } from "@cosmjs/stargate";
// import { DirectSecp256k1HdWallet } from "@cosmjs/proto-signing";
import ReactMarkdown from "react-markdown";
import SolanaWalletBanner from "../WalletPage/SolanaWalletBanner";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import TransactionStatus from "./TransactionStatus";
import solanalogo from "../../Assets/Image/solana-logo.png"
import { transactionStatusSubject } from "./SolanaTransactionHandler";
// import {
//   Connection,
//   PublicKey,
//   sendAndConfirmTransaction,
//   LAMPORTS_PER_SOL,
//   Transaction,
//   SystemProgram,
// } from "@solana/web3.js";
// import { Secp256k1HdWallet } from "@cosmjs/amino";
import { handleSolanaTransaction } from "./SolanaTransactionHandler";
import rehypeRaw from "rehype-raw";
// import TourGuide from "./TourGuide";
import YourWallet from "./YourWallet";
import ZeroBalanceWarning from "./ZeroBalanceWarning";
import { useTheme } from '../../contexts/ThemeContext';
// import { Subscription } from "rxjs";
import {
  // FiStar,
  // FiShare2,
  FiMessageSquare,
  // FiSearch,
  FiMoreVertical,
  // FiCheck,
  FiEdit2,
  FiTrash2,
  // FiChevronRight,
  // FiChevronLeft,
  // FiExternalLink,
  // FiImage,
  FiCopy,
  FiX,
  FiChevronDown,
  FiSettings,
  FiLogOut,
  FiPlus,
  FiSend, FiDollarSign, FiTrendingUp 
} from "react-icons/fi";
// import { format, parseISO } from "date-fns";
import { Toaster, toast } from "sonner";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";
import "./NewChatWindows.css";
import { handleCosmosTransaction } from "./CosmosTransactionHandler";
import { Agent, Message, ChatSession, Wallet, Account } from "./types";
import process from "../../Assets/Image/Infinity Loop (1).gif";
import complete from "../../Assets/Image/ezgif.com-crop.gif";
// import bot from "../../Assets/Image/favicon.png";
import chainConfig from "./config";
// import TypingAnimation from "../../magicui/typing-animation";
import InputArea from "./InputArea";
import chainConfigSol from "./configsol";
import MessageComponent from "./MessageComponent";
import {
  getWalletInfoFromMnemonic,
  // validateWalletName,
  // shortenAddress,
  getWalletBalance,
  // getSolanaWalletBalance,
} from "./walletUtils";
import WalletBanner from "./../WalletPage/WalletBanner";
// import walletpic from "../../Assets/profilePics/images.png";
// import { id } from "ethers";
// import { title } from "process";

const LOW_BALANCE_THRESHOLD = 0.001; // Set a threshold for low balance warning
const BALANCE_CHECK_DELAY = 3000; // 2 seconds delay
interface ChatPageProps {
  AllConnectAgents: Agent[];
  removeAgent: (agentId: string) => void;
  profilePic: string;
}
// interface Account {
//   name: string;
//   mnemonic: string;
//   address: string;
//   balance: string;
//   icon?: string;
// }

// Wallet Dropdown Component
type DetailedStatus = {
  stage: "initializing" | "processing" | "confirming" | "completed" | "error";
  message: string;
  progress: number;
};

const ChatPage: React.FC<ChatPageProps> = ({
  AllConnectAgents,
  removeAgent,
  profilePic,
}) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [agentToRemove, setAgentToRemove] = useState<Agent | null>(null);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [inputValue, setInputValue] = useState("");
   // Add new state for suggestion cards
   const [showSuggestionCards, setShowSuggestionCards] = useState(true);
  // const [loading, setLoading] = useState(false);
  const [currentSessionId, setCurrentSessionId] = useState<string | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [chatSessions, setChatSessions] = useState<ChatSession[]>(() => {
    const storedSessions = localStorage.getItem("chatSessions");
    return storedSessions ? JSON.parse(storedSessions) : [];
  });
  const [currentSessionIndex, setCurrentSessionIndex] = useState<number>();
  const [showRightPanel, setShowRightPanel] = useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [walletName, setWalletName] = useState("");
  const [expandedImage, setExpandedImage] = useState<string | null>(null);
  const [isGeneratingText, setIsGeneratingText] = useState(false);
  const [isTransaction, setisTransaction] = useState(false);
  const [generatedText, setGeneratedText] = useState("");
  const [isStoppingResponse, setIsStoppingResponse] = useState(false);
  const [recentTransactions, setRecentTransactions] = useState<any[]>([]);
  const [transactionStatus, setTransactionStatus] =
    useState<DetailedStatus | null>(null);
  const [currentAgent, setCurrentAgent] = useState<Agent | null>(() => {
    return (
      AllConnectAgents.find((agent) => agent.name == "solana") ||
      AllConnectAgents[0] ||
      null
    );
  });
  // const [isNewChatModalOpen, setIsNewChatModalOpen] = useState(false);
  // const [currentAgent, setCurrentAgent] = useState<Agent | null>(null);
  const [isChatHistoryOpen, setIsChatHistoryOpen] = useState(false);
  // const [searchTerm, setSearchTerm] = useState("");
  const chatHistoryRef = useRef<HTMLDivElement>(null);
  const [currentAccount, setCurrentAccount] = useState<Account | null>(null);
  const [showZeroBalanceWarning, setShowZeroBalanceWarning] = useState(false);
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [currentWallet, setCurrentWallet] = useState<Wallet | null>(null);
  const [showFeatureTour, setShowFeatureTour] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [defaultAccountIndex, setDefaultAccountIndex] = useState<number>(() => {
    const storedIndex = localStorage.getItem("defaultAccountIndex");
    console.log("Initial defaultAccountIndex from localStorage:", storedIndex);
    return storedIndex ? parseInt(storedIndex, 10) : 0;
  });
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const agentDropdownRef = useRef<HTMLDivElement>(null);
  const notificationRef = useRef<HTMLDivElement>(null);
  const profileMenuRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  // New state for dropdown visibility
  const [showAgentDropdown, setShowAgentDropdown] = useState(false);
  const username = localStorage.getItem("walletname") || "User";
  const [currentNetwork, setCurrentNetwork] = useState<string>(() => {
    return AllConnectAgents.find((agent) => agent.name === "Cosmos")
      ? "cosmos"
      : AllConnectAgents.find((agent) => agent.name === "Solana")
      ? "solana"
      : "unknown";
  });

  const loadingGifUrl = process;
  const completionGifUrl = complete;
  const staticBotImageUrl =
    "https://ui8-brainwave.herokuapp.com/_next/image?url=%2Fimages%2Favatar-chat.jpg&w=640&q=75";

  // Add this function to load existing accounts:
  const loadExistingAccounts = useCallback(async () => {
    try {
      const storedWallets = localStorage.getItem("accounts");
      if (storedWallets) {
        const parsedWallets = JSON.parse(storedWallets) as Account[];
        const updatedWallets = await Promise.all(
          parsedWallets.map(async (wallet: Account) => {
            try {
              // const balance = wallet.network === 'cosmos'
              //   ? await getWalletBalance(wallet.address,wallet.network)
              //   :
              const balance = await getWalletBalance(
                wallet.address,
                wallet.network
              );
              console.log("balance", balance);
              // console.log("wallet", ...wallet);
              return { ...wallet, balance };
            } catch (error) {
              console.error(
                `Error fetching balance for ${wallet.network} wallet ${wallet.name}:`,
                error
              );
              return wallet;
            }
          })
        );
        setAccounts(updatedWallets);
        const storedIndex = localStorage.getItem("defaultAccountIndex");
        const defaultIndex = storedIndex ? parseInt(storedIndex, 10) : 0;
        console.log(
          "defaultAccountIndex in loadExistingAccounts:",
          defaultIndex
        );

        setDefaultAccountIndex(defaultIndex);
        setCurrentWallet(updatedWallets[defaultIndex] as unknown as Wallet);
      } else {
        console.log("No stored wallets found");
        setAccounts([]);
        setCurrentWallet(null);
      }
    } catch (error) {
      console.error("Error loading accounts:", error);
      toast.error("Failed to load accounts. Please try again.");
    }
  }, []);
  // console.log("defaultAccountIndex in loadexisting", defaultAccountIndex);
  // Update currentNetwork when the agent changes
  // useEffect(() => {
  //   if (currentAgent) {
  //     setCurrentNetwork(currentAgent.name.toLowerCase());
  //   }
  // }, [currentAgent]);

  // useEffect(() => {
  //   const statusSubscription: Subscription = transactionStatusSubject.subscribe(
  //     (status: string) => {
  //       setTransactionStatus(status);
  //     }
  //   );

  //   return () => {
  //     statusSubscription.unsubscribe();
  //   };
  // }, []);

  // useEffect(() => {
  //   const savedSessions = localStorage.getItem('chatSessions');
  //   if (savedSessions) {
  //     const parsedSessions = JSON.parse(savedSessions);
  //     setChatSessions(parsedSessions);
  //     if (parsedSessions.length > 0) {
  //       setCurrentSessionId(parsedSessions[parsedSessions.length - 1].id);
  //       setMessages(parsedSessions[parsedSessions.length - 1].messages);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    // Check if it's a new user
    const hasSeenTour = localStorage.getItem("hasSeenFeatureTour");
    if (!hasSeenTour) {
      setShowFeatureTour(true);
    }
  }, []);

  const handleTourComplete = () => {
    setShowFeatureTour(false);
  };

  useEffect(() => {
    localStorage.setItem("chatSessions", JSON.stringify(chatSessions));
  }, [chatSessions]);

  // Unified useEffect for handling outside clicks
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        agentDropdownRef.current &&
        !agentDropdownRef.current.contains(event.target as Node)
      ) {
        setShowAgentDropdown(false);
      }
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target as Node)
      ) {
        setShowPopup(false);
      }
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target as Node)
      ) {
        setProfileMenuOpen(false);
      }
      if (
        chatHistoryRef.current &&
        !chatHistoryRef.current.contains(event.target as Node)
      ) {
        setIsChatHistoryOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // ... other existing code
  useEffect(() => {
    const subscription = transactionStatusSubject.subscribe(
      (status: DetailedStatus) => {
        setTransactionStatus(status);
      }
    );
    const storedAgent = localStorage.getItem("selectedAgent");
    const storedAccount = localStorage.getItem("currentAccount");

    if (storedAgent) {
      setCurrentAgent(JSON.parse(storedAgent));
    }

    if (storedAccount) {
      setCurrentAccount(JSON.parse(storedAccount));
    }

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const loadData = async () => {
      const storedAgent = localStorage.getItem("selectedAgent");
      const storedAccount = localStorage.getItem("currentAccount");
      const storedIndex = localStorage.getItem("defaultAccountIndex");
      console.log("Stored index in loadData:", storedIndex);
      const agent = localStorage.getItem("selectedAgent");

      if (storedAgent) {
        try {
          const agent = JSON.parse(storedAgent) as Agent;
          setCurrentAgent(agent);
          console.log("agent switch", agent);
          const newNetwork = agent.name.toLowerCase();
          console.log("Switching to network:", newNetwork);
          setCurrentNetwork(newNetwork);
        } catch (error) {
          console.error("Error parsing stored agent:", error);
        }
      }

      if (storedAccount) {
        try {
          const account = JSON.parse(storedAccount);
          setCurrentAccount(account);
          console.log("account switch", account);
        } catch (error) {
          console.error("Error parsing stored account:", error);
        }
      }

      if (storedIndex) {
        const parsedIndex = parseInt(storedIndex, 10);
        console.log("Setting defaultAccountIndex to:", parsedIndex);
        setDefaultAccountIndex(parsedIndex);
      }
      const outputloadAccount = await loadExistingAccounts();
      console.log(outputloadAccount);
      // const storedDefaultIndex = localStorage.getItem("defaultAccountIndex");
      // const defaultIndex = storedDefaultIndex
      //   ? parseInt(storedDefaultIndex, 10)
      //   : 0;
      // console.log("defaultAccountIndex", defaultIndex);

      // if (accounts.length > 0) {
      //   const defaultAccount = accounts[defaultIndex];
      //   try {
      //     const transactions = await getRecentTransactions(
      //       defaultAccount.address
      //     );
      //     setRecentTransactions(transactions);
      //   } catch (error) {
      //     console.error("Error fetching recent transactions:", error);
      //     toast.error("Failed to fetch recent transactions");
      //   }
      // }
    };
    loadData();
  }, []);


  // Inside the component, add this line near the top
const { darkMode } = useTheme();


  useEffect(() => {
    loadStoredData();
    // loadWallets();
    // const savedAgent = localStorage.getItem("AllConnectAgents");
    // console.log(savedAgent)

    // if (savedAgent) {
    //   setCurrentAgent(JSON.parse(savedAgent));
    //   console.log(setCurrentAgent)
    // } else if (AllConnectAgents.length > 0) {
    //   setCurrentAgent(AllConnectAgents[0]);
    // }
  }, [AllConnectAgents]);

  useEffect(() => {
    console.log("loadchat function to load the functions");
    loadChatSessions();
  }, []);

  // const loadChatSessions = () => {
  //   const storedSessions = localStorage.getItem("chatSessions");
  //   if (storedSessions) {
  //     const parsedSessions = JSON.parse(storedSessions);
  //     setChatSessions(parsedSessions);
  //     setCurrentSessionIndex(parsedSessions.length - 1);
  //     setMessages(parsedSessions[parsedSessions.length - 1].messages);
  //   } else {
  //     const initialSession: ChatSession = {
  //       id: uuidv4(),
  //       title: "New Chat",
  //       messages: [],
  //       isLoading: false,
  //       agentId: ""
  //     };
  //     setChatSessions([initialSession]);
  //     localStorage.setItem("chatSessions", JSON.stringify([initialSession]));
  //   }
  // };

  // Function to toggle chat history visibility
  const toggleChatHistory = () => setIsChatHistoryOpen(!isChatHistoryOpen);

  const loadChatSessions = () => {
    const storedSessions = localStorage.getItem("chatSessions");
    console.log("Stored sessions from localStorage:", storedSessions);

    if (storedSessions) {
      try {
        const parsedSessions = JSON.parse(storedSessions) as ChatSession[];
        console.log("Parsed sessions:", parsedSessions);
        console.log("parsedSessions.length", parsedSessions.length);

        if (parsedSessions.length > 0) {
          setChatSessions(parsedSessions);
          const lastSessionIndex = parsedSessions.length - 1;
          setCurrentSessionIndex(lastSessionIndex);
          setCurrentSessionId(parsedSessions[lastSessionIndex].id);
          setMessages(parsedSessions[lastSessionIndex].messages);
        } else {
          console.log("No sessions found, initializing new session");
          initializeNewSession();
        }
      } catch (error) {
        console.error("Error parsing stored sessions:", error);
        initializeNewSession();
      }
    } else {
      console.log("No stored sessions found, initializing new session");
      initializeNewSession();
    }
  };

  const initializeNewSession = () => {
    const initialSession: ChatSession = {
      id: uuidv4(),
      title: "New Chat",
      messages: [],
      isLoading: false,
      agentId: currentAgent?.id || "",
    };
    setChatSessions([initialSession]);
    setCurrentSessionId(initialSession.id);
    setCurrentSessionIndex(0);
    setMessages([]);
    localStorage.setItem("chatSessions", JSON.stringify([initialSession]));
  };

  // Suggestion cards data
  const suggestionCards = [
   
    {
      title: "Send SOL",
      description: "Transfer SOL to another address",
      action: "I want to send Solana tokens",
      icon: <FiSend className="text-black text-xl" />,
    },
    {
      title: "Swap",
      description: "Swap your SOL tokens",
      action: "I want to swap my Solana tokens",
      icon: <FiTrendingUp className="text-black text-xl" />,
    }, {
      title: "Transaction history",
      description: "Get your previous transaction history ",
      action: "What is my Transaction History?",
      icon: <FiDollarSign className="text-black text-xl" />,
    },
  ];

  // Function to handle suggestion card click
  const handleSuggestionClick = async (action: string) => {
    // Create a user message object
    const userMessage: Message = {
      type: "user",
      text: action,
      time: new Date().toLocaleTimeString(),
    };

    // Update messages state
    setMessages(prevMessages => [...prevMessages, userMessage]);

    // Hide suggestion cards
    setShowSuggestionCards(false);

    // Set input value (optional, if you want to show the action in the input field)
    setInputValue(action);

    // Process the message (similar to handleSend function)
    try {
      // Set loading state
      setIsGeneratingText(true);
setInputValue("");
      // Here, call your API or process the message
      // This is a placeholder for your actual API call or processing logic
      const response = await handleSolanaAgent(action);

      // Create a bot message object
      const botMessage: Message = {
        type: "bot",
        text: response,
        time: new Date().toLocaleTimeString(),
      };

      // Update messages state with bot response
      setMessages(prevMessages => [...prevMessages, botMessage]);
      
    } catch (error) {
      console.error("Error processing suggestion:", error);
      toast.error("Failed to process your request. Please try again.");
    } finally {
      setIsGeneratingText(false);
    }
  };

  const createNewChatSession = () => {
    const newSession: ChatSession = {
      id: uuidv4(),
      title: "New Chat",
      messages: [],
      isLoading: false,
      agentId: currentAgent?.id || "",
    };
    setShowSuggestionCards(true);
    setChatSessions((prevSessions) => [...prevSessions, newSession]);
    setCurrentSessionId(newSession.id);
    setCurrentSessionIndex(chatSessions.length);
    setMessages([]);
    localStorage.setItem(
      "chatSessions",
      JSON.stringify([...chatSessions, newSession])
    );
    // setIsNewChatModalOpen(false);
  };

  useEffect(() => {
    if (currentAgent) {
      localStorage.setItem("selectedAgents", JSON.stringify(currentAgent));
      // was resetting the wallet on first refresh from other agent.
      localStorage.setItem(
        "defaultAccountIndex",
        defaultAccountIndex.toString()
      );
    }
  }, [currentAgent]);

  // useEffect(() => {
  //   if (currentAgent?.name === "Cosmos" && currentWallet) {
  //     fetchUserCosmosInfo(currentWallet);
  //   }
  // }, [currentAgent, currentWallet]);

  const loadStoredData = useCallback(() => {
    try {
      const storedSessions = localStorage.getItem("chatSessions");
      console.log(
        storedSessions,
        "localstorage value inside from loadstorageDATA"
      );

      const storedWalletName = localStorage.getItem("walletname");
      if (storedWalletName) setWalletName(storedWalletName);

      // const storedChatSessions = localStorage.getItem("chatSessions");
      // console.log("chatsessions");
      // if (storedChatSessions) {
      //   const parsedSessions = JSON.parse(storedChatSessions);
      //   setChatSessions(parsedSessions);
      //   setCurrentSessionIndex(parsedSessions.length - 1);
      //   if (parsedSessions.length > 0) {
      //     const currentSession = parsedSessions[parsedSessions.length - 1];
      //     setMessages(currentSession.messages);
      //     setLoading(currentSession.isLoading);
      //   }
      // } else {
      //   setChatSessions([
      //     { id: "", title: "", messages: [], isLoading: false, agentId: "" },
      //   ]);
      // }

      const storedWallets = localStorage.getItem("accounts");
      if (storedWallets) {
        setWallets(JSON.parse(storedWallets));
      }
    } catch (error) {
      console.error("Error loading stored data:", error);
      toast.error("Failed to load stored data. Please refresh the page.");
    }
  }, []);

  const loadWallets = useCallback(async () => {
    try {
      const storedWallets = localStorage.getItem("accounts");
      if (storedWallets) {
        const parsedWallets = JSON.parse(storedWallets);
        const updatedWallets = await Promise.all(
          parsedWallets.map(async (wallet: Wallet) => {
            try {
              return await getWalletInfoFromMnemonic(
                wallet.mnemonic,
                wallet.name
              );
            } catch (error) {
              console.error(`Error updating wallet ${wallet.name}:`, error);
              return wallet;
            }
          })
        );
        setWallets(updatedWallets);
        const defaultWalletIndex = localStorage.getItem("defaultWalletIndex");
        if (defaultWalletIndex) {
          setCurrentWallet(updatedWallets[parseInt(defaultWalletIndex)]);
        } else if (updatedWallets.length > 0) {
          setCurrentWallet(updatedWallets[0]);
        }
      }
    } catch (error) {
      console.error("Error loading wallets:", error);
      toast.error("Failed to load wallets. Please try again.");
    }
  }, []);
  const updateAccounts = (updatedAccounts: Account[]) => {
    setAccounts(updatedAccounts);
    localStorage.setItem("useraccounts", JSON.stringify(updatedAccounts));
  };

  // Update the fetchUserCosmosInfo function
  // const fetchUserCosmosInfo = async (account: Account) => {
  //   if (account) {
  //     try {
  //       setUserAddress('Loading...');
  //       setUserBalance('Loading...');

  //       setUserAddress(account.account); // Assuming publicKey is used as the address

  //       const balance = await getWalletBalance(account.account);
  //       setUserBalance(`${balance} ATOM`);

  //       // Update the account in the accounts array
  //       setAccounts(prevAccounts =>
  //         prevAccounts.map(a =>
  //           a.name === account.name ? { ...a, balance } : a
  //         )
  //       );

  //       // Set the currentWallet state

  //       // Fetch dollar value
  //       const dollarVal = await fetchDollarValue(balance);
  //       setDollarValue(dollarVal);
  //     } catch (error) {
  //       console.error("Error fetching Cosmos info:", error);
  //       setUserAddress("Error fetching address");
  //       setUserBalance("Error fetching balance");
  //       toast.error("Failed to fetch account information. Please try again later.");
  //     }
  //   } else {
  //     setUserAddress("No account connected");
  //     setUserBalance("N/A");
  //     setDollarValue(0);
  //     toast.warning("Please select an account to view balance.");
  //   }
  // };

  // const fetchDollarValue = async (amount: string) => {
  //   // Implement logic to fetch dollar value from an API
  //   // This is a placeholder implementation
  //   return parseFloat(amount) * 0.1; // Assuming 1 ATOM = $0.1
  // };

  const handleRemoveAgent = (agent: Agent) => {
    setAgentToRemove(agent);
    setShowConfirmDialog(true);
  };

  const confirmRemoveAgent = () => {
    if (agentToRemove) {
      removeAgent(agentToRemove.id);
      if (currentAgent && currentAgent.id === agentToRemove.id) {
        // If the removed agent is the current agent, switch to the first available agent
        const nextAgent = AllConnectAgents.find(
          (a) => a.id !== agentToRemove.id
        );
        if (nextAgent) {
          switchAgent(nextAgent);
        }
      }
      toast.success(`${agentToRemove.name} agent removed successfully`);
    }
    setShowConfirmDialog(false);
    setAgentToRemove(null);
  };

  const loadChatSession = (sessionId: string) => {
    setCurrentSessionId(sessionId);
    const session = chatSessions.find((s) => s.id === sessionId);
    if (session) {
      setMessages(session.messages);
      const agent = AllConnectAgents.find((a) => a.id === session.agentId);
      if (agent) setCurrentAgent(agent);
    }
  };

  // const deleteChatSession = (sessionId: string) => {
  //   setChatSessions(prev => prev.filter(s => s.id !== sessionId));
  //   if (currentSessionId === sessionId) {
  //     const remainingSessions = chatSessions.filter(s => s.id !== sessionId);
  //     if (remainingSessions.length > 0) {
  //       loadChatSession(remainingSessions[remainingSessions.length - 1].id);
  //     } else {
  //       setCurrentSessionId(null);
  //       setMessages([]);
  //     }
  //   }
  // };
  // Function to delete a chat session
  const deleteChatSession = (sessionId: string) => {
    if (chatSessions.length <= 1) {
      toast.error("Cannot delete the last chat session");
      return;
    }

    setChatSessions((prevSessions) => {
      const updatedSessions = prevSessions.filter(
        (session) => session.id !== sessionId
      );
      localStorage.setItem("chatSessions", JSON.stringify(updatedSessions));
      return updatedSessions;
    });

    if (currentSessionId === sessionId) {
      const newCurrentSession =
        chatSessions.find((session) => session.id !== sessionId) ||
        chatSessions[0];
      setCurrentSessionId(newCurrentSession.id);
      setMessages(newCurrentSession.messages);
    }

    // toast.success("Chat session deleted");
  };
  const handleSend = async () => {
    type AgentResponse =
      | string
      | { error: string }
      | { transactionHash: string };

    if (!currentWallet && currentAgent?.name === "Cosmos") {
      toast.error("No wallet selected. Please select a wallet first.");
      return;
    }

    if (inputValue.trim() || attachments.length > 0) {
      setShowSuggestionCards(false);

      const userMessage: Message = {
        type: "user",
        text: inputValue,
        time: new Date().toLocaleTimeString(),
        ...(attachments.length > 0 ? { attachments } : {}),
      };
      const updatedMessages = [...messages, userMessage];
      setMessages(updatedMessages);
      saveChatSession(updatedMessages);
      // updateMessages(userMessage);
      setInputValue("");
      setAttachments([]);
      // setIsGeneratingText(true);
      // setGeneratedText("");
      // setIsStoppingResponse(false);

      try {
        let response: AgentResponse;
        const isSolanaTransactionQuery = isSolanaTransaction(inputValue);
        if (currentAgent?.name === "Cosmos") {
          response = await handleCosmosAgent(inputValue);
        } else if (currentAgent?.name === "Personal") {
          response = await handlePersonalAgent(inputValue);
        } else if (currentAgent?.name === "Solana") {
          if (isSolanaTransactionQuery) {
            // Don't set isGeneratingText for transaction queries
            setGeneratedText("");
            setisTransaction(true);
            response = await handleSolanaAgent(inputValue);
          } else {
            setIsGeneratingText(true);
            setGeneratedText("");
            // response = await handleSimpleQuery(inputValue);
            response = await handleSolanaAgent(inputValue);
          }
        } else {
          // response = `Please Try Again after some time`;
          throw new Error("Unsupported agent");
        }

        // if (typeof response === 'string' && !isStoppingResponse) {
        //   const typingSpeed = 1;
        //   for (let i = 0; i < response.length; i += 5) {
        //     if (isStoppingResponse) break;
        //     await new Promise((resolve) => setTimeout(resolve, typingSpeed));
        //     setGeneratedText(
        //       (prevText) => prevText + response!.slice(i, i + 5)
        //     );
        //   }
        // }

        if (!isStoppingResponse) {
          console.log("response check")
          if (typeof response === "string") {
            const typingSpeed = 1;
            const stringResponse = response as string; // Type assertion
            for (let i = 0; i < stringResponse.length; i += 5) {
              if (isStoppingResponse) break;
              await new Promise((resolve) => setTimeout(resolve, typingSpeed));
              setGeneratedText(
                (prevText) => prevText + stringResponse.slice(i, i + 5)
              );
            }
          } else if (typeof response === "object" && response !== null) {
            // For non-string responses, we'll set the generated text all at once
            if ("error" in response) {
              setGeneratedText(`Error: ${response.error}`);
            } else if ("transactionHash" in response) {
              setGeneratedText(`Transaction Hash: ${response.transactionHash}`);
            } else {
              setGeneratedText(JSON.stringify(response));
            }
          } else {
            setGeneratedText("No response generated");
          }
        }

        // if (!isStoppingResponse) {
        //   const botReply: Message = {
        //     type: currentAgent?.name === "Cosmos" ? "transaction" : "bot",
        //     text: response || "No response generated",
        //     time: new Date().toISOString(),
        //   };
        //   updateMessages(botReply);
        // }

        if (!isStoppingResponse) {
          console.log("botReply")
          let botReply: Message;

          if (typeof response === "object" && "error" in response) {
            // Handle error responses
            botReply = {
              type: "error",
              text: response.error,
              time: new Date().toLocaleTimeString(),
              error: response.error,
            };
          } else if (
            typeof response === "object" &&
            "transactionHash" in response
          ) {
            // Handle successful transaction responses
            botReply = {
              type: "transaction",
              text: "Transaction successful",
              time: new Date().toLocaleTimeString(),
              transactionHash: response.transactionHash,
            };
          } else if (typeof response === "string") {
             // Handle normal bot responses
            botReply = {
              type: "bot",
              text: response,
              time: new Date().toLocaleTimeString(),
            };
          } else {
            // Handle unexpected responses
            botReply = {
              type: "bot",
              text: "No response generated",
              time: new Date().toLocaleTimeString(),
            };
          }

          updateMessages(botReply);
        }
        // if (!isStoppingResponse) {
        //   console.log("botReply")
        //   const botReply: Message = {
        //     type: currentAgent?.name === "Cosmos" ? "transaction" : "bot",
        //     text:
        //       typeof response === "string" ? response : "No response generated",
        //     time: new Date().toLocaleTimeString(),
        //   };
        //   const finalMessages = [...updatedMessages, botReply];
        //   setMessages(finalMessages);
        //   saveChatSession(finalMessages);
        //   updateChatSession(finalMessages);
        //   // updateMessages(finalMessages);
        // }
        //   }
      } catch (error) {
        console.error("Error:", error);
        const errorMessage: Message = {
          type: "error",
          text:
            error instanceof Error
              ? error.message
              : "An unexpected error occurred",
          time: new Date().toLocaleTimeString(),
          error: error instanceof Error ? error.message : "Unknown error",
        };
        updateMessages(errorMessage);
      } finally {
        setIsGeneratingText(false);
        setGeneratedText("");
        setIsStoppingResponse(false);
        setisTransaction(false);
      }
    }
    // if (chatSessions.find(s => s.id === currentSessionId)?.messages.length === 1) {
    //   updateSessionTitle(currentSessionId!, userMessage.text);
    // }
  };

  const isSolanaTransaction = (input: string): boolean => {
    const sendRegex =
      /send\s+(\d+(?:\.\d+)?)\s+SOL\s+to\s+([a-zA-Z0-9]{32,44})/i;
    const delegateRegex =
      /delegate\s+(\d+(?:\.\d+)?)\s+SOL\s+to\s+([a-zA-Z0-9]{32,44})/i;
    const swapRegex = /swap\s+(\d+(?:\.\d+)?)\s+SOL\s+to\s+([a-zA-Z0-9]+)/i;
    return (
      sendRegex.test(input) ||
      delegateRegex.test(input) ||
      swapRegex.test(input)
    );
  };

  type AgentResponse = string | { error: string } | { transactionHash: string };

  const handleCosmosAgent = async (input: string) => {
    const mnemonic = localStorage.getItem("mnemonic");
    console.log(mnemonic);
    if (!currentWallet) {
      throw new Error("No wallet selected. Please select a wallet first.");
    }
    if (!mnemonic) {
      throw new Error("Mnemonic not found in local storage.");
    }
    try {
      const result = await handleCosmosTransaction(
        input,
        mnemonic,
        chainConfig
      );

      if (typeof result === "string") {
        console.log("Transaction hash:", result);
        // return result;
        return { transactionHash: result };
      } else {
        return "Unexpected response from Cosmos agent";
      }
    } catch (error) {
      console.error("Error in Cosmos transaction:", error);
      toast.error("Error occurred during Cosmos transaction.");
      return {
        error:
          error instanceof Error
            ? error.message
            : "An unexpected error occurred",
      };
    }
  };

  const handleSimpleQuery = async (input: string): Promise<string> => {
    try {
      // Get the full conversation history
      const conversationHistory = messages
        .map(
          (msg) => `${msg.type === "user" ? "Human" : "Assistant"}: ${msg.text}`
        )
        .join("\n");

      // Prepare the prompt with conversation history
      const prompt = `${conversationHistory}\nHuman: ${input}\nAssistant:`;
      // "https://solana-backend.zenscape.one/proxy/query",
      const response = await axios.post(
        // "https://70b-test.zenscape.one/proxy/v1/chat/completions",
        "http://provider.h100.wdc.val.akash.pub:31529/v1/chat/completions",

        {
          "model": "meta-llama/Meta-Llama-3.1-70B-Instruct",
          "messages": [
          {"role": "system", "content": "You are a helpful assistant."},
          {"role": "user", "content": prompt}
          ]
          }
          
      );

      let agentResponse = response.data.choices[0].message.content;
      return agentResponse.replace(/<\|.*?\|>/g, "").trim();
    } catch (error) {
      console.error("Error fetching from Simple Query:", error);
      throw error;
    }
  };

  const handleSolanaAgent = async (input: any) => {
    const mnemonic = localStorage.getItem("mnemonic");
    // Get the full conversation history
    // const conversationHistory = messages
    // .map(
    //   (msg) => `${msg.type === "user" ? "Human" : "Assistant"}: ${msg.text}`
    // )
    // .join("\n");

    console.log(mnemonic);
// Get the full conversation history
const conversationHistory = messages
 .map(
//  (msg) => `${msg.type === "user" ? "Human" : "Assistant"}: ${msg.text}`
(msg) => {
if (msg.type === 'user') {
  console.log("messages here ",msg);
        return { role: 'Human', content: msg.text };
        }else{
          return { role: 'Assistant', content: msg.transactionHash || msg.text };
        }
      }
     )
    //  .join("\n");


  // Prepare the prompt with conversation history
  // const prompt = `${conversationHistory}\nHuman: ${input}\nAssistant:`;

  let prompt = [...conversationHistory]
   prompt.push({ role: "human", content: input })

   if (prompt.length > 5 * 2) {
    const excessMessages = prompt.length - (5 * 2);
    prompt = prompt.slice(excessMessages);
  }




   console.log("COnverstaion here >> ",conversationHistory)

    if (!currentWallet) {
      throw new Error("No wallet selected. Please select a wallet first.");
    }
    if (!mnemonic) {
      throw new Error("Mnemonic not found in local storage.");
    }
    try {
      const result = await handleSolanaTransaction(
        prompt,
        mnemonic,
        chainConfigSol
      );


      if (typeof result === "string" && result.match(/^[A-Za-z0-9]{64}$/)) {
        console.log("txHash", result);
        setTransactionStatus(null);
        return { transactionHash: result };
      }
      //previous code uncomment if nothing work in response
      // if (typeof result === "string") {
      //   console.log("txHash", result);
      //   setTransactionStatus(null);
      //   return { transactionHash: result };
      // }


      // else if (typeof result === "object" && result.error) {
      //   console.error("Transaction error:", result.error);
      //   const errorReply: Message = {
      //     type: "error",
      //     text: result.error,
      //     time: new Date().toISOString(),
      //     error: result.error,
      //   };
      //   updateMessages(errorReply);
      //   // throw new Error(result.error);
      //   return;
      // }

      // For normal queries, return the string response
      else {
        setTransactionStatus(null);
        return result;
      }
    } catch (error) {
      console.error("Error in Solana transaction:", error);
      setTransactionStatus(null);
      toast.error("Error occurred during Solana transaction.");

      throw error;
      // return {
      //   error:
      //     error instanceof Error
      //       ? error.message
      //       : "An unexpected error occurred",
      // };
    }
  };

  const handlePersonalAgent = async (input: string): Promise<AgentResponse> => {
    try {
      const response = await axios.post(
        "https://70b-test.zenscape.one/proxy/v1/chat/completions",
        {
          model: "meta-llama/Meta-Llama-3-70B-Instruct",
          messages: [
            { role: "system", content: "You are a helpful assistant." },
            { role: "user", content: input },
          ],
        }
      );

      let agentResponse = response.data.choices[0].message.content;
      return agentResponse.replace(/<\|.*?\|>/g, "").trim();
    } catch (error) {
      console.error("Error fetching from Personal Agent:", error);
      throw error;
    }
  };

  // const updateMessages = (newMessage: Message) => {
  //   setMessages((prevMessages) => {
  //     const updatedMessages = [
  //       ...prevMessages.filter((m) => m.type !== "loading"),
  //       newMessage,
  //     ];
  //     saveChatSession(updatedMessages);
  //     return updatedMessages;
  //   });

  //   setChatSessions((prev) =>
  //     prev.map((session) =>
  //       session.id === currentSessionId
  //         ? { ...session, messages: [...session.messages, newMessage] }
  //         : session
  //     )
  //   );
  //   localStorage.setItem("chatMessages", JSON.stringify(messages));
  // };
  const updateMessages = (newMessage: Message) => {
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, newMessage];

      setChatSessions((prevSessions) => {
        const updatedSessions = prevSessions.map((session) =>
          session.id === currentSessionId
            ? { ...session, messages: updatedMessages }
            : session
        );
        localStorage.setItem("chatSessions", JSON.stringify(updatedSessions));
        return updatedSessions;
      });

      return updatedMessages;
    });
  };
  // const saveChatSession = (
  //   messages: Message[],
  //   isLoading: boolean,
  //   agentId: string
  // ) => {
  //   const updatedSessions = [...chatSessions];
  //   updatedSessions[currentSessionIndex] = {
  //     id: uuidv4(),
  //     title,
  //     messages,
  //     isLoading,
  //     agentId,
  //   };
  //   localStorage.setItem("chatSessions", JSON.stringify(updatedSessions));
  //   setChatSessions(updatedSessions);
  // };

  // const startNewChatSession = () => {
  //   const newSession: ChatSession = {
  //     id: uuidv4(),
  //     title: "New Chat",
  //     messages: [],
  //     isLoading: false,
  //     agentId: currentAgent?.id || ""
  //   };
  //   setChatSessions(prevSessions => {
  //     const updatedSessions = [...prevSessions, newSession];
  //     localStorage.setItem("chatSessions", JSON.stringify(updatedSessions));
  //     return updatedSessions;
  //   });
  //   setCurrentSessionId(newSession.id);
  //   setCurrentSessionIndex(chatSessions.length);
  //   setMessages([]);
  // };

  // const selectChatSession = (index: number) => {
  //   setCurrentSessionIndex(index);
  //   setMessages(chatSessions[index].messages);
  //   scrollToBottom();
  // };
  const saveChatSession = (updatedMessages: Message[]) => {
    const updatedSessions = chatSessions.map((session) =>
      session.id === currentSessionId
        ? { ...session, messages: updatedMessages }
        : session
    );
    setChatSessions(updatedSessions);
    localStorage.setItem("chatSessions", JSON.stringify(updatedSessions));
  };
  const updateChatSession = (updatedMessages: Message[]) => {
    setChatSessions((prevSessions) => {
      const updatedSessions = prevSessions.map((session) =>
        session.id === currentSessionId
          ? { ...session, messages: updatedMessages }
          : session
      );
      localStorage.setItem("chatSessions", JSON.stringify(updatedSessions));
      return updatedSessions;
    });
  };

  // Then in updateMessages function:
  // saveChatSession(updatedMessages);

  const startNewChatSession = () => {
    const newSession: ChatSession = {
      id: uuidv4(),
      title: "New Chat",
      messages: [],
      isLoading: false,
      agentId: currentAgent?.id || "",
    };
    setChatSessions([...chatSessions, newSession]);
    setCurrentSessionIndex(chatSessions.length);
    setMessages([]);
    localStorage.setItem(
      "chatSessions",
      JSON.stringify([...chatSessions, newSession])
    );
  };

  const selectChatSession = (index: number) => {
    setCurrentSessionIndex(index);
    const selectedSession = chatSessions[index];
    setCurrentSessionId(selectedSession.id);
    setMessages(selectedSession.messages);
  
    // Update the current agent if necessary
    const sessionAgent = AllConnectAgents.find(agent => agent.id === selectedSession.agentId);
    if (sessionAgent && sessionAgent.id !== currentAgent?.id) {
      switchAgent(sessionAgent);
    }
  };
  

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);
  // Effect to trigger scroll when messages update
  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  const clearChatHistory = () => {
    const initialSession: ChatSession = {
      id: uuidv4(),
      title: "New Chat",
      messages: [],
      isLoading: false,
      agentId: "",
    };
    setShowSuggestionCards(true);
    setChatSessions([initialSession]);
    setMessages([]);
    setCurrentSessionIndex(0);
    setCurrentSessionId(initialSession.id);
    localStorage.setItem("chatSessions", JSON.stringify([initialSession]));
    toast.success("Chat history cleared");
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => toast.success("Text copied to clipboard!"),
      (err) => {
        console.error("Could not copy text: ", err);
        toast.error("Failed to copy text.");
      }
    );
  };
  const stopResponseGeneration = () => {
    setIsStoppingResponse(true);
    toast.success("Response generation stopped.");
  };

  const regenerateResponse = () => {
    toast.success("Regenerating response...");
    handleSend();
  };

  // const switchAgent = (agent: Agent) => {
  //   setCurrentAgent(agent);
  //   setCurrentNetwork(agent.name.toLowerCase()); // Update the current network

  //   toast.success(`Switched to ${agent.name} agent`);
  // };
  // Function to find the first account of a given network
  const findFirstAccountOfNetwork = useCallback(
    (network: string) => {
      return accounts.findIndex(
        (account) => account.network.toLowerCase() === network.toLowerCase()
      );
    },
    [accounts]
  );

  // Updated switchAgent function
  const switchAgent = useCallback(
    (agent: Agent) => {
      setCurrentAgent(agent);
      console.log("agent switch", agent);
      const newNetwork = agent.name.toLowerCase();
      console.log("Switching to network:", newNetwork);
      setCurrentNetwork(newNetwork);

      // Update selectedAgents as an array

      // Find the first account of the new network
      const newAccountIndex = findFirstAccountOfNetwork(newNetwork);
      console.log("New account index:", newAccountIndex);
      if (newAccountIndex !== -1) {
        console.log("Setting defaultAccountIndex to:", newAccountIndex);
        setDefaultAccountIndex(newAccountIndex);
        localStorage.setItem("defaultAccountIndex", newAccountIndex.toString());

        const selectedAccount = accounts[newAccountIndex];
        localStorage.setItem("mnemonic", selectedAccount.mnemonic);
        localStorage.setItem("account", selectedAccount.address);
        localStorage.setItem("balance", selectedAccount.balance);
        setCurrentWallet(selectedAccount as unknown as Wallet);
        toast.success(
          `Switched to ${agent.name} agent and ${selectedAccount.name} account`
        );
      } else {
        // If no account of the new network exists, just switch the agent
        toast.warning(
          `Switched to ${agent.name} agent, but no ${newNetwork} account found`
        );
      }
      setShowAgentDropdown(false); // Close dropdown after selection
      if (currentSessionId) {
        setChatSessions((prevSessions) => {
          const updatedSessions = prevSessions.map((session) =>
            session.id === currentSessionId
              ? { ...session, agentId: agent.id }
              : session
          );
          localStorage.setItem("chatSessions", JSON.stringify(updatedSessions));
          return updatedSessions;
        });
      } else {
        startNewChatSession();
      }
    },
    [
      accounts,
      findFirstAccountOfNetwork,
      setDefaultAccountIndex,
      startNewChatSession,
    ]
  );

  const switchWallet = (account: Account) => {
    const index = accounts.findIndex((a) => a.address === account.address);
    setDefaultAccountIndex(index);
    localStorage.setItem("defaultAccountIndex", index.toString());
    localStorage.setItem("mnemonic", account.mnemonic);
    localStorage.setItem("account", account.address);
    localStorage.setItem("balance", account.balance);
    setCurrentWallet(account as unknown as Wallet); // Type assertion
    toast.success(`Switched to account: ${account.name}`);
  };

  // Function to render suggestion cards
  const renderSuggestionCards = () => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="space-y-10  lg:w-[80%] mx-auto"
    >
      <div className="text-left mt-32">
        <img src={solanalogo} alt="solan" className="w-12 rounded-full p-2.5 mb-2.5 border border-teal-300" />
        <h1 className= {`text-3xl font-semibold mb-1 tracking-tight ${darkMode ? "text-gray-50 " : "text-black"}`}>Hi!, {walletName}</h1>
        <p className={`text-3xl font-semibold  tracking-tight ${darkMode ? "text-gray-400 " : "text-gray-400"}`}>What would you like to do today?</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-9">
        {suggestionCards.map((card, index) => (
          <motion.div
            key={index}
            className={`${darkMode ? "border-teal-400":"bg-teal-50/40 border-teal-100 text-black"} border shadow-inner   p-6   rounded-3xl  hover:shadow-xl transition-shadow duration-300 cursor-pointer `}
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => handleSuggestionClick(card.action)}
          >
            <div className="flex items-center mb-2">
              
              <h3 className="text-xl font-medium">{card.title}</h3>
            </div>
            <p className="text-gray-300 mb-4 text-base">{card.description}</p>
            <div className="flex item-center align-middle justify-between mt-6">
              <button className=" text-teal-600 rounded-full text-sm font-medium hover:bg-teal-50 transition-colors duration-300">
              Try it
            </button>
            <div className="bg-teal-100 p-1 rounded-full w-7 text-center">
                {card.icon}
              </div>
            </div>
            
          </motion.div>
        ))}
      </div>
    </motion.div>
  );


  const renderAgentIntro = () => {
    if (!currentAgent) return null;

    if (currentAgent.name === "Personal") {
      return (
        <div className="space-y-4 p-6 border-2 rounded-xl bg-gray-100 text-slate-700">
          <p className="text-lg font-medium">
            Welcome, {walletName}! I'm your personal chat assistant. How can I
            help you today?
          </p>
        </div>
      );
    } else if (currentAgent.name === "Cosmos") {
      return (
        <div className="space-y-4 p-6 border-2 rounded-xl bg-gray-100 text-slate-700">
          <p className="text-lg font-medium">
            Hello, {walletName}. This is the Cosmos agent for executing SEND
            token transactions.
          </p>
          <WalletBanner
            profilePic={profilePic}
            profileName={username}
            publicKey={accounts[defaultAccountIndex]?.address || ""}
            atomBalance={parseFloat(
              accounts[defaultAccountIndex]?.balance || "0"
            )}
            dollarValue={
              parseFloat(accounts[defaultAccountIndex]?.balance || "0") * 0.1
            }
            recentTransactions={recentTransactions}
          />
          {!currentWallet && (
            <p className="text-red-500">
              No wallet selected. Please select a wallet to perform
              transactions.
            </p>
          )}
        </div>
      );
    } else if (currentAgent.name === "Solana") {
      return (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className={`rounded-3xl bg-gradient-to-r ${darkMode ? "from-teal-700 to-teal-900 text-white":"from-green-100 to-green-100 text-black"} shadow-lg`} 
        
        >
          {/* <p className="text-lg font-medium">
            Hello, {walletName}. This is the Solana agent for executing Solana blockchain transactions.
          </p> */}

          <div className="text-center">
            {/* <h2 className="text-2xl font-semibold text-purple-700 mb-2">Welcome to Your Solana Assistant!</h2> */}
            {/* <p className="text-lg text-purple-600">
            Hi {walletName}! I'm your Solana Agent, ready to simplify your transactions and boost your blockchain security. What can I help you with today?
            </p> */}
          </div>

          {/* <SolanaWalletBanner
            profilePic={profilePic}
            profileName={username}
            publicKey={accounts[defaultAccountIndex]?.address || ""}
            onRefresh={loadExistingAccounts}
          /> */}

          {!currentWallet && (
            <p className="text-red-500 text-center">
              No wallet selected. Please select a wallet to perform
              transactions.
            </p>
          )}
        </motion.div>
      );
    }

    return null;
  };
  // useEffect(() => {
  //   const checkBalance = async () => {
  //     if (currentAgent?.name === 'Solana' && currentAccount) {
  //       try {
  //         const balance = await getSolanaWalletBalance(currentAccount.address);
  //         if (parseFloat(balance) === 0) {
  //           setShowZeroBalanceWarning(true);
  //         }
  //       } catch (error) {
  //         console.error('Error fetching Solana balance:', error);
  //         toast.error('Failed to fetch wallet balance');
  //       }
  //     }
  //   };

  //   checkBalance();
  // }, [currentAgent, currentAccount]);

  const checkBalance = useCallback(async () => {
    const storedWallets = localStorage.getItem("accounts");
    if (storedWallets) {
      const parsedWallets = JSON.parse(storedWallets) as Account[];

      const updatedWallets = await Promise.all(
        parsedWallets.map(async (wallet: Account) => {
          try {
            const balance = await getWalletBalance(
              wallet.address,
              wallet.network
            );
            console.log("balance checkpop", balance);
            const balanceFloat = parseFloat(balance);

            if (balanceFloat <= LOW_BALANCE_THRESHOLD) {

            
              // Double-check the balance after a short delay
              setTimeout(async () => {
                const updatedBalance = await getWalletBalance(
                  wallet.address,
                  wallet.network
                );
                console.log("updatedBalance checkpop", updatedBalance);
                if (parseFloat(updatedBalance) <= LOW_BALANCE_THRESHOLD) {
                  setShowZeroBalanceWarning(true);
                }
              }, BALANCE_CHECK_DELAY);
            } else {
                  setShowZeroBalanceWarning(false);
                }
             
            

            return { ...wallet };
          } catch (error) {
            console.error("Error fetching Solana balance:", error);
            toast.error("Failed to fetch wallet balance");
            return wallet;
          }
        })
      );
    }
  }, []);

  useEffect(() => {
    checkBalance();
  }, [checkBalance]);

  // Add this function to refresh the balance
  const refreshBalance = () => {
    checkBalance();
  };

  const handleLogout = () => {
    localStorage.removeItem("wallets");
    localStorage.removeItem("defaultWalletIndex");
    localStorage.removeItem("selectedAgents");
    setWallets([]);
    setCurrentWallet(null);
    setCurrentAgent(null);
    toast.success("Logged out successfully");
  };

  const renderAgentDropdown = () => (
    <div className="relative" ref={agentDropdownRef}>
      <button
        onClick={() => setShowAgentDropdown(!showAgentDropdown)}
        className={` flex items-center space-x-2 ${darkMode ? 'bg-teal-300/40 hover:bg-teal-600 text-white ':'bg-teal-50 hover:bg-teal-600 '} px-3 py-2 rounded-md transition-colors duration-200`}
      >
        <img
          src={currentAgent?.icon || ""}
          alt={currentAgent?.name || "Select Agent"}
          className="w-6 h-6"
        />
        <span>{currentAgent?.name + " Agent" || "Select Agent"}</span>
        <FiChevronDown
          className={`transition-transform duration-200 ${
            showAgentDropdown ? "transform rotate-180" : ""
          }`}
        />
      </button>
      {showAgentDropdown && (
        <div className={`absolute left-0 mt-2 w-48 ${darkMode ? 'bg-gray-600' : 'bg-white'} rounded-md shadow-lg z-20`}>
          {AllConnectAgents.map((agent) => (
            <div
              key={agent.id}
              className={`flex items-center justify-between px-4 py-2 ${darkMode ? 'hover:bg-gray-500' : 'hover:bg-gray-100'} `}
            >
              <button
                onClick={() => switchAgent(agent)}
                className="w-full text-left py-2 flex items-center space-x-2"
              >
                <img src={agent.icon} alt={agent.name} className="w-6 h-6" />
                <span>{agent.name + " Agent"}</span>
              </button>
              {AllConnectAgents.length > 1 && (
                <button
                  onClick={() => handleRemoveAgent(agent)}
                  className="text-red-500 ml-3 hover:text-red-700"
                  title="Remove Agent"
                >
                  <FiTrash2 size={16} />
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  // New component for the chat history dropdown
  const ChatHistoryDropdown = () => {
    return (
      <div className="relative" ref={chatHistoryRef}>
        <button
          onClick={toggleChatHistory}
          className={`flex items-center space-x-2 bg-green-50 
            ${darkMode ? "bg-teal-800 text-white":"bg-green-50 text-black hover:bg-green-100"} px-4 py-2.5 rounded-lg transition-colors duration-200  drop-shadow-sm`}
        >
          <FiMessageSquare className="text-green-600" />
          <span className={` ${darkMode ? "":""}font-medium`}>Chat History</span>
          <FiChevronDown
            className={`text-green-600 transition-transform duration-200 ${
              isChatHistoryOpen ? "transform rotate-180" : ""
            }`}
          />
        </button>
        <AnimatePresence>
          {isChatHistoryOpen && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className={`absolute left-0 mt-2 w-80 ${darkMode ? "bg-teal-900 text-white":"bg-emerald-50 text-black"}  rounded-lg shadow-xl z-20 max-h-[70vh] overflow-hidden`}
            >
              <div className="p-4 border-b border-gray-200">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-lg font-semibold ">
                    Chat History
                  </h3>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => createNewChatSession()}
                      className="text-green-600 hover:text-green-800 transition-colors duration-200"
                      title="New Chat"
                    >
                      <FiPlus size={20} />
                    </button>
                    <button
                      onClick={clearChatHistory}
                      className="p-2 text-red-500 hover:text-red-600 transition-colors duration-200"
                      title="Clear Chat History"
                    >
                      <FiTrash2 size={20} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="overflow-y-auto max-h-[calc(70vh-80px)]">
                {chatSessions.map((session, index) => (
                  <motion.div
                    key={session.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className={`p-3  ${darkMode ? "hover:-bg-teal-900":"hover:bg-green-100"} cursor-pointer transition-colors duration-200 ${
                      currentSessionId === session.id ? "bg-green-100 border-l-4 border-green-500"
                      : darkMode ? "bg-teal-500 hover:bg-teal-600 text-white ":"bg-white hover:bg-gray-50 text-black "
                    }`}
                    onClick={() => selectChatSession(index)}
                  >
                    <div className="flex justify-between items-center">
                      <div
                        onClick={() => {
                          selectChatSession(index);
                          setIsChatHistoryOpen(false);
                        }}
                        className="flex-grow pr-2"
                      >
                        <p className="font-medium text-gray-700 truncate flex items-center gap-2">
                          <FiMessageSquare className="text-green-600 text-sm" />
                          {session.messages[0]?.text.slice(0, 30) ||
                            "Empty chat"}
                        </p>
                      </div>
                      <div className="flex space-x-2">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          // Implement edit functionality
                          console.log("Edit session:", session.id);
                        }}
                        className="text-gray-500 hover:text-blue-600 transition-colors duration-200"
                        title="Edit Chat Title"
                      >
                        <FiEdit2 size={16} />
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteChatSession(session.id);
                        }}
                        className="text-red-500 hover:text-red-700 transition-colors duration-200"
                        title="Delete Chat"
                      >
                        <FiTrash2 size={16} />
                      </button>
                    </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };



  const ConfirmDialog = () => (
    <AnimatePresence>
      {showConfirmDialog && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        >
          <motion.div
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.9 }}
            className="bg-white rounded-lg p-6 max-w-sm w-full mx-4"
          >
            <h2 className="text-xl font-semibold mb-4">Confirm Removal</h2>
            <p>
              Are you sure you want to remove the {agentToRemove?.name} agent?
            </p>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={() => setShowConfirmDialog(false)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={confirmRemoveAgent}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Remove
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return (
<div className={`flex relative flex-col h-full overflow-hidden ${darkMode ? 'bg-[#011A1E]' : 'bg-gray-100'}`}>
      <Toaster richColors closeButton position="top-center" />
      {showZeroBalanceWarning && currentAccount && (
        <ZeroBalanceWarning
          address={currentAccount.address}
          onClose={() => setShowZeroBalanceWarning(false)}
          onRefresh={refreshBalance}
        />
      )}
      {/* Left Section - Chat Window */}
      {/* Add a button to trigger the tour */}
      {/* <button 
        onClick={() => setShowTourGuide(true)}
        className="fixed bottom-4 right-4 bg-blue-500 text-white px-4 py-2 rounded-full z-50"
      >
        Start Tour
      </button> */}
      {/* Chat nav */}
      <div className={`absolute top-0 left-0 right-0 ${darkMode?'bg-[#011112] border-gray-700 text-white':'bg-white border-gray-200 text-black'}  z-10 border-b `}>
        <div className="flex items-center justify-between py-2 px-6">
          <h1 className="text-xl font-medium">
            Chat with {currentAgent?.name}
          </h1>
          <div className="flex items-center space-x-4">
            {renderAgentDropdown()}
            {/* <WalletDropdown /> */}
            <YourWallet
              accounts={accounts}
              defaultAccountIndex={defaultAccountIndex}
              setDefaultAccountIndex={setDefaultAccountIndex}
              updateAccounts={updateAccounts}
              currentNetwork={currentNetwork}
            />
            <ChatHistoryDropdown />

            <div className="relative" ref={notificationRef}>
              <button
                onClick={() => setShowPopup(!showPopup)}
                className="p-2 rounded-full hover:bg-gray-100 focus:outline-none"
              >
                {/* <FiImage className="w-6 h-6 text-gray-600" /> */}
                <svg
                  className="inline-block w-6 h-6 fill-n-4 transition-colors group-hover:fill-primary-1 ui-open:fill-primary-1"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M11 3a1 1 0 0 1 .117 1.993L11 5l-3.664.001-.752.009-.299.008-.254.012-.31.025-.167.022-.141.027-.121.032-.106.038-.096.044a2 2 0 0 0-.874.874l-.048.106-.041.119-.034.139-.028.166-.022.2-.024.38-.01.317-.01.818v8.327l.01.818.01.317.015.265.009.115.022.2.028.166.034.139.041.119.048.106a2 2 0 0 0 .874.874l.106.048.119.041.139.034.166.028.2.022.38.024.317.01.818.01h8.327l.818-.01.317-.01.265-.015.115-.009.2-.022.166-.028.139-.034.119-.041.106-.048a2 2 0 0 0 .874-.874l.044-.096.02-.051.035-.112.015-.063.027-.141.022-.167.025-.31.012-.254.014-.647L19 16.2V13a1 1 0 0 1 1.993-.117L21 13l-.003 3.985-.006.443-.01.387-.016.338-.022.296-.03.26c-.046.327-.114.577-.218.825l-.084.186-.047.095a4 4 0 0 1-1.748 1.748l-.189.091c-.279.126-.551.207-.918.258l-.26.03-.296.022-.338.016-.387.01-.443.006L15.2 22l-8.185-.003-.443-.006-.387-.01-.338-.016-.296-.022-.26-.03c-.327-.046-.577-.114-.825-.218l-.186-.084-.095-.047a4 4 0 0 1-1.748-1.748l-.091-.189c-.126-.279-.207-.551-.258-.918l-.03-.26-.022-.296-.016-.338-.01-.387-.006-.443L2 16.2l.003-8.185.006-.443.01-.387.016-.338.022-.296.03-.26c.046-.327.114-.577.218-.825l.084-.186.047-.095a4 4 0 0 1 1.748-1.748l.189-.091a3.18 3.18 0 0 1 .918-.258l.26-.03.296-.022.338-.016.387-.01.443-.006L7.8 3H11zm9.828.172a4 4 0 0 1-5.657 5.657 4 4 0 0 1 5.657-5.657zm-4.243 1.414a2 2 0 0 0 2.828 2.828 2 2 0 0 0 .117-2.701l-.117-.127-.127-.117a2 2 0 0 0-2.701.117z"></path>
                </svg>
                {/* <div className="absolute top-2 right-2 w-2 h-2 bg-red-500 rounded-full"></div> */}
              </button>
              {showPopup && (
                <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-xl p-4 z-10">
                  <h3 className="text-lg font-semibold mb-2">Notifications</h3>
                  <p className="text-gray-600">
                    You have no new notifications.
                  </p>
                </div>
              )}
            </div>
            <div className="relative" ref={profileMenuRef}>
              <button onClick={() => setProfileMenuOpen(!profileMenuOpen)}>
                <img
                  src={profilePic}
                  alt="Profile"
                  className="w-9 h-9 rounded-full cursor-pointer"
                />
              </button>
              {profileMenuOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-xl">
                  <div className="p-4 border-b border-gray-200">
                    <img
                      src={profilePic}
                      alt="Profile"
                      className="w-16 h-16 rounded-full mx-auto mb-2"
                    />
                    <p className="font-bold text-center">{walletName}</p>
                  </div>
                  <ul>
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
                      onClick={handleLogout}
                    >
                      <FiLogOut className="mr-2" /> Log out
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
                      onClick={() => setShowSettings(true)}
                    >
                      <FiSettings className="mr-2" /> Settings
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <FiMoreVertical
              className="cursor-pointer text-gray-600 hover:text-gray-900 font-bold"
              onClick={() => setShowSettings(!showSettings)}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-grow overflow-hidden flex-row pt-14">
        <div
          className={`flex-grow flex flex-col border-r border-gray-200 bg-white ${
            showRightPanel ? "w-full" : "w-full"
          }`}
        >
          {/* Main Chat Window */}
          <div
            className={`flex-grow overflow-y-auto p-6 space-y-6 ${darkMode ? 'bg-[#011A1E]' : 'bg-white'}`}
            ref={chatContainerRef}
          >
            {renderAgentIntro()}
            {showSuggestionCards && messages.length === 0 ? (
              renderSuggestionCards()
            ) : (
              <>
                {/* {renderAgentIntro()} */}
                <AnimatePresence>
                  {messages.map((message, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.3 }}
                      className={`mb-4 ${message.type === "user" ? "ml-auto" : ""}`}
                    >
                      <MessageComponent
                        message={message}
                        messages={messages}
                        copyToClipboard={copyToClipboard}
                        setExpandedImage={setExpandedImage}
                        regenerateResponse={regenerateResponse}
                        staticBotImageUrl={staticBotImageUrl}
                        loadingGifUrl={loadingGifUrl}
                        completionGifUrl={completionGifUrl}
                        profile={profilePic}
                        agent={currentAgent?.name || ""}
                      />
                    </motion.div>
                  ))}
                </AnimatePresence>
              </>
            )}
            {/* <AnimatePresence>
              {transactionStatus && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4 rounded-lg shadow-md"
                >
                  <div className="flex items-center">
                    <div className="mr-3">
                      <motion.div
                        animate={{ rotate: 360 }}
                        transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                        className="w-6 h-6 border-t-2 border-blue-500 border-solid rounded-full"
                      />
                    </div>
                    <p className="font-semibold">{transactionStatus}</p>
                  </div>
                </motion.div>
              )}
            </AnimatePresence> */}
            <AnimatePresence>
              {transactionStatus && (
                <TransactionStatus initialStatus={transactionStatus} />
              )}
            </AnimatePresence>

            {isTransaction && (
              <div className="flex items-center mt-2">
              <img
                src={
                  isTransaction
                    ? loadingGifUrl
                    : currentAgent?.name === "Cosmos"
                    ? completionGifUrl
                    : staticBotImageUrl
                }
                alt="Bot"
                className="w-8 h-8 rounded-full mr-2"
              />
              <span className="text-sm text-gray-500">
                AI is thinking...
              </span>
            </div>
            )}

            {isGeneratingText && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="mb-4"
              >
                <div className="bg-gray-100 rounded-lg p-4">
                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    components={{
                      code: ({
                        node,
                        inline,
                        className,
                        children,
                        ...props
                      }: any) => {
                        const match = /language-(\w+)/.exec(className || "");
                        const code = String(children).replace(/\n$/, "");
                        const language = match ? match[1] : "text";
                        return !inline ? (
                          <div className="relative mt-4 mb-4">
                            <div className="relative top-0 right-0 left-0 flex justify-between items-center px-4 py-1 bg-gray-800 text-white rounded-t-lg">
                              <span className="text-sm font-mono">
                                {language}
                              </span>
                              <button
                                onClick={() => copyToClipboard(code)}
                                className="p-1 hover:bg-gray-700 rounded"
                              >
                                <FiCopy size={16} />
                              </button>
                            </div>
                            <SyntaxHighlighter
                              style={vscDarkPlus}
                              language={language}
                              PreTag="div"
                              className="rounded-b-lg border-t-0 !mt-0"
                              showLineNumbers
                              {...props}
                            >
                              {code}
                            </SyntaxHighlighter>
                          </div>
                        ) : (
                          <code
                            className="bg-gray-200 rounded px-1 py-0.5"
                            {...props}
                          >
                            {children}
                          </code>
                        );
                      },
                      img: ({ src, alt, ...props }) => (
                        <img
                          src={src}
                          alt={alt}
                          {...props}
                          className="cursor-pointer max-w-full h-auto"
                          onClick={() => src && setExpandedImage(src)}
                        />
                      ),
                    }}
                  >
                    {generatedText}
                  </ReactMarkdown>
                  {/* <TypingAnimation text="Generating Response..." /> */}
                </div>
                <div className="flex items-center mt-2">
                  <img
                    src={
                      isGeneratingText
                        ? loadingGifUrl
                        : currentAgent?.name === "Cosmos"
                        ? completionGifUrl
                        : staticBotImageUrl
                    }
                    alt="Bot"
                    className="w-8 h-8 rounded-full mr-2"
                  />
                  <span className="text-sm text-gray-500">
                    AI is thinking...
                  </span>
                </div>
              </motion.div>
            )}
            <div ref={messagesEndRef} />
          </div>

          {/* Input Area */}
          <div className={`border-t ${darkMode ? 'border-gray-500 bg-[#011A1E]' : 'border-gray-200'} p-4`}>
            <InputArea
              inputValue={inputValue}
              setInputValue={setInputValue}
              handleSend={handleSend}
              isGeneratingText={isGeneratingText}
              handlePaste={(e: React.ClipboardEvent) => {
                const pastedText = e.clipboardData.getData("text");
                if (pastedText.includes("\n")) {
                  e.preventDefault();
                  const formattedCode = "```\n" + pastedText + "\n```";
                  setInputValue((prevValue) => prevValue + formattedCode);
                }
              }}
              attachments={attachments}
              setAttachments={setAttachments}
            />
          </div>
        </div>

        {/* Right Section - Wallet Management and Chat History */}
        {/* {showRightPanel && (
          <div className="w-1/3 flex flex-col bg-white border-l border-gray-200">
           
            <div className="p-4 border-b border-gray-200">
              <h2 className="text-xl font-semibold mb-4">Select Agent</h2>
              <div className="space-y-2">
                {AllConnectAgents.map((agent) => (
                  <button
                    key={agent.id}
                    onClick={() => switchAgent(agent)}
                    className={`w-full text-left px-4 py-2 rounded-lg ${
                      currentAgent?.id === agent.id
                        ? "bg-blue-500 text-white"
                        : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                    }`}
                  >
                    <div className="flex items-center">
                      <img
                        src={agent.icon}
                        alt={agent.name}
                        className="w-6 h-6 mr-2"
                      />
                      {agent.name}
                    </div>
                  </button>
                ))}
              </div>
            </div>

           
            <div className="p-4 border-t border-gray-200 bg-gray-50">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-xl font-semibold text-gray-800">
                  Chat History
                </h3>
                <div className="flex space-x-2">
                  <button
                    onClick={() => setIsNewChatModalOpen(true)}
                    className="px-4 py-2 bg-green-400 text-white rounded-lg hover:bg-green-600 transition-colors duration-200 flex items-center"
                  >
                    <FiPlus className="mr-2" /> New Chat
                  </button>
                  <button
                    onClick={clearChatHistory}
                    className="p-2 text-red-500 hover:text-red-600 transition-colors duration-200"
                    title="Clear Chat History"
                  >
                    <FiTrash2 size={20} />
                  </button>
                </div>
              </div>
              <div className="space-y-4 max-h-[calc(100vh-374px)] overflow-y-auto pr-2 custom-scrollbar">
                {chatSessions.length === 0 ? (
                  <p className="text-gray-500 text-center py-4">
                    No chat history available
                  </p>
                ) : (
                  chatSessions.map((session, index) => (
                    <motion.div
                      key={session.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.3 }}
                      className={`p-4 border rounded-lg cursor-pointer hover:shadow-md transition-all duration-200 ${
                        currentSessionId === session.id
                          ? "bg-green-50 border-green-300"
                          : "bg-white hover:bg-gray-50"
                      }`}
                      onClick={() => selectChatSession(index)}
                    >
                      <div className="flex justify-between items-center">
                        <p className="font-medium text-gray-800 truncate flex-grow">
                          {session.title ||
                            (session.messages && session.messages.length > 0
                              ? session.messages[0].text.slice(0, 30) + "..."
                              : "Empty chat")}
                        </p>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteChatSession(session.id);
                          }}
                          className="p-1 text-gray-400 hover:text-red-500 transition-colors duration-200"
                          title="Delete Chat"
                        >
                          <FiX size={16} />
                        </button>
                      </div>
                      <p className="text-sm text-gray-500 mt-1">
                        {session.messages.length} message
                        {session.messages.length !== 1 ? "s" : ""}
                      </p>
                      <div className="flex items-center mt-2 space-x-2">
                        <span className="text-xs text-gray-400">
                          {new Date(
                            session.messages[session.messages.length - 1]
                              ?.time || Date.now()
                          ).toLocaleString()}
                        </span>
                        <span className="text-xs px-2 py-1 bg-gray-200 text-gray-700 rounded-full">
                          {AllConnectAgents.find(
                            (agent) => agent.id === session.agentId
                          )?.name || "Unknown Agent"}
                        </span>
                      </div>
                    </motion.div>
                  ))
                )}
              </div>
            </div>

            <AnimatePresence>
              {isNewChatModalOpen && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                >
                  <motion.div
                    initial={{ scale: 0.9, y: 20 }}
                    animate={{ scale: 1, y: 0 }}
                    exit={{ scale: 0.9, y: 20 }}
                    className="bg-white rounded-lg p-6 w-96 max-w-md"
                  >
                    <h2 className="text-2xl font-semibold mb-4">
                      Create New Chat
                    </h2>
                    <p className="text-gray-600 mb-4">
                      Start a new conversation with the current agent.
                    </p>
                    <div className="flex justify-end space-x-3">
                      <button
                        onClick={() => setIsNewChatModalOpen(false)}
                        className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 transition-colors duration-200"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={createNewChatSession}
                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200"
                      >
                        Create
                      </button>
                    </div>
                  </motion.div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}  */}
      </div>
      {/* Toggle button for right panel */}
      {/* <button
        onClick={() => setShowRightPanel(!showRightPanel)}
        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-200 p-2 rounded-l hover:bg-gray-300 transition-colors"
      >
        {showRightPanel ? <FiChevronRight /> : <FiChevronLeft />}
      </button> */}
      {/* Expanded Image Modal */}
      {expandedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative max-w-4xl max-h-4xl">
            <img
              src={expandedImage}
              alt="Expanded"
              className="max-w-full max-h-full"
            />
            <button
              onClick={() => setExpandedImage(null)}
              className="absolute top-4 right-4 text-white bg-gray-800 rounded-full p-2 hover:bg-gray-700 transition-colors"
            >
              <FiX size={24} />
            </button>
          </div>
        </div>
      )}
      {/* Settings Modal */}
      {showSettings && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-96">
            <h2 className="text-2xl font-semibold mb-4">Settings</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  API URL
                </label>
                <input
                  type="text"
                  value={chainConfig.rpcEndpoint}
                  onChange={(e) => {
                    // Update chainConfig here
                    // You might want to create a function to update chainConfig and save it to localStorage
                  }}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Chain ID
                </label>
                <input
                  type="text"
                  // value={chainConfig.chainId}
                  onChange={(e) => {
                    // Update chainConfig here
                  }}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
              {/* Add more settings as needed */}
            </div>
            <div className="mt-6 flex justify-end space-x-3">
              <button
                onClick={() => setShowSettings(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  // Save settings here
                  setShowSettings(false);
                  toast.success("Settings saved successfully");
                }}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Feature Tour */}
      {showFeatureTour && <FeatureTour onComplete={handleTourComplete} />}
      <ConfirmDialog />
    </div>
  );
};

export default ChatPage;
