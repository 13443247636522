import React, { useState, useEffect, useCallback } from "react";
import { Secp256k1HdWallet } from "@cosmjs/amino";
import { useTheme } from '../../contexts/ThemeContext';
// import { StargateClient } from "@cosmjs/stargate";
import { Toaster, toast } from "sonner";
import WalletBanner from "./WalletBanner";
import {
  Eye,
  EyeOff,
  Plus,
  Check,
  Trash2,
  RefreshCw,
  // Send,
  // BarChart2,
  Upload,
  Download,
  Copy,
} from "lucide-react";
import {
  getWalletInfoFromMnemonic,
  // validateWalletName,
  shortenAddress,
  getWalletBalance,
} from "../../Components/NewChatWindows/walletUtils";
import { motion } from "framer-motion";
// import { getRecentTransactions } from '../NewChatWindows/walletUtils';
import { Account } from "../NewChatWindows/types";
//solana
// import { Keypair, PublicKey } from '@solana/web3.js';
// import { derivePath } from 'ed25519-hd-key';
// import * as bip39 from "bip39";

// interface Account {
//   name: string;
//   mnemonic: string;
//   address: string;
//   balance: string;
// }

interface WalletPageProps {
  profilePic: string;
}

const WalletPage: React.FC<WalletPageProps> = ({ profilePic }) => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [defaultAccountIndex, setDefaultAccountIndex] = useState<number>(0);
  const [isPrivateMode, setIsPrivateMode] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  // const [showAddAccountModal, setShowAddAccountModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  // const [newAccountName, setNewAccountName] = useState("");
  const [importMnemonic, setImportMnemonic] = useState("");
  const [showExportModal, setShowExportModal] = useState(false);
  const [exportMnemonic, setExportMnemonic] = useState("");
  const [recentTransactions, setRecentTransactions] = useState<any[]>([]);

  const username = localStorage.getItem("walletname") || "User";

  // Inside the component, add this line near the top
const { darkMode } = useTheme();
  const loadWallets = useCallback(async () => {
    setIsLoading(true);
    try {
      const storedAccounts = localStorage.getItem("accounts");
      if (storedAccounts) {
        const parsedAccounts = JSON.parse(storedAccounts);
        setAccounts(parsedAccounts);
        const storedDefaultIndex = localStorage.getItem("defaultAccountIndex");
        setDefaultAccountIndex(
          storedDefaultIndex ? parseInt(storedDefaultIndex, 10) : 0
        );
      } else {
        // If no accounts are stored, initialize with an empty array
        setAccounts([]);
        setAccounts([]);
        toast.error("No accounts found. Please create a new wallet.");
      }
    } catch (error) {
      console.error("Error loading wallets:", error);
      toast.error("Failed to load wallets. Please try again.");
      // Initialize with empty array on error
      setAccounts([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // useEffect(() => {
  //   loadAccounts();
  // }, []);
  useEffect(() => {
    const loadData = async () => {
      await loadWallets();
      await loadAccounts();
      // Any other async operations...
    };
    loadData();
  }, [loadWallets]);

  const loadAccounts = useCallback(async () => {
    setIsLoading(true);
    try {
      const storedAccounts = localStorage.getItem("accounts");
      console.log("Stored accounts:", storedAccounts);
      if (storedAccounts) {
        const parsedAccounts = JSON.parse(storedAccounts);
        console.log("Parsed accounts:", parsedAccounts);
        setAccounts(parsedAccounts);
        const updatedAccounts = await Promise.all(
          parsedAccounts.map(async (account: Account) => {
            const balance = await getWalletBalance(
              account.address,
              account.network
            );
            return { ...account, balance };
          })
        );
        setAccounts(updatedAccounts);
        const storedDefaultIndex = localStorage.getItem("defaultAccountIndex");
        const defaultIndex = storedDefaultIndex
          ? parseInt(storedDefaultIndex, 10)
          : 0;
        setDefaultAccountIndex(defaultIndex);
        // if (accounts.length > 0) {
        //   const defaultAccount = accounts[defaultIndex];
        //   const recentTransactions = await getRecentTransactions(defaultAccount.address);
        //   setRecentTransactions(recentTransactions);
        //   // You might want to store these transactions in state or pass them to WalletBanner
        // }
      }
    } catch (error) {
      console.error("Error loading accounts:", error);
      toast.error("Failed to load accounts. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleQuickCreateWallet = async () => {
    setIsLoading(true);
    try {
      const wallet = await Secp256k1HdWallet.generate(24);

      const [{ address }] = await wallet.getAccounts();
      const newWalletName = `Wallet ${accounts.length - 1}`;
      const newWallet = await getWalletInfoFromMnemonic(
        wallet.mnemonic,
        newWalletName
      );
      const updatedAccounts = [...accounts, newWallet[0], newWallet[1]]; // Use newWallet[0] as getWalletInfoFromMnemonic returns an array
      setAccounts(updatedAccounts);
      localStorage.setItem("accounts", JSON.stringify(updatedAccounts));
      const newIndex = updatedAccounts.length - 1;
      setDefaultAccountIndex(newIndex);
      localStorage.setItem("defaultAccountIndex", newIndex.toString());
      toast.success("New wallet created and set as default");
    } catch (error) {
      console.error("Error creating new wallet:", error);
      toast.error("Failed to create new wallet. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // const handleAddAccount = async () => {
  //   setShowAddAccountModal(true);
  //   if (!validateWalletName(newAccountName)) {
  //     toast.error('Invalid account name. Please use 1-50 characters.');
  //     return;
  //   }
  //   setIsLoading(true);
  //   try {
  //     const wallet = await Secp256k1HdWallet.generate(24);
  //     const [{ address }] = await wallet.getAccounts();
  //     const newAccount = await getWalletInfoFromMnemonic(wallet.mnemonic, newAccountName);
  //     const updatedAccounts = [...accounts, newAccount];
  //     setAccounts(updatedAccounts);
  //     localStorage.setItem('accounts', JSON.stringify(updatedAccounts));
  //     const newIndex = updatedAccounts.length - 1;
  //     setDefaultAccountIndex(newIndex);
  //     localStorage.setItem('defaultAccountIndex', newIndex.toString());
  //     toast.success('New account added and set as default');
  //     setShowAddAccountModal(false);
  //     setNewAccountName('');
  //   } catch (error) {
  //     console.error('Error adding new account:', error);
  //     toast.error('Failed to add new account. Please try again.');
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleImportWallet = async () => {
    if (!importMnemonic.trim()) {
      console.log(importMnemonic.trim());
      toast.error("Please enter a valid mnemonic");
      return;
    }

    setIsLoading(true);
    try {
      const newWalletName = `Imported Wallet ${accounts.length + 1}`;
      const newWallet = await getWalletInfoFromMnemonic(
        importMnemonic,
        newWalletName
      );
      const updatedAccounts = [...accounts, newWallet[0], newWallet[1]]; // Use newWallet[0] as getWalletInfoFromMnemonic returns an array
      setAccounts(updatedAccounts);
      localStorage.setItem("accounts", JSON.stringify(updatedAccounts));
      const newIndex = updatedAccounts.length - 1;
      setDefaultAccountIndex(newIndex);
      localStorage.setItem("defaultAccountIndex", newIndex.toString());
      toast.success("Wallet imported successfully and set as default");
      setShowImportModal(false);
      setImportMnemonic("");
    } catch (error) {
      console.error("Error importing wallet:", error);
      toast.error(
        "Failed to import wallet. Please check your mnemonic and try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSetDefaultAccount = (
    groupIndex: number,
    accountIndex: number
  ) => {
    const index = groupIndex * 2 + accountIndex;
    setDefaultAccountIndex(index);
    localStorage.setItem("defaultAccountIndex", index.toString());
    const selectedAccount = accounts[index];
    localStorage.setItem("mnemonic", selectedAccount.mnemonic);
    localStorage.setItem("account", selectedAccount.address);
    localStorage.setItem("balance", selectedAccount.balance);
    toast.success(`Set ${accounts[index].name} as default account`);
  };

  const handleDeleteAccount = (groupIndex: number) => {
    if (accounts.length <= 2) {
      toast.error("Cannot delete the only account");
      return;
    }

    const actualIndex = groupIndex * 2;
    const updatedAccounts = accounts.filter(
      (_, i) => i < actualIndex || i >= actualIndex + 2
    );
    setAccounts(updatedAccounts);
    localStorage.setItem("accounts", JSON.stringify(updatedAccounts));

    if (
      actualIndex === defaultAccountIndex ||
      actualIndex + 1 === defaultAccountIndex
    ) {
      const newDefaultIndex = actualIndex > 0 ? actualIndex - 2 : 0;
      setDefaultAccountIndex(newDefaultIndex);
      localStorage.setItem("defaultAccountIndex", newDefaultIndex.toString());
    } else if (defaultAccountIndex > actualIndex) {
      setDefaultAccountIndex(defaultAccountIndex - 2);
      localStorage.setItem(
        "defaultAccountIndex",
        (defaultAccountIndex - 2).toString()
      );
    }

    toast.success("Accounts deleted successfully");
  };

  const togglePrivateMode = () => {
    setIsPrivateMode(!isPrivateMode);
  };

  const refreshBalances = async () => {
    setIsLoading(true);
    try {
      const updatedAccounts = await Promise.all(
        accounts.map(async (account) => {
          const balance = await getWalletBalance(
            account.address,
            account.network
          );
          return { ...account, balance };
        })
      );
      setAccounts(updatedAccounts);
      localStorage.setItem("accounts", JSON.stringify(updatedAccounts));
      toast.success("Balances refreshed successfully");
    } catch (error) {
      console.error("Error refreshing balances:", error);
      toast.error("Failed to refresh balances. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleExportWallet = (index: number) => {
    setExportMnemonic(accounts[index].mnemonic);
    setShowExportModal(true);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  };
  const groupedAccounts = [];
  for (let i = 0; i < accounts.length; i += 2) {
    groupedAccounts.push(accounts.slice(i, i + 2));
  }
  console.log("LL ", groupedAccounts);

  return (
    <div className={`max-h-screen overflow-y-auto ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'}`}>
      <Toaster richColors closeButton position="top-center" />

      <div className="container mx-auto px-4 py-8">
        <WalletBanner
          profilePic={profilePic}
          profileName={username}
          publicKey={accounts[defaultAccountIndex]?.address || ""}
          atomBalance={parseFloat(
            accounts[defaultAccountIndex]?.balance || "0"
          )}
          dollarValue={
            parseFloat(accounts[defaultAccountIndex]?.balance || "0") * 0.1
          }
          recentTransactions={recentTransactions}
        />

        <div className={` shadow rounded-xl p-6 mb-6 ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-2xl font-semibold">Wallet Information</h3>
            <div className="flex space-x-2">
              <button
                onClick={refreshBalances}
                className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 transition-colors"
                disabled={isLoading}
              >
                <RefreshCw
                  className={`w-5 h-5 ${isLoading ? "animate-spin" : ""}`}
                />
              </button>
              <button
                onClick={togglePrivateMode}
                className="bg-yellow-500 text-white p-2 rounded-full hover:bg-yellow-600 transition-colors"
              >
                {isPrivateMode ? (
                  <Eye className="w-5 h-5" />
                ) : (
                  <EyeOff className="w-5 h-5" />
                )}
              </button>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <p className={`text-sm ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>Public Key:</p>
              <p className="font-medium break-all">
                {isPrivateMode
                  ? "********"
                  : shortenAddress(
                      accounts[defaultAccountIndex]?.address || "",
                      8
                    )}
              </p>
            </div>
            <div>
              <p className={`text-sm ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>Balance:</p>
              <p className="font-medium">
                {isPrivateMode
                  ? "********"
                  : `${accounts[defaultAccountIndex]?.balance || "0"} ATOM`}
              </p>
            </div>
          </div>
        </div>

        <div className={` ${darkMode ? 'bg-gray-800 ' :'bg-white'} shadow rounded-xl p-6 mb-6 `}>
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold">
              Your Wallets ({accounts.length / 2})
            </h3>
            <div className="flex space-x-2">
              <button
                onClick={handleQuickCreateWallet}
                className="${darkMode ? 'bg-green-600 hover:bg-green-700  text-white' : 'bg-green-500 hover:bg-green-600  text-white'}`} text-white px-4 py-2 rounded-full flex items-center"
              >
                <Plus className="mr-2" /> Quick Create
              </button>
              {/* <button
                onClick={() => setShowAddAccountModal(true)}
                className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 flex items-center"
              >
                <Plus className="mr-2" /> Add Account
              </button> */}
              <button
                onClick={() => setShowImportModal(true)}
                className="bg-purple-500 text-white px-4 py-2 rounded-full hover:bg-purple-600 flex items-center"
              >
                <Upload className="mr-2" /> Import
              </button>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {groupedAccounts.map((accountPair, groupIndex) => (
              <motion.div
                key={groupIndex}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className="border border-gray-200 p-4 rounded-xl shadow-sm"
              >
                {accountPair.map((account, accountIndex) => {
                  const actualIndex = groupIndex * 2 + accountIndex;
                  return (
                    <div key={accountIndex} className="mb-4">
                      <div className="flex justify-between items-start">
                        <div>
                          <p className="font-bold">{account.name}</p>
                          <p className="text-sm text-gray-500">
                            {shortenAddress(account.address)}
                          </p>
                          <p className="text-sm">
                            <span className="text-gray-500">Balance:</span>{" "}
                            {isPrivateMode
                              ? "********"
                              : `${account.balance} ATOM`}
                          </p>
                        </div>
                        <div className="flex flex-col space-y-2">
                          {actualIndex === defaultAccountIndex ? (
                            <span className="text-green-500 flex items-center">
                              <Check className="mr-1" /> Default
                            </span>
                          ) : (
                            <button
                              onClick={() =>
                                handleSetDefaultAccount(
                                  groupIndex,
                                  accountIndex
                                )
                              }
                              className="text-blue-500 hover:text-blue-600"
                            >
                              Set Default
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="mt-4 flex space-x-2 justify-between">
                  <button
                    onClick={() => handleExportWallet(groupIndex * 2)}
                    className="bg-yellow-500 text-white px-3 py-1 rounded-full text-sm hover:bg-yellow-600 flex items-center"
                  >
                    <Download className="w-4 h-4 mr-1" /> Export
                  </button>
                  <button
                    onClick={() => handleDeleteAccount(groupIndex)}
                    className="text-red-500 hover:text-red-600"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Add Account Modal */}
      {/* {showAddAccountModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-96">
            <h2 className="text-2xl font-semibold mb-4">Add New Account</h2>
            <input
              type="text"
              value={newAccountName}
              onChange={(e) => setNewAccountName(e.target.value)}
              placeholder="Enter account name"
              className="w-full p-2 border border-gray-300 rounded-md mb-4"
            />
           <div className="flex justify-end space-x-3">
              <button 
                onClick={() => setShowAddAccountModal(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button 
                onClick={handleAddAccount}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
              >
                Add Account
              </button>
            </div>
          </div>
        </div>
      )} */}

      {/* Import Wallet Modal */}
      {showImportModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-96">
            <h2 className="text-2xl font-semibold mb-4">Import Wallet</h2>
            <textarea
              value={importMnemonic}
              onChange={(e) => setImportMnemonic(e.target.value)}
              placeholder="Enter wallet mnemonic"
              className="w-full p-2 border border-gray-300 rounded-md mb-4 h-32"
            />
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowImportModal(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleImportWallet}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
              >
                Import Wallet
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Export Wallet Modal */}
      {showExportModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-96">
            <h2 className="text-2xl font-semibold mb-4">Export Wallet</h2>
            <p className="text-sm text-gray-600 mb-2">Your mnemonic phrase:</p>
            <div className="bg-gray-100 p-3 rounded-md mb-4 break-all">
              {exportMnemonic}
            </div>
            <div className="flex justify-between items-center mb-4">
              <p className="text-sm text-red-500">
                Keep this phrase safe and secret!
              </p>
              <button
                onClick={() => copyToClipboard(exportMnemonic)}
                className="flex items-center text-blue-500 hover:text-blue-600"
              >
                <Copy className="w-4 h-4 mr-1" /> Copy
              </button>
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => setShowExportModal(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WalletPage;
