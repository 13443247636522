import React, { useState, useEffect, useCallback,useRef } from "react";
// import { motion, AnimatePresence } from "framer-motion";
import { ScheduledEvent } from './types'; 
import {  Wallet ,Account } from "../NewChatWindows/types";
import "./Main.css";
import {
  Search,
  } from "lucide-react";
  import{
  FiSettings,
  FiLogOut,
  FiStar

} from "react-icons/fi";
import gofu8 from "../../Assets/Image/Jerry-Lee_Bosmans-removebg-preview.png";
import gofu1 from "../../Assets/Image/Rectangle.png";
import gofu2 from "../../Assets/Image/Splaaashes__Design_Elements__1_-removebg-preview.png";
import EventComponent from "./EventComponent";
import { Toaster, toast } from "sonner";
import Comdexbg from "../../Assets/Image/download-removebg-preview (2).png" 
// import { Link } from "react-router-dom";
import Atombg from "../../Assets/Image/atombg.png" 
import Solanabg from "../../Assets/Image/Solana-Crypto-Logo-Transparent-removebg-preview.png";
// import { momentLocalizer } from "react-big-calendar";
// import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  
  Legend,
  ResponsiveContainer,
} from "recharts";
import { FaRobot, FaBell, FaMicrophone } from "react-icons/fa";
// import { getWalletBalance } from '../../Components/NewChatWindows/walletUtils';
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from '../../contexts/ThemeContext';
interface DashboardProps {
  onLogout: () => void;
  profilePic: string;
  AllConnectAgents: Agent[];
  currentAccount: Account | undefined;
}
// interface Account {
//   name: string;
//   mnemonic: string;
//   address: string;
//   balance: string;
// }
interface Event {
  id: string;
  title: string;
  date: Date;
  startTime: string;
  endTime: string;
  description: string;
  category: string;
  agentId: string | null;
  transactionDetails: {
    amount: number;
    recipient: string;
    tokenType: string;
  } | null;
  isCompleted: boolean;
}
interface Review {
  id: string;
  user: string;
  rating: number;
  comment: string;
  date: string;
}
interface Agent {
  id: string;
  name: string;
  type: string;
  action: string;
  icon: string;
  description: string;
  longDescription: string;
  version: string;
  developer: string;
  downloadCount: number;
  rating: number;
  reviews: Review[];
  categories: string[];
  releaseDate: string;
  lastUpdate: string;
  size: string;
  compatibility: string[];
  features: string[];
  hourlyRate?: number; // New field for displaying price per hour
}


const Dashboard: React.FC<DashboardProps> = ({
  onLogout,
  profilePic,
  AllConnectAgents,
  currentAccount,
}) => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  // const [transactionHistory, setTransactionHistory] = useState<any[]>([]);
  const [isHovered, setIsHovered] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [walletName, setWalletName] = useState("");
  const [events, setEvents] = useState<ScheduledEvent[]>([]);
  // const [currentWallet, setCurrentWallet] = useState<Wallet | null>(null);
  const [defaultAccountIndex, setDefaultAccountIndex] = useState<number>(0);
  const notificationRef = useRef<HTMLDivElement>(null);
  const profileMenuRef = useRef<HTMLDivElement>(null);
const [accounts, setAccounts] = useState<Account[]>([]);
  const [currentNetwork, setCurrentNetwork] = useState<string>(() => {
    return AllConnectAgents.find(agent => agent.name === "Solana")
      ? "Solana"
      : AllConnectAgents.find(agent => agent.name === "Solana")
      ? "solana"
      : "unknown";
  });
  const navigate = useNavigate();
  const { darkMode } = useTheme();
  useEffect(() => {

    const handleMouseMove = (event: MouseEvent) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target as Node)) {
        setShowNotifications(false);
      }
      if (profileMenuRef.current && !profileMenuRef.current.contains(event.target as Node)) {
        setShowProfileMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Load accounts and defaultAccountIndex from localStorage
    const storedAccounts = localStorage.getItem('accounts');
    const storedDefaultIndex = localStorage.getItem('defaultAccountIndex');
    
    if (storedAccounts) {
      setAccounts(JSON.parse(storedAccounts));
    }
    
    if (storedDefaultIndex) {
      setDefaultAccountIndex(parseInt(storedDefaultIndex, 10));
    }
  }, []);

  // Sample notifications data
  const notifications = [
    { id: 1, message: "New agent available", time: "2 minutes ago" },
    { id: 2, message: "Transaction completed", time: "1 hour ago" },
    { id: 3, message: "Wallet balance updated", time: "3 hours ago" },
  ];


  // Add this useEffect to load events from localStorage
  useEffect(() => {
    const storedWalletName = localStorage.getItem("walletname");
      if (storedWalletName) setWalletName(storedWalletName);
    const storedEvents = localStorage.getItem("events");
    if (storedEvents) {
      setEvents(
        JSON.parse(storedEvents).map((event: Event) => ({
          ...event,
          date: new Date(event.date),
        }))
      );
    }
  }, []);

  // Define data

  // setTransactionHistory([
  //   { date: '2023-07-01', type: 'Send', amount: 50, token: 'ATOM' },
  //   { date: '2023-07-03', type: 'Receive', amount: 2, token: 'ETH' },
  //   { date: '2023-07-05', type: 'Stake', amount: 100, token: 'SOL' },
  //   { date: '2023-07-07', type: 'Swap', amount: 500, token: 'DOT' },
  // ]);
  // Add this function to save events to localStorage
  const saveEvents = (updatedEvents: ScheduledEvent[]) => {
    localStorage.setItem("events", JSON.stringify(updatedEvents));
    setEvents(updatedEvents);
  };

  // Add this function to handle adding a new event
  const executeTransaction = async (event: Event) => {
    if (!event.agentId || !event.transactionDetails) return;

    const agent = AllConnectAgents.find((a) => a.id === event.agentId);
    if (!agent) {
      console.error("Agent not found");
      return;
    }

    try {
      // This is where you'd integrate with your blockchain transaction logic
      // For demonstration, we'll just log the transaction details
      console.log(`Executing transaction for event: ${event.title}`);
      console.log(`Agent: ${agent.name}`);
      console.log(
        `Amount: ${event.transactionDetails.amount} ${event.transactionDetails.tokenType}`
      );
      console.log(`Recipient: ${event.transactionDetails.recipient}`);

      // In a real application, you'd call your blockchain transaction function here
      // await executeBlockchainTransaction(agent, event.transactionDetails);

      toast.success(`Transaction executed for event: ${event.title}`);
    } catch (error) {
      console.error("Failed:", error);
      toast.error(`Failed for event: ${event.title}`);
    }
  };

  const checkAndExecuteTransactions = useCallback(async () => {
    const now = new Date();
    const updatedEvents = events.map((event) => {
      if (
        !event.isCompleted &&
        event.date <= now &&
        event.agentId &&
        event.transactionDetails
      ) {
        // Execute the transaction here
        executeTransaction(event);
        return { ...event, isCompleted: true };
      }
      return event;
    });

    if (updatedEvents.some((event, index) => event !== events[index])) {
      saveEvents(updatedEvents);
    }
  }, [events, executeTransaction]);

  useEffect(() => {
    const intervalId = setInterval(checkAndExecuteTransactions, 60000); // Check every minute
    return () => clearInterval(intervalId);
  }, [checkAndExecuteTransactions]);



  // const localizer = momentLocalizer(moment);

  const [isChatOpen, setIsChatOpen] = useState(false);
  // const [chatInput, setChatInput] = useState("");

  
  const findFirstAccountOfNetwork = useCallback((network: string) => {
  return accounts.findIndex(account => account.network.toLowerCase() === network.toLowerCase());
}, [accounts]);


  const handleChatRedirect = useCallback( async(agent: Agent) => {
    // setCurrentAgent(agent);
  const newNetwork = agent.name.toLowerCase();
  console.log("networkname", newNetwork)
  setCurrentNetwork(newNetwork);

  // Update selectedAgents as an array


  // Find the first account of the new network
  const newAccountIndex = findFirstAccountOfNetwork(newNetwork);
  console.log("New account index:", newAccountIndex);
  if (newAccountIndex !== -1) {
    console.log("Setting defaultAccountIndex to:", newAccountIndex);
    setDefaultAccountIndex(newAccountIndex); // Update the state as well
    
    localStorage.setItem("defaultAccountIndex", newAccountIndex.toString());
    // Update localStorage
    const selectedAccount = accounts[newAccountIndex];
    localStorage.setItem("selectedAgent", JSON.stringify(agent));
    localStorage.setItem("currentAccount", JSON.stringify(selectedAccount));
    localStorage.setItem("mnemonic", selectedAccount.mnemonic);
    localStorage.setItem("account", selectedAccount.address);
    localStorage.setItem("balance", selectedAccount.balance);


    await toast.success(`Switched to ${agent.name} agent and ${selectedAccount.name} account`);
  } else {
    // If no account of the new network exists, just switch the agent
    await toast.warning(`Switched to ${agent.name} agent, but no ${newNetwork} account found`);
    // Store only the agent information if no matching account is found
    localStorage.setItem("selectedAgent", JSON.stringify(agent));
  }
  // Navigate to the chat page
    navigate('/chat');
  

}, [accounts, findFirstAccountOfNetwork, navigate,setDefaultAccountIndex]);

    
  

  const renderAgentCard = (agent: Agent) => (
    <div
      key={agent.id}
      className= {`${darkMode ? "bg-[#011A1E] text-white border-teal-400" : "bg-teal-50 text-black border-teal-300"} rounded-lg shadow-md border shadow-glow p-4 hover:shadow-lg transition-shadow duration-300`}
    >
      <div className="flex justify-between items-start mb-3">
        <div className="flex items-center gap-3">
          <div className="bg-gray-50 rounded-lg p-[6px] w-10">
            <img
              src={agent.icon}
              alt={agent.name}
              className="w-7 h-7 mr-3 rounded-full"
            />
          </div>

          <div>
            <h3 className="font-semibold text-base">{agent.name}</h3>
            <p className="text-xs text-gray-500 font-thin">{agent.developer}</p>
          </div>
        </div>
        <button className="text-gray-300 hover:text-yellow-500 transition-colors">
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
            />
          </svg> */}
          <FiStar className=" text-yellow h-6 w-6"/>
        </button>
      </div>
      <div className="flex flex-wrap gap-2 mb-3">
        {agent.categories.slice(0, 3).map((category, index) => {
          const colors = [
            "bg-purple-100 text-purple-800",
            "bg-green-100 text-green-800",
            "bg-yellow-100 text-yellow-800",
          ];
          return (
            <span
              key={index}
              className={`px-2 py-1 rounded-lg font-semibold text-xs ${colors[index]}`}
            >
              {category}
            </span>
          );
        })}
      </div>
      <p className="text-sm text-gray-500 font-normal mb-3  line-clamp-2">
        {agent.description}
      </p>

      <div className="flex justify-between items-center ">
        <span className="font-semibold text-lg">
          {/* ${agent.hourlyRate?.toFixed(2)}/hr */}
        </span>
        
        {/* <Link to="/chat" className="mx-auto"> */}
        <button onClick={() => handleChatRedirect(agent)} className="background-bg-teal-600 hover:bg-teal-700 hover:shadow-lg text-white font-medium px-4 py-2 rounded-xl text-sm mx-auto transition-colors">
          Connect to Agent
        </button>
        {/* </Link> */}
      </div>
      {/* <div className="flex justify-between items-center">
        <button className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-full text-sm transition-colors">
          Connect to Agent
        </button>
        <span className="text-sm text-gray-500">
          Posted {getRandomDaysAgo()} days ago
        </span>
      </div> */}
    </div>
  );

  // Helper function to generate random "days ago" for demonstration
  // const getRandomDaysAgo = () => Math.floor(Math.random() * 14) + 1;

  const tokens = [
    { name: "Cosmos", symbol: "ATOM",price: 0, img:Atombg ,balance: currentAccount ? parseFloat(currentAccount.balance) : 0,},
    { name: "Solana", symbol: "SOL", img:Solanabg,balance: currentAccount ? parseFloat(currentAccount.balance) : 0, },
    { name: "Comdex", symbol: "CMDX", price: 0, img:Comdexbg,balance: currentAccount ? parseFloat(currentAccount.balance) : 0,},
  ];
  // const events = [
  //   {
  //     start: new Date(),
  //     end: new Date(new Date().getTime() + 60 * 60 * 1000), // Add 1 hour
  //     title: "Sample Event",
  //   },
  // ];
  const chartData = [
    { name: "Jan", ATOM: 4000, SOL: 2400, CMDX: 2400 },
    { name: "Feb", ATOM: 3000, SOL: 1398, CMDX: 2210 },
    { name: "Mar", ATOM: 2000, SOL: 9800, CMDX: 2290 },
    { name: "Apr", ATOM: 2780, SOL: 3908, CMDX: 2000 },
    { name: "May", ATOM: 1890, SOL: 4800, CMDX: 2181 },
    { name: "Jun", ATOM: 2390, SOL: 3800, CMDX: 2500 },
    { name: "Jul", ATOM: 3490, SOL: 4300, CMDX: 2100 },
  ];

  return (
    <div className= {`flex-1 flex flex-col  scrollbar-none bg-primary ${darkMode ? 'dark' : ''}`}>
      <Toaster richColors closeButton position="top-center"  />
      {/* Top Navigation */}
      
      <div className={`relative w-full  ${ darkMode
        ? " bg-gradient-to-r from-teal-700  via-teal-600/60 to-teal-100/15 text-white": "bg-gradient-to-r from-teal-700  via-teal-600/60 to-teal-100 text-black" }`}>
        {/* Particle effect (simplified) */}
        <div className="absolute inset-0 bg-opacity-50 bg-black/5"></div>
        {/* Abstract shape */}
        {/* <div className="absolute top-0 right-0 w-full h-full overflow-hidden">
          <div className="absolute -right-1/4 -bottom-1/2 w-full h-full bg-gradient-to-br from-teal-900 to-teal-950 rounded-full opacity-100 "></div>
        </div> */}
        <div className="overflow-hidden">
          {/* <img
          src={gofu1}
          alt="elements"
          className="absolute w-80 object-contain opacity-90 blur-sm "
        /> */}
        {/* <img src={gofu6} alt="elements" className="absolute w-80 -bottom-14 left-1/3 opacity-25 -rotate-12 " /> */}
        {/* <img src={gofu7} alt="elements" className="absolute w-80 top-0 opacity-25 -rotate-12 left-0"/> */}
        {/* <img
          src={gofu8}
          alt="elements"
          className="absolute w-96 opacity-25 -rotate-90 blur-sm"
        />
        <img
          src={gofu2}
          alt="elements"
          className="absolute w-96 opacity-25 -top-10 rotate-45 -right-44 blur-md"
        /> */}
        </div>
        



        {/* Main content */}
        <div className="relative z-50 mt-12 md:mt-0 flex h-full flex-col justify-between p-7 px-8 ">
          {/* Left side content */}
          <div className="max-w-2xl">
            <h1
              className="mb-2 text-2xl font-semibold "
              // style={{
              //   background: 'linear-gradient(to right, #ffffff, #a78bfa)',
              //   WebkitBackgroundClip: 'text',
              //   WebkitTextFillColor: 'transparent',
              // }}
            >
              Welcome to Your Blockchain LLM Hub
            </h1>
            <p className="text-sm text-gray-200 font-light w-72">
              Manage your agents across multiple blockchains and AI systems
            </p>
            <div
              className=" inline-block cursor-pointer"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {/* <span className="text-xl">Learn more</span> */}
              <div
                className={`h-0.5 bg-gray-400 transition-all duration-300 ${
                  isHovered ? "w-full" : "w-0"
                }`}
              ></div>
            </div>
          </div>

          {/* Right side buttons */}
          <div className="absolute  z-50 hidden  right-8 top-4 md:flex text-black space-x-4">

          <div className="relative" ref={profileMenuRef}>
              <button
                
                className="rounded-full border-gray-100/30 border-2 p-[1px] transition-all duration-300 hover:bg-white/20 hover:shadow-lg" onClick={() => setShowProfileMenu(!showProfileMenu)}
              >
                <img src={profilePic} alt="Profile" className="w-9 h-9  rounded-full object-cover" />
              </button>
              {/* {showProfileMenu && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg overflow-hidden z-20">
                  <div className="py-2">
                    <a href="#profile" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Profile</a>
                    <a href="#settings" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Settings</a>
                    <button onClick={onLogout} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Logout</button>
                  </div>
                </div>
              )} */}
              {showProfileMenu && (
                <div className="z-auto absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-xl">
                  <div className="p-4 border-b border-gray-200">
                    <img
                      src={profilePic}
                      alt="Profile"
                      className="w-16 h-16 rounded-full mx-auto mb-2"
                    />
                    <p className="font-bold text-center">{walletName}</p>
                  </div>
                  <ul>
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center" onClick={onLogout}>
                      <FiLogOut className="mr-2" /> Log out
                    </li>
                    <Link to="/setting"><li className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center" >
                      <FiSettings className="mr-2" /> Settings
                    </li></Link>
                  </ul>
                </div>
              )}
            </div>
            <div className="relative" ref={notificationRef}>
              <button
           
              className="rounded-full border-gray-100/30 border-2 p-3 transition-all duration-300 hover:bg-white/20 hover:shadow-lg" onClick={() => setShowNotifications(!showNotifications)}
            >
              <FaBell className="h-3 w-3 " />
            </button>
            {showNotifications && (
                <div className="absolute right-0 mt-2 w-80 rounded-md shadow-lg overflow-hidden z-50">
                  <div className="py-2">
                    <h3 className="text-sm font-semibold text-gray-800 px-4 py-2">Notifications</h3>
                    {notifications.map((notification) => (
                      <div key={notification.id} className="px-4 py-2 hover:bg-gray-100">
                        <p className="text-sm text-gray-700">{notification.message}</p>
                        <p className="text-xs text-gray-500">{notification.time}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>


              <div className="relative">
            <button
          
            className="rounded-full border-gray-100/30 border-2 p-3 transition-all duration-300 hover:bg-white/20 hover:shadow-lg"
          >
            <Search className="h-3 w-3 " />
          </button>
          </div>
         
          </div>

          {/* Bottom content */}
          <div className="flex flex-col md:flex-row ali items-center md:space-y-0 space-y-6 md:justify-between">
           <Link to="/marketplace" ><button className="flex items-center  space-x-2 rounded-2xl bg-gray-800 text-white px-6 py-3  transition-all duration-300 hover:bg-opacity-90 text-sm hover:shadow-lg">
              <FaRobot className="h-5 w-5 hidden md:block" />
              <span>Explore Agents</span>
            </button>
            </Link>
            <div className="relative flex w-96 items-center">
              <input
                type="text"
                placeholder="| Just Ask me anything"
                className="w-full rounded-2xl bg-white/10 px-6 py-3 pr-12 text-white placeholder-white/70 focus:outline-none focus:ring-2 focus:ring-white/50 transition-all duration-300"
              />
              <button className="absolute right-2 rounded-full bg-white/10 p-2 transition-all duration-300 hover:bg-white/20">
                <FaMicrophone className="h-4 w-4 text-white" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={`flex h-full flex-col md:flex-row text-primary ${darkMode ? 'dark' : ''}`}>
        <main className="w-full md:w-[100%] p-6 overflow-x-auto bg-primary ">
          <div className="mb-8">
            <h2 className="text-xl  font-semibold mb-4">Recent Agents</h2>
            <div className="grid grid-cols-1 overflow-x-auto sm:grid-cols-2 lg:grid-cols-3 gap-6 p-1">
              {AllConnectAgents.map((agent) => renderAgentCard(agent))}
            </div>
          </div>

          {/* Data Visualization */}
          <h2 className="text-xl font-semibold mb-4">Token Performance</h2>
          {/* <div className=" bg-white rounded-xl shadow-lg px-6 pt-6">
            
            <ResponsiveContainer width="100%" height={120}>
              <LineChart data={chartData} className="text-xs">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />

                <Legend />
                <Line type="monotone" dataKey="ATOM" stroke="#8884d8" />
                <Line type="monotone" dataKey="SOL" stroke="#82ca9d" />
                <Line type="monotone" dataKey="CMDX" stroke="#ffc658" />
              </LineChart>
            </ResponsiveContainer>
          </div> */}
        </main>

        {/* Right Sidebar */}
        {/* <aside className={`w-full md:w-1/4 bg-secondary p-6 overflow-y-auto border-l border-border-color ${darkMode ? 'dark' : ''}`}>
        <EventComponent 
            events={events} 
            setEvents={setEvents} 
            selectedAgents={AllConnectAgents}
          />

          

          <h2 className="text-xl font-medium mt-8 mb-4">Tokens</h2>
          <div className="space-y-4">
            {tokens.map((token, index) => (
              <div
                key={index}
                className=" relative bg-gradient-to-r from-purple-100 to-indigo-100 rounded-lg px-4 py-3 shadow-md overflow-hidden"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold">{token.name}</span>
                  <span className="text-gray-600">{token.symbol}</span>
                </div>
                <div className="text-lg font-bold mt-1">
                {token.symbol === "SOL" 
                    ? `${token.balance.toFixed(6)} SOL`
                    : `${token.price?.toLocaleString()} ${token.symbol}`
                  }
                </div>
                <img className="absolute -bottom-7 right-5 w-20 h-20 drop-shadow-md opacity-5 grayscale shadow-lg fill-orange-400" src={token.img} alt={token.name} />
              </div>
            ))}
          </div>
        </aside> */}

        {/* Floating Chat Button */}
        {/* <div className="fixed bottom-6 right-6">
          <button
            className="bg-gradient-to-r from-purple-500 to-indigo-600 text-white rounded-full p-4 shadow-lg hover:from-purple-600 hover:to-indigo-700 transition duration-300 transform hover:scale-105"
            onClick={() => setIsChatOpen(!isChatOpen)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
              />
            </svg>
          </button>
        </div> */}
      </div>

      {/* Notifications Popup */}
 
    </div>
  );
};

export default Dashboard;
