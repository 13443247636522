import React,{useState,useRef} from "react";
// import profile from "../../Assets/Image/Young_Person-removebg-preview.png";
import blue from "../../Assets/Image/blue-black-fabric_777078-95203.avif";
import { updateProfilePic } from '../../utils/profilePicManager';
// import { motion } from "framer-motion";
import { toast } from 'sonner'; // Import toast
import imageCompression from 'browser-image-compression';
interface ProfileNavProps {
  name: string;
  role: string;
  email: string;
  country: string;
  subscriptionDate: string;
  subscriptionPlan: string;
  profilePic: string;
}

const ProfileNav: React.FC<ProfileNavProps> = ({
  name,
  role,
  email,
  country,
  subscriptionDate,
  subscriptionPlan,
  profilePic,
}) => {



  const username = localStorage.getItem("walletname");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
      handleUpload(event.target.files[0]);
    }
  };

  const handleUpload = async(file: File) => {
    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };
      const compressedFile = await imageCompression(file, options);
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result as string;
      const success = updateProfilePic(base64String);
      if (success) {
        toast.success('Profile picture updated successfully');
        window.location.reload();
      // Update the profilePic state instead of reloading the page
      // This assumes you're using some state management (e.g., React Context or Redux)
      // updateProfilePicState(base64String);
    } else {
      toast.error('Failed to update profile picture. The image might be too large.');
      handleLargeImageError();
    }
      
    };
    reader.readAsDataURL(file);
  }catch (error) {
    console.error('Error compressing image:', error);
    toast.error('Error processing image. Please try again.');
  }
};
  const handleLargeImageError = () => {
    // Implement a solution for large images
    toast.info('Try compressing the image before uploading');
    // You could also provide a link to an image compression tool here
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };
  return (
    <div className="w-full relative  pb-4">
      <div className="h-32 relative z-0 ">
<img src={blue} className="w-full h-full object-cover" alt="blue" />
      </div>
      <div className="max-w-6xl mx-auto h-10  px-4">
        <div className="bg-white rounded-2xl shadow-lg  relative -top-14 z-10">
          <div className="pt-5 pb-4 px-4">
            <div className="flex flex-col md:flex-row justify-between items-start">
              <div className="flex  items-center space-x-4">
              <div className="">
                  
                    <img
                      src={profilePic}
                      alt="Current Profile"
                      className="w-28 h-28 object-cover bg-white absolute -top-12 z-20 border-4 border-blue-50 rounded-2xl transition-all duration-300 hover:shadow-lg"
                    />
                    <div className="absolute -top-12  w-28 h-28 bg-black bg-opacity-50 rounded-2xl flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300 z-30">
                      <button
                        onClick={triggerFileInput}
                        className="bg-white text-black px-2 py-1 rounded-md text-xs font-medium"
                      >
                        Update Avatar
                      </button>
                    </div>
                 
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    accept="image/*"
                    className="hidden"
                  />
                </div>
                <div className="  pl-36 md:pr-16">
                  <div className="flex items-center mb-1">
                    {/* <img src={``} alt={country} className="mr-2" /> */}
                    <span className="text-sm text-gray-600">{country}</span>
                  </div>
                  <h1 className="text-xl font-bold">{username}</h1>
                  <p className="text-sm text-gray-600">
                    {role} | {email}
                  </p>
                </div>
              </div>

              <div className="w-px h-20 bg-gray-300 mx-4 hidden md:block"></div>
              <div className="flex-1">
                <h2 className="font-bold mb-1">Subscription</h2>
                <p className="text-sm text-gray-600">
                  Your workspace was created on {subscriptionDate}.
                </p>
                <p className="text-sm text-gray-600">
                  You are currently on the {subscriptionPlan}.
                  <a href="#" className="text-blue-500 ml-2 hover:underline">
                    Edit Payment →
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileNav;
