import React, { useState, useEffect, ChangeEvent } from "react";
import { Switch, Tabs, Modal } from "./../../Components/ui/ui";
import {
  Sun,
  Moon,
  Bell,
  Lock,
  Cog,
  Settings,
} from "lucide-react";
import { Toaster, toast } from "sonner";
// Simple Input component
const Input: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = (
  props
) => <input {...props} className="w-full p-2 border rounded" />;

// Simple Select component
// const Select: React.FC<React.SelectHTMLAttributes<HTMLSelectElement>> = (
//   props
// ) => <select {...props} className="w-full p-2 border rounded" />;

// Simple Slider component
const Slider: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = (
  props
) => <input type="range" {...props} className="w-full" />;

// Simple Button component
const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = (
  props
) => (
  <button
    {...props}
    className="bg-blue-500 text-white px-4 py-2 rounded-3xl hover:bg-blue-600"
  />
);

interface NotificationSettings {
  email: boolean;
  push: boolean;
  sms: boolean;
}
// You'll need to import or create these components
// import { Input, Select, Slider, Button } from './path-to-your-ui-components';

const SettingsPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("general");
  const [theme, setTheme] = useState<"light" | "dark">("light");
  const [apiUrl, setApiUrl] = useState<string>("");
  const [advancedSettingsUnlocked, setAdvancedSettingsUnlocked] =
    useState(false);
  const [notificationSettings, setNotificationSettings] =
    useState<NotificationSettings>({
      email: true,
      push: false,
      sms: false,
    });
  const [maxNewTokens, setMaxNewTokens] = useState<number>(0);
  const [topP, setTopP] = useState<number>(0);
  const [temperature, setTemperature] = useState<number>(0);

  // const [walletNetwork, setWalletNetwork] = useState('cosmos');
  // const [gasPrice, setGasPrice] = useState(0.1);
  // const [autoLockTime, setAutoLockTime] = useState(15);
  // const [showPrivateKeys, setShowPrivateKeys] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [password, setPassword] = useState("");
  const handleTabChange = (value: string) => {
    if (value === "advanced" && !advancedSettingsUnlocked) {
      setShowAuthModal(true);
    } else {
      setActiveTab(value);
    }
  };
  const AuthModal = () => (
    <Modal isOpen={showAuthModal} onClose={() => setShowAuthModal(false)}>
      <h2 className="text-xl font-bold mb-4">Enter Password</h2>
      <Input
        type="password"
        value={password}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setPassword(e.target.value)
        }
        placeholder="Enter password"
      />
      <Button onClick={handleAuthentication} className="mt-4">
        Submit
      </Button>
    </Modal>
  );

  const handleAuthentication = () => {
    if (password === "secret123") {
      setAdvancedSettingsUnlocked(true);
      setShowAuthModal(false);
      setActiveTab("advanced");
      toast.success("Advanced settings unlocked");
    } else {
      toast.error("Incorrect password");
    }
    setPassword("");
  };
  useEffect(() => {
    // Load saved settings
    // This is just a placeholder, you'd typically load these from localStorage or an API
    setTheme((localStorage.getItem("theme") as "light" | "dark") || "light");
    setApiUrl(localStorage.getItem("apiUrl") || "");
    setNotificationSettings(
      JSON.parse(localStorage.getItem("notificationSettings") || "{}")
    );
    setMaxNewTokens(Number(localStorage.getItem("maxNewTokens")) || 0);
    setTopP(Number(localStorage.getItem("topP")) || 0);
    setTemperature(Number(localStorage.getItem("temperature")) || 0);
  }, []);

  const saveSettings = () => {
    // Save all settings
    localStorage.setItem("theme", theme);
    localStorage.setItem("apiUrl", apiUrl);
    localStorage.setItem(
      "notificationSettings",
      JSON.stringify(notificationSettings)
    );
    localStorage.setItem("maxNewTokens", maxNewTokens.toString());
    localStorage.setItem("topP", topP.toString());
    localStorage.setItem("temperature", temperature.toString());
    // You would typically also save the new blockchain wallet settings
    // alert('Settings saved successfully!');
    toast.success("Settings saved successfully!");
  };

  const handleNotificationChange = (type: keyof NotificationSettings) => {
    setNotificationSettings((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  return (
    <div
      className={` mx-auto h-full p-6 pt-4  `}
    >
      <Toaster richColors closeButton position="top-center"  />
      <h1 className="text-3xl font-bold ml-6 md:ml-4 mb-6">Settings</h1>

      <Tabs value={activeTab} onValueChange={handleTabChange}>
        <Tabs.List>
          <Tabs.Trigger value="general">
            <Cog className="inline-block mr-2" />
            General
          </Tabs.Trigger>
          <Tabs.Trigger value="advanced" >
            <Settings className="inline-block mr-2" />
            Advanced
          </Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value="general">
          <div className="space-y-6">
            <div>
              <label className="block mb-2">API URL</label>
              <Input
                type="text"
                value={apiUrl}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setApiUrl(e.target.value)
                }
                placeholder="Enter API URL"
              />
            </div>
            <div className="flex items-center justify-between">
              <span>Theme</span>
              <div className="flex items-center">
                <Switch
                  checked={theme === "dark"}
                  onCheckedChange={(checked: boolean) =>
                    setTheme(checked ? "dark" : "light")
                  }
                />
                {theme === "dark" ? <Moon /> : <Sun />}
              </div>
            </div>

            <div>
              <h3 className="font-semibold mb-3 mt-2">Notification Settings</h3>
              <div className="space-y-2">
                {Object.entries(notificationSettings).map(([key, value]) => (
                  <div key={key} className="flex items-center justify-between">
                    <span className="capitalize">{key} Notifications</span>
                    <div className="flex items-center">
                      <Switch
                        checked={value}
                        onCheckedChange={() =>
                          handleNotificationChange(
                            key as keyof NotificationSettings
                          )
                        }
                      />
                      <Bell className="ml-2" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-8">
            <Button className=" rounded-3xl" onClick={saveSettings}>
              Save Settings
            </Button>
          </div>
        </Tabs.Content>

        {/* <Tabs.Content value="advanced">
          <div className="space-y-6">
            <div>
              <label className="block mb-2">Max New Tokens</label>
              <Input
                type="number"
                value={maxNewTokens}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setMaxNewTokens(Number(e.target.value))}
              />
            </div>
            <div>
              <label className="block mb-2">Top P</label>
              <Slider
                min={0}
                max={100}
                step={0.01}
                value={topP}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setTopP(Number(e.target.value))}
              />
              <span className="text-sm">{topP}</span>
            </div>
            <div>
              <label className="block mb-2">Temperature</label>
              <Slider
                min={0}
                max={200}
                step={0.1}
                value={temperature}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setTemperature(Number(e.target.value))}
              />
              <span className="text-sm">{temperature}</span>
            </div>
          </div>

          <div className="mt-8">
        <Button  className=" rounded-3xl" onClick={saveSettings}>Save Settings</Button>
      </div>
        </Tabs.Content> */}
        <Tabs.Content value="advanced">
          {advancedSettingsUnlocked ? (
            <div className="space-y-6">
              <div>
                <label className="block mb-2">Max New Tokens</label>
                <Input
                  type="number"
                  value={maxNewTokens}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setMaxNewTokens(Number(e.target.value))
                  }
                />
              </div>
              <div>
                <label className="block mb-2">Top P</label>
                <Slider
                  min={0}
                  max={100}
                  step={0.01}
                  value={topP}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setTopP(Number(e.target.value))
                  }
                />
                <span className="text-sm">{topP}</span>
              </div>
              <div>
                <label className="block mb-2">Temperature</label>
                <Slider
                  min={0}
                  max={200}
                  step={0.1}
                  value={temperature}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setTemperature(Number(e.target.value))
                  }
                />
                <span className="text-sm">{temperature}</span>
              </div>
              <div className="mt-8">
                <Button className="rounded-3xl" onClick={saveSettings}>
                  Save Settings
                </Button>
              </div>
            </div>
          ) : (
            <div className="text-center py-8">
              <Lock className="mx-auto mb-4" size={48} />
              <p>
                Advanced settings are locked. Please authenticate to access.
              </p>
            </div>
          )}
        </Tabs.Content>
      </Tabs>
      <AuthModal />
    </div>
  );
};

export default SettingsPage;
