// File: src/utils/walletUtils.ts

import { ethers } from 'ethers';
// import { DirectSecp256k1HdWallet } from '@cosmjs/proto-signing';
import { StargateClient } from '@cosmjs/stargate';
import { Secp256k1HdWallet } from '@cosmjs/amino';
import {  Account } from './types';
import cosmos from "../../Assets//Image/download-removebg-preview.png"
import solanabg from "../../Assets//Image/solana.svg";
import { Connection, PublicKey, Keypair, LAMPORTS_PER_SOL } from '@solana/web3.js';
import * as bip39 from 'bip39';
import { derivePath } from 'ed25519-hd-key';

// export interface Wallet {
//   name: string;
//   mnemonic: string;
//   address: string;
//   balance: string;
//   network: string;
// }

// export async function getRecentTransactions(address: string, limit: number = 5): Promise<any[]> {
//   try {
//     const client = await StargateClient.connect('https://cosmos-rpc.comdex.one:443/cosmos');
    
//     const latestHeight = await client.getHeight();
//     const transactions: any[] = [];

//     for (let height = latestHeight; height > latestHeight - 100 && transactions.length < limit; height--) {
//       const block = await client.getBlock(height);
//       for (const txHash of block.txs) {
//         const tx = await client.getTx(Buffer.from(txHash).toString('hex'));
//         if (tx) {
//           const involvesSender = tx.events.some(event => 
//             event.attributes.some(attr => 
//               attr.key === 'sender' && attr.value === address
//             )
//           );
//           const involvesRecipient = tx.events.some(event => 
//             event.attributes.some(attr => 
//               attr.key === 'recipient' && attr.value === address
//             )
//           );

//           if (involvesSender || involvesRecipient) {
//             const amount = tx.events
//               .flatMap(event => event.attributes)
//               .find(attr => attr.key === 'amount')?.value || '0';

//             transactions.push({
//               hash: Buffer.from(txHash).toString('hex'),
//               height: height,
//               amount: parseFloat(amount) / 1000000, // Convert from uatom to ATOM
//               type: involvesSender ? 'send' : 'receive',
//               date: new Date().toISOString() // You might want to get the actual block time if available
//             });

//             if (transactions.length >= limit) {
//               break;
//             }
//           }
//         }
//       }
//     }

//     return transactions;
//   } catch (error) {
//     console.error('Error fetching recent transactions:', error);
//     throw new Error('Failed to fetch recent transactions');
//   }
// }


const URL ="https://solana-backend.zenscape.one/proxy/solana-rpc"
// const URL ="http://95.217.179.152:8086/solana-rpc"
export async function getWalletInfoFromMnemonic(mnemonic: string, name: string): Promise<Account[]> {
  try {
    // Validate mnemonic
    if (!ethers.Mnemonic.isValidMnemonic(mnemonic)) {
      throw new Error('Invalid mnemonic');
    }
    const wallets: Account[] = [];

    // Create Cosmos wallet
    const cosmosWallet = await Secp256k1HdWallet.fromMnemonic(mnemonic, { prefix: 'cosmos' });
    const [cosmosAccount] = await cosmosWallet.getAccounts();

    // Connect to Cosmos network and fetch balance
    const cosmosClient = await StargateClient.connect('https://cosmos-rpc.comdex.one:443/cosmos');
    const cosmosBalance = await cosmosClient.getBalance(cosmosAccount.address, 'uatom');

    // Format Cosmos balance
    const formattedCosmosBalance = ethers.formatUnits(cosmosBalance.amount, 6);

    wallets.push({
      name: `${name} - Cosmos`,
      mnemonic,
      address: cosmosAccount.address,
      balance: formattedCosmosBalance,
      network: 'cosmos',
      icon: cosmos,
    });

    // Create Solana wallet
    const seed = await bip39.mnemonicToSeed(mnemonic);
    const derivationPath = "m/44'/501'/0'/0'";
    const derivedSeed = derivePath(derivationPath, seed.toString('hex')).key;
    const solanaKeypair = Keypair.fromSeed(Uint8Array.from(derivedSeed));
    const solanaPublicKey = new PublicKey(solanaKeypair.publicKey);


    // Connect to Solana network and fetch balance
    // const solanaConnection = new Connection('https://rpc.ankr.com/solana',"confirmed");
    // const solanaBalance = await solanaConnection.getBalance(solanaPublicKey);

    // // Format Solana balance
    // const formattedSolanaBalance = (solanaBalance / LAMPORTS_PER_SOL).toFixed(9)
    let formattedSolanaBalance = '0';
    try {
      const solanaConnection = new Connection(URL);
      console.log('Solana Public Key:', solanaPublicKey.toBase58());
      const solanaBalance = await solanaConnection.getBalance(solanaPublicKey);
      console.log('Raw Solana Balance:', solanaBalance);
      formattedSolanaBalance = (solanaBalance / LAMPORTS_PER_SOL).toFixed(9);
    } catch (error) {
      console.error('Error fetching Solana balance:', error);
      if (error instanceof Error) {
        console.error('Error message:', error.message);
        console.error('Error stack:', error.stack);
      }
      // Set a default balance of 0 if there's an error
      // formattedSolanaBalance = '0';
    }

    wallets.push({
      name: `${name} - Solana`,
      mnemonic,
      address: solanaPublicKey.toBase58(),
      balance: formattedSolanaBalance,
      network: 'solana',
      icon: solanabg,
      // Add Solana icon here if available
    });

    return wallets;
  } catch (error) {
    console.error('Error fetching wallet info:', error);
    if (error instanceof Error) {
      throw new Error(`Failed to get wallet info: ${error.message}`);
    } else {
      throw new Error('An unknown error occurred while fetching wallet info');
    }
  }
}

export function validateWalletName(name: string): boolean {
  return name.length > 0 && name.length <= 50;
}

export function shortenAddress(address: string | undefined | null, chars = 4): string {
  if (!address) return 'N/A';
  return `${address.slice(0, chars)}...${address.slice(-chars)}`;
}

export async function getWalletBalance(address: string, network: string): Promise<string> {
  try {
    if (network === 'cosmos') {
      const client = await StargateClient.connect('https://cosmos-rpc.comdex.one:443/cosmos');
      const balance = await client.getBalance(address, 'uatom');
      return ethers.formatUnits(balance.amount, 6);
    } else if (network === 'solana') {
      try {
        const connection = new Connection(URL);
        const publicKey = new PublicKey(address);
        console.log('Fetching balance for Solana address:', address);
        const balance = await connection.getBalance(publicKey);
        console.log('Raw Solana Balance:', balance);
        return (balance / LAMPORTS_PER_SOL).toFixed(9);
      } catch (error) {
        console.error('Error fetching Solana balance:', error);
        if (error instanceof Error) {
          console.error('Error message:', error.message);
          console.error('Error stack:', error.stack);
        }
        return '0';
      }
    } else {
      throw new Error('Unsupported network');
    }
  } catch (error) {
    console.error('Error fetching wallet balance:', error);
    if (error instanceof Error) {
      console.error('Error message:', error.message);
      console.error('Error stack:', error.stack);
    }
    return '0';
  }
}

export async function getSolanaWalletBalance(address: string): Promise<string> {
  try {
    const connection = new Connection(URL);
    const publicKey = new PublicKey(address);
    const balance = await connection.getBalance(publicKey);
    return (balance / LAMPORTS_PER_SOL).toFixed(9); // Convert lamports to SOL
  } catch (error) {
    console.error('Error fetching Solana wallet balance:', error);
    throw new Error('Failed to fetch Solana wallet balance');
  }
}

// // Add this function to getWalletInfoFromMnemonic
// if (name.toLowerCase().includes('solana')) {
//   // Implement Solana-specific wallet creation logic here
//   // This is a placeholder and needs to be implemented properly
//   const solanaAddress = 'SOLANA_ADDRESS_PLACEHOLDER';
//   const solanaBalance = await getSolanaWalletBalance(solanaAddress);
  
//   return {
//     name,
//     mnemonic,
//     address: solanaAddress,
//     balance: solanaBalance,
//   };
// }