import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  FiSend,
  FiFile,
  FiX,
  FiMic,
  FiStopCircle,
  FiDollarSign,
  FiRefreshCw,
  // FiLink,
  // FiUnlock,
  FiInfo,
  FiTrendingUp,
  FiList,
  FiClock,
} from "react-icons/fi";
import { PiWaveform } from "react-icons/pi";
import { IoMdAttach } from "react-icons/io";
import { Toaster, toast } from "sonner";
import { useTheme } from '../../contexts/ThemeContext';
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
// import { debounce } from "lodash";
// import { Tooltip } from 'react-tooltip';
// import 'react-tooltip/dist/react-tooltip.css';
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

interface InputAreaProps {
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  handleSend: () => void;
  isGeneratingText: boolean;
  handlePaste: (e: React.ClipboardEvent) => void;
  attachments: File[];
  setAttachments: React.Dispatch<React.SetStateAction<File[]>>;
}

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes
const SILENCE_TIMEOUT = 4000; // 4 seconds

// Define quick action types
type QuickAction = {
  label: string;
  template: string;
  tooltip: string;
  shortcut: string;
  icon: React.ReactElement;
  color: string;
};

const QUICK_ACTIONS: QuickAction[] = [
  // {
  // label: "Check Balance",
  // template: "check balance",
  // tooltip: "Check your current SOL balance",
  // shortcut: "Alt+B"
  // },
  {
    label: "Send SOL",
    template: "I want to send some Solana",
    tooltip: "Send SOL tokens to a specified address",
    shortcut: "Alt+S",
    icon: <FiSend />,
    color: "text-blue-500",
  },
  {
    label: "Stake SOL",
    template: "I want to stake some Solana",
    tooltip: "Delegate SOL tokens to a validator",
    shortcut: "Alt+D",
    icon: <FiRefreshCw />,
    color: "text-green-500",
  },
  {
    label: "Swap",
    template: "I want to do a Swap ",
    tooltip: "I want to do a Swap ",
    shortcut: "Alt+P",
    icon: <FiDollarSign />,
    color: "text-purple-500",
  },
  // {
  //   label: "SOL Price",
  //   template: "What is the current Solana Token price?",
  //   tooltip: "Get current SOL price",
  //   shortcut: "Alt+P",
  //   icon: <FiTrendingUp />,
  //   color: "text-yellow-500",
  // },
  // {
  //   label: "Transaction History",
  //   template: "Show my recent transactions",
  //   tooltip: "View recent transaction history",
  //   shortcut: "Alt+H",
  //   icon: <FiList />,
  //   color: "text-indigo-500",
  // },
  // {
  //   label: "Network Status",
  //   template: "What is the current Solana network status?",
  //   tooltip: "Check Solana network status",
  //   shortcut: "Alt+N",
  //   icon: <FiInfo />,
  //   color: "text-red-500",
  // },
  // {
  //   label: "Block Time",
  //   template: "What is the current Solana block time?",
  //   tooltip: "Get current Solana block time",
  //   shortcut: "Alt+T",
  //   icon: <FiClock />,
  //   color: "text-orange-500",
  // },
  // {
  //   label: "Redelegate SOL",
  //   template: "redelegate <amount> SOL from <old validator address> to <new validator address>",
  //   tooltip: "Redelegate SOL tokens from one validator to another",
  //   shortcut: "Alt+R"
  // },
];
// Quick Actions section

const InputArea: React.FC<InputAreaProps> = ({
  inputValue,
  setInputValue,
  handleSend,
  isGeneratingText,
  attachments,
  setAttachments,
}) => {
  // const [attachments, setAttachments] = useState<File[]>([]);
  const [dragActive, setDragActive] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en-IN");
  const [lastTranscript, setLastTranscript] = useState("");
  const [silenceTimer, setSilenceTimer] = useState<NodeJS.Timeout | null>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedAction, setSelectedAction] = useState<QuickAction | null>(
    null
  );
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [showQuickActions, setShowQuickActions] = useState(false);
  // Function to handle quick action button click
  const handleQuickAction = (action: QuickAction) => {
    setInputValue(action.template);
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setShowQuickActions(false);
  };
  // Inside the component, add this line near the top
const { darkMode } = useTheme();

  // Function to confirm quick action
  const confirmQuickAction = () => {
    if (selectedAction) {
      setInputValue(selectedAction.template);
      if (inputRef.current) {
        inputRef.current.focus();
      }
      setShowConfirmation(false);
    }
  };

  const renderQuickActions = () => (
    <div className="quick-actions-section  rounded-lg mb-1 pl-2 w-[80%] ">
      <h3 className={`text-xs font-medium my-1 ${darkMode ?'text-gray-400' :' text-gray-500'} `}>Quick Actions</h3>
      <div className="gap-2 quick-actions-section  flex flex-nowrap items-center space-x-2 overflow-x-auto pb-2 w-full ">
        {QUICK_ACTIONS.map((action, index) => (
          <Tippy
            key={index}
            content={`${action.tooltip} (${action.shortcut})`}
            placement="top"
          >
            <button
              onClick={() => handleQuickAction(action)}
              className={` hover:bg-green-200 border text-white text-xs font-medium py-1 px-2 rounded transition-colors flex shadow-inner flex-shrink-0 ${darkMode ? 'border-teal-700 ' : 'border-gray-300 '} `}
            >
              <div className="flex align-middle items-center">
                <span
                  className={`mr-2 p-[2px] rounded-sm  ${darkMode ?'bg-slate-600 ' : 'bg-slate-200/60 '} ${action.color}`}
                >
                  {action.icon}
                </span>

                <span className={`text-clip  ${darkMode ? 'text-slate-300 ' : 'text-gray-500 '}break-normal flex flex-shrink-0 `}>
                  {action.label}
                </span>
              </div>
            </button>
          </Tippy>
        ))}
      </div>
    </div>
  );

  // Function to handle keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.altKey) {
        const action = QUICK_ACTIONS.find(
          (a) => a.shortcut === `Alt+${e.key.toUpperCase()}`
        );
        if (action) {
          e.preventDefault();
          handleQuickAction(action);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  // Function to provide suggestions for placeholder values
  const provideSuggestions = (value: string) => {
    const placeholders = [
      "<amount>",
      "<recipient address>",
      "<validator address>",
      "<old validator address>",
      "<new validator address>",
    ];
    const currentPlaceholder = placeholders.find((p) => value.includes(p));

    if (currentPlaceholder) {
      switch (currentPlaceholder) {
        case "<amount>":
          setSuggestions(["0.1", "1", "5", "10"]);
          break;
        case "<recipient address>":
        case "<validator address>":
        case "<old validator address>":
        case "<new validator address>":
          setSuggestions(["Bxxx...", "Cxxx...", "Dxxx..."]);
          break;
        default:
          setSuggestions([]);
      }
    } else {
      setSuggestions([]);
    }
  };

  // Update suggestions when input value changes
  useEffect(() => {
    provideSuggestions(inputValue);
  }, [inputValue]);

  // Function to apply suggestion
  const applySuggestion = (suggestion: string) => {
    const placeholders = [
      "<amount>",
      "<recipient address>",
      "<validator address>",
      "<old validator address>",
      "<new validator address>",
    ];
    let newValue = inputValue;

    for (const placeholder of placeholders) {
      if (newValue.includes(placeholder)) {
        newValue = newValue.replace(placeholder, suggestion);
        break;
      }
    }

    setInputValue(newValue);
  };

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  // const debouncedSetInput = useRef(
  //   debounce((value: string) => {
  //     setInputValue(value.trim());
  //   }, 300)
  // ).current;

  const stopListening = useCallback(() => {
    if (listening) {
      SpeechRecognition.stopListening();
      if (silenceTimer) {
        clearTimeout(silenceTimer);
        setSilenceTimer(null);
      }
    }
  }, [listening, silenceTimer]);

  useEffect(() => {
    if (transcript && transcript !== lastTranscript) {
      setInputValue((prevValue) => {
        // Remove the last transcript from the previous value
        const valueWithoutLastTranscript = prevValue
          .replace(lastTranscript, "")
          .trim();
        // Add the new transcript
        return `${valueWithoutLastTranscript} ${transcript}`.trim();
      });
      setLastTranscript(transcript);

      // // Delay resetting the transcript
      // const timer = setTimeout(() => {
      //   resetTranscript();
      // }, 1000); // 1 second delay

      // return () => clearTimeout(timer);

      // Reset silence timer
      if (silenceTimer) clearTimeout(silenceTimer);
      const timer = setTimeout(() => {
        stopListening();
        toast.info("Stopped listening due to silence");
      }, SILENCE_TIMEOUT);
      setSilenceTimer(timer);

      return () => {
        if (timer) clearTimeout(timer);
      };
    }
  }, [
    transcript,
    lastTranscript,
    setInputValue,
    resetTranscript,
    silenceTimer,
    stopListening,
  ]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  const handleAttachmentClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      const validFiles = newFiles.filter((file) => file.size <= MAX_FILE_SIZE);
      const invalidFiles = newFiles.filter((file) => file.size > MAX_FILE_SIZE);

      if (invalidFiles.length > 0) {
        // alert(`The following files exceed the 2MB limit and were not added: ${invalidFiles.map(f => f.name).join(', ')}`);
        toast.error(
          `File exceed the 2MB limit and were not added: ${invalidFiles
            .map((f) => f.name)
            .join(", ")}`
        );
      }

      setAttachments((prev) => [...prev, ...validFiles]);
    }
  };

  const removeAttachment = (index: number) => {
    setAttachments(attachments.filter((_, i) => i !== index));
  };

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  // const handleClickOutside = (e: MouseEvent) => {
  //   if (
  //     inputRef.current &&
  //     !inputRef.current.contains(e.target as HTMLElement)
  //   ) {
  //     setInputValue("");
  //   }
  // };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const newFiles = Array.from(e.dataTransfer.files);
      const validFiles = newFiles.filter((file) => file.size <= MAX_FILE_SIZE);
      const invalidFiles = newFiles.filter((file) => file.size > MAX_FILE_SIZE);

      if (invalidFiles.length > 0) {
        toast.error(
          `File exceed the 2MB limit and were not added: ${invalidFiles
            .map((f) => f.name)
            .join(", ")}`
        );
      }

      setAttachments((prev) => [...prev, ...validFiles]);
    }
  };

  // const handlePaste = (e: React.ClipboardEvent) => {
  //   const pastedText = e.clipboardData.getData('text');
  //   if (pastedText.length > 500) { // Adjust threshold as needed
  //     e.preventDefault();
  //     const fileName = `Pasted_Text_${new Date().getTime()}.txt`;
  //     const file = new File([pastedText], fileName, {type: 'text/plain'});
  //     setAttachments(prev => [...prev, file]);
  //   }
  // };
  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const pastedText = e.clipboardData.getData("text");
    if (pastedText.length > 500) {
      // Adjust threshold as needed
      e.preventDefault();
      const fileName = `Pasted_Text_${new Date().getTime()}.txt`;
      const file = new File([pastedText], fileName, { type: "text/plain" });
      setAttachments((prev) => [...prev, file]);
    }
    //  else {
    //   // Original paste behavior for shorter text
    //   const newValue = inputValue + pastedText;
    //   setInputValue(newValue);
    // }
  };

  const truncateFileName = (fileName: string, maxLength: number = 12) => {
    if (fileName.length <= maxLength) return fileName;
    const extension = fileName.split(".").pop();
    const nameWithoutExtension = fileName.slice(0, fileName.lastIndexOf("."));
    return `${nameWithoutExtension.slice(
      0,
      maxLength - 3 - (extension?.length || 0)
    )}...${extension}`;
  };

  const renderFilePreview = (file: File, index: number) => {
    const isImage = file.type.startsWith("image/");
    return (
      <div key={index} className="relative inline-block mr-2 mb-2">
        {isImage ? (
          <img
            src={URL.createObjectURL(file)}
            alt={file.name}
            className="w-16 h-16 object-cover rounded-xl"
          />
        ) : (
          <div className="w-16 h-16 bg-gray-200 rounded-xl flex items-center justify-center">
            <FiFile size={24} />
          </div>
        )}
        <button
          onClick={() => removeAttachment(index)}
          className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full p-[2px]"
        >
          <FiX size={12} />
        </button>
        <p className="text-xs mt-1 text-center">
          {truncateFileName(file.name)}
        </p>
      </div>
    );
  };
  const toggleListening = () => {
    if (listening) {
      stopListening();
    } else {
      setLastTranscript("");
      resetTranscript();
      SpeechRecognition.startListening({
        continuous: true,
        language: selectedLanguage,
      });
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputValue]);

  const adjustTextareaHeight = () => {
    const textarea = inputRef.current;
    if (textarea) {
      textarea.style.height = "55px";
      if (textarea.value) {
        const newHeight = Math.min(textarea.scrollHeight, 4 * 24);
        textarea.style.height = `${newHeight}px`;
      }
    }
  };

  const handleSendWithVoice = () => {
    stopListening();
    handleSend();
  };

  return (
    <div className="relative">
      <div
        className={`relative z-2 border border-n-3 ${darkMode ? 'border-teal-400' : 'border-gray-200'} rounded-[20px] overflow-hidden dark:border-n-5 ${
          dragActive ? "bg-green-100" : ""
        }`}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        // onPaste={handlePaste}
      >
        {/* <Toaster richColors closeButton position="top-center"  /> */}
        {/* File Attachments Preview */}
        {attachments.length > 0 && (
          <div className="flex flex-wrap gap-2 p-2 mb-2 md:px-6 bg-gray-50 rounded-3xl ">
            {attachments.map((file, index) => renderFilePreview(file, index))}
          </div>
        )}

        {/* Main Input Area */}
        <div className=" relative overflow-hidden flex items-center min-h-[3.5rem] space-x-2 text-pretty px-2">
          {/* attachments  */}
          <button
            className="group outline-none rounded-2xl p-2 hover:bg-gray-300 transition-colors"
            onClick={handleAttachmentClick}
          >
            <IoMdAttach
              className="font-medium transition-colors cursor-pointer text-gray-400 group-hover:fill-white"
              size={22}
            />
          </button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            multiple
          />

          <textarea
            ref={inputRef}
            value={inputValue}
            // onChange={(e) => setInputValue(e.target.value)}
            onChange={handleInputChange}
            onPaste={handlePaste}
            onKeyPress={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSendWithVoice();
              }
            }}
            className="flex-grow w-full py-3 bg-transparent ${darkMode ? 'text-white' : 'text-gray-900'} ml-2 outline-none resize-none min-h-[40px] max-h-[120px]"
            placeholder="Ask me anything"
          />

          {browserSupportsSpeechRecognition && (
            <button
              onClick={toggleListening}
              className={`group outline-none rounded-2xl p-[9px]  ${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-300/90'} transition-colors ${
                listening
                  ? "text-blue-500 animate-pulse"
                  : "text-gray-400 hover:text-gray-600"
              }`}
            >
              {listening ? (
                <PiWaveform size={20} />
              ) : (
                <FiMic
                  size={20}
                  className="text-gray-400 group-hover:text-white"
                />
              )}
            </button>
          )}

          <button
            disabled={isGeneratingText && !inputValue.trim()}
            onClick={() => {
              if (isGeneratingText) {
                // Function to stop generating text
                // handleStopGeneration();
              } else {
                handleSendWithVoice();
              }
            }}
            className={`group outline-none rounded-2xl cursor-pointer hover:bg-gray-300 transition-colors ${
              isGeneratingText
                ? "text-rose-500 p-[9px] outline-none"
                : "text-gray-400 group-hover:fill-primary-1 outline-none "
            } ${
              !inputValue.trim() && !isGeneratingText
                ? "opacity-0 translate-x-full scale-50"
                : "opacity-100 p-[9px] translate-x-0 scale-100"
            }`}
          >
            {isGeneratingText ? (
              <FiStopCircle size={21} />
            ) : inputValue.trim() ? (
              <FiSend className="group-hover:text-white font-thin " size={21} />
            ) : null}
          </button>
        </div>

        {/* bottom view  */}
        <div
          className={`
            shadow-inner px-3 flex flex-row flex-grow text-left items-center transition-colors justify-between
            ${
              listening
                ? darkMode
                  ? "bg-blue-900 border-t-2 border-blue-700"
                  : "bg-blue-100 border-t-2 border-blue-300"
                : attachments.length > 0
                ? darkMode
                  ? "bg-gray-800 border-t-2 border-gray-700"
                  : "bg-gray-200 border-t-2 border-gray-300"
                : darkMode
                ? "bg-gradient-to-br from-teal-200/20 via-teal-300/40 to-teal-500 border-t-2 border-teal-700"
                : "bg-gradient-to-br from-green-50/50 to-green-100/70 border-t-2 border-gray-50"
            }
          `}
        >

          {/* quick action buttons */}
          {renderQuickActions()}
          <div className="flex items-center space-x-2">
            {/* File Attachment Count */}
            {attachments.length > 0 && (
              <div className="bg-transparent text-xs font-light  text-gray-600  md:px-6 px-3 ">
                {attachments.length} File{attachments.length !== 1 ? "s" : ""}{" "}
                attached
              </div>
            )}

            {/* Listening Indicator */}
            {listening && (
              <div className=" text-blue-800 animate-pulse px-3 text-xs rounded-t-lg">
                Listening...
              </div>
            )}
          </div>

          {/* Quick Action Buttons */}
          {/* <div className="flex flex-wrap justify-center md:justify-start gap-2 pl-3">
        {QUICK_ACTIONS.map((action, index) => (
          <Tippy
            key={index}
            content={`${action.tooltip} (${action.shortcut})`}
            placement="top"
          >
            <button
              onClick={() => handleQuickAction(action)}
              className="bg-blue-200 hover:bg-blue-400 text-black hover:text-white text-xs font-normal  px-2 rounded transition-colors"
            >
              {action.label}
            </button>
          </Tippy>
        ))}
      </div> */}
          {/* <div
            className={`shadow-inner px-3 flex flex-row flex-grow text-left items-center transition-colors justify-between ${
              listening
                ? "bg-blue-100 border-t-2 border-blue-300 "
                : attachments.length > 0
                ? "bg-gray-200 border-t-2 border-gray-300"
                : "bg-gray-50 border-t-2 border-gray-50"
            }`}
          ></div> */}

          {/* Language Selector */}
          <select
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e.target.value)}
            className="bg-transparent text-xs font-light cursor-pointer ml-auto"
          >
            <option value="en-IN">English IN</option>
            <option value="hi">Hindi</option>
            <option value="en-US">English US</option>
            <option value="es-ES">Español</option>
            <option value="fr-FR">Français</option>
          </select>
        </div>
        {suggestions.length > 0 && (
          <div className="absolute bottom-full left-0 w-full bg-white border border-gray-300 rounded-t-lg shadow-lg">
            {suggestions.map((suggestion, index) => (
              <button
                key={index}
                onClick={() => applySuggestion(suggestion)}
                className="block w-full text-left px-4 py-2 hover:bg-gray-100"
              >
                {suggestion}
              </button>
            ))}
          </div>
        )}

        {/* Confirmation Dialog
      {showConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <h3 className="text-lg font-semibold mb-4">Confirm Action</h3>
            <p>Are you sure you want to use the "{selectedAction?.label}" template?</p>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={() => setShowConfirmation(false)}
                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={confirmQuickAction}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )} */}
      </div>
    </div>
  );
};

export default InputArea;
