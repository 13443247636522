import React from "react";
import { Link } from "react-router-dom";
// import { cn } from "../../lib/utils";
// import ShimmerButton from "../../magicui/shimmer-button";
import Logo from "../../Assets/ZOOLY LOGO 2/JUSTX LOGO/JUSTX LOGO.png";
// import AnimatedGridPattern from "../../magicui/animated-grid-pattern";
import { motion } from "framer-motion";
import head from "../../Assets/Image/Group 1321314365.svg";
import pill from "../../Assets/Image/Group 1321314367.svg";

interface Onboarding1Props {
  onNext: () => void
};

const Onboarding1: React.FC<Onboarding1Props> = ({ onNext }) => {
  const pageVariants = {
    initial: { opacity: 0, x: 0 },
    in: { opacity: 1, x: 0 },
    out: { opacity: 0, x: "-100%" },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5,
  };

  return (
    <motion.div
      className="min-h-screen overflow-hidden relative bg-gradient-to-br from-slate-950/20 via-gray-950 to-black"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
       {/* JustX Logo */}
       <motion.img
        src={Logo}
        alt="JusEx Logo"
        className="absolute top-8 left-10 w-32 z-20"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
      />
      <div className="flex flex-col lg:flex-row min-h-screen text-white">
        {/* Left side (content) */}
        <motion.div
          className="relative z-10 w-full lg:w-1/2 flex flex-col justify-center py-10 px-6 lg:px-16"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
        >
          

          <motion.h1
            className="text-4xl md:text-6xl font-bold mb-6 leading-tight"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
          >
            Revolutionize your Operations
          </motion.h1>

          <motion.p
            className="text-lg mb-10 text-gray-300 leading-relaxed max-w-2xl"
            animate={{ opacity: 1, y: 0 }}
          >
            Enhance your blockchain transactions with our AI agent, automating
            routine tasks and providing insightful data analysis for optimal
            efficiency. Focus on what matters while our AI streamlines your
            workflow.
          </motion.p>

          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <Link to="/signup" onClick={onNext}>
 <button className="bg-teal-500 text-gray-900 font-semibold text-lg px-7 py-2 rounded-md hover:bg-teal-300 transition-colors duration-300">
                  Get Started
                </button>
            </Link>
          </motion.div>
        </motion.div>

        {/* Right side (3D head projection) */}
        <motion.div
          className="relative w-full lg:w-1/2 flex items-center justify-center"
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.3 }}
        >
          {/* Base */}
          
          
          <motion.div 
            className="relative w-400 h-96"
            animate={{ 
              y: [0, -10, 0],
              rotateY: [0, 5, 0]
            }}
            transition={{ 
              duration: 5, 
              repeat: Infinity, 
              ease: "easeInOut" 
            }}
          >
            <img src={head} alt="3D Head Projection" className="w-full h-full mt-20"/>
          </motion.div>

          {/* Floating Elements */}
          <motion.div
            className="absolute top-1/4 left-8 bg-gray-800 gap-3 text-gray-300 px-4 py-2 rounded-md border-teal-500 border flex items-center"
            whileHover={{ scale: 1.1 }}
            animate={{ 
              x: [0, 10, 0],
              y: [0, -5, 0]
            }}
            transition={{ duration: 4, repeat: Infinity, ease: "easeInOut" }}
          >
            <img src={pill} alt="Floating Elements"/> 
            AI Insights
          </motion.div>
          <motion.div
            className="absolute top-16 right-1/3 bg-gray-800 gap-3 text-gray-300 px-4 py-2 rounded-md border-teal-500 border flex items-center"
            whileHover={{ scale: 1.1 }}
            animate={{ 
              x: [0, -10, 0],
              y: [0, 5, 0]
            }}
            transition={{ duration: 4.5, repeat: Infinity, ease: "easeInOut" }}
          >
           <img src={pill} alt="Floating Elements"/> 
            Secure Transactions
          </motion.div>
          <motion.div
            className="absolute top-1/3 right-12 bg-gray-800 gap-3 text-gray-300 px-4 py-2 rounded-md border-teal-500 border flex items-center"
            whileHover={{ scale: 1.1 }}
            animate={{ 
              x: [0, 5, 0],
              y: [0, -10, 0]
            }}
            transition={{ duration: 5, repeat: Infinity, ease: "easeInOut" }}
          >
            <img src={pill} alt="Floating Elements"/> 
            Smart Contracts
          </motion.div>
        </motion.div>
      </div>

      
    </motion.div>
  );
};

export default Onboarding1;