import {
  Connection,
  Keypair,
  SystemProgram,
  Transaction,
  sendAndConfirmTransaction,
  LAMPORTS_PER_SOL,
  PublicKey,
  // ConnectionConfig,
  StakeProgram,
  VersionedTransaction
} from "@solana/web3.js";
import { Subject } from 'rxjs';
import * as bip39 from "bip39";
import { derivePath } from "ed25519-hd-key";
// import { config } from "process";
import { toast } from "sonner";
// import
interface ChainConfig {
  rpcEndpoint: string;
  prefix: string;
  denom: string;
  feeAmount: string;
  gas: string;
}

type DetailedStatus = {
  stage: 'initializing' | 'processing' | 'confirming' | 'completed' | 'error';
  message: string;
  progress: number;
};

// async function getKeypairFromMnemonic(mnemonic: string): Promise<Keypair> {
//   const seed = await bip39.mnemonicToSeed(mnemonic);
//   const derivedSeed: any = derivePath(
//     "m/44'/501'/0'/0'",
//     seed.toString("hex")
//   ).key;
//   return Keypair.fromSeed(derivedSeed);
// }

export const transactionStatusSubject = new Subject<DetailedStatus>()

export const handleSolanaTransaction = async (
  input: any,
  mnemonic: string,
  chainConfig: ChainConfig
) => {
  // transactionStatusSubject.next({
  //   stage: 'processing',
  //   message: "Preparing transaction details",
  //   progress: 0
  // });
  // async function getKeypairFromMnemonic(mnemonic: any) {
  //   const seed = await bip39.mnemonicToSeed(mnemonic);
  //   const derivedSeed: any = derivePath(
  //     "m/44'/501'/0'/0'",
  //     seed.toString("hex")
  //   ).key;
  //   return Keypair.fromSeed(derivedSeed);
  // }

  // async function getStakeAccount(connection: any, publicKey: any) {
  //   const STAKE_PROGRAM_ID = new PublicKey(
  //     "Stake11111111111111111111111111111111111111"
  //   );
  //   const accounts = await connection.getProgramAccounts(STAKE_PROGRAM_ID, {
  //     filters: [
  //       {
  //         memcmp: {
  //           offset: 12,
  //           bytes: publicKey.toBase58(),
  //         },
  //       },
  //     ],
  //   });
  //   return accounts.length > 0 ? accounts[0].pubkey : null;
  // }

  // async function retryWithExponentialBackoff(
  //   fn: any,
  //   retries = 2,
  //   delay = 4000
  // ) {
  //   for (let i = 0; i < retries; i++) {
  //     try {
  //       return await fn();
  //     } catch (error: any) {
  //       if (error.code === 429 && i < retries - 1) {
  //         console.warn(`Rate limited. Retrying in ${delay} ms...`);
  //         await new Promise((resolve) => setTimeout(resolve, delay));
  //         delay *= 2;
  //       } else {
  //         throw error;
  //       }
  //     }
  //   }
  //   throw new Error("Max retries reached");
  // }


      // Extract transaction details from input
      // const regex = /(?:send|delegate|swap)\s+(\d+(?:\.\d+)?)\s+(\w+)(?:\s+to\s+(\S+))?/i;
      // const match = input.match(regex);
      
      // if (!match) {
      //   transactionStatusSubject.next({
      //     stage: 'error',
      //     message: "Invalid transaction format",
      //     progress: 0
      //   });
      //   throw new Error("Invalid transaction format. Please use: 'send/delegate/swap [amount] [token] (to [recipient])'");
      // }
  
      // const [, amountStr, token, recipient] = match;
      // const amount = parseFloat(amountStr);
  
      // if (isNaN(amount) || amount <= 0) {
      //   transactionStatusSubject.next({
      //     stage: 'error',
      //     message: "Invalid amount. Please provide a positive number.",
      //     progress: 0
      //   });
      //   throw new Error("Invalid amount. Please provide a positive number.");
      //   toast.error("Invalid amount. Please provide a positive number.")
      // }
  
      // if (token.toLowerCase() !== 'sol') {
      //   transactionStatusSubject.next({
      //     stage: 'error',
      //     message: "Invalid amount. Please provide a positive number.",
      //     progress: 0
      //   });
      //   throw new Error("Only SOL token is supported for now.");
      //   toast.error("Only SOL token is supported for now.")
      // }

      
  

  // transactionStatusSubject.next({
  //   stage: 'processing',
  //   message: "Preparing transaction details",
  //   progress: 10
  // });

  const url = "https://solana-backend.zenscape.one/proxy/query";
    // const url = "http://95.217.179.152:8086/query";
  console.log("input ", input);
// Fetch the public key from local storage
const publicKey = localStorage.getItem("account");
console.log("publicKey", publicKey);
// Prepare the payload with the public key
const payload = {
  messages: input,
  userAddress: publicKey, // Include the public key in the payload
};



  const headers = {
    "Content-Type": "application/json",
  };
  ;

  try {
    // transactionStatusSubject.next({
    //   stage: 'processing',
    //   message: "Sending request to AI model",
    //   progress: 20
    // });
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    

    console.log("This is the output in json format", data);
    // transactionStatusSubject.next({
    //   stage: 'processing',
    //   message: "Extracting transaction function",
    //   progress: 30
    // });
    const extractedFunction = await extractFunctionFromResponse(data);
    console.log("extract function", extractedFunction);
    return extractedFunction;
  } catch (error) {
    console.error("Solana Response Error:", error);
    transactionStatusSubject.next({
      stage: 'error',
      message: `Solana Response Error: ${error instanceof Error ? error.message : 'Unknown error'}`,
      progress: 0
    });
    console.error("Solana Response Error:", error);
    throw error;
  }


async function extractFunctionFromResponse(response: any) {
   
    console.log("generatedText response", response);
    const generatedText = response.response;

    console.log("generatedText", generatedText);
    const asyncKeywordIndex = generatedText.indexOf("async");

    console.log("asyncKeywordIndex", asyncKeywordIndex);

    // if (asyncKeywordIndex !== -1) {
    const functionStart = generatedText.indexOf("async (");
    // const functionEnd =
    //   generatedText.indexOf("return result.transactionHash;") +
    //   "return result.transactionHash;".length;

    const functionEnd = generatedText.lastIndexOf("}");

    if (functionStart !== -1 && functionEnd !== -1) {
      const functionCode =
        generatedText.substring(functionStart, functionEnd) + "\n}";
      console.log("Extracted function code:", functionCode);


      transactionStatusSubject.next({
        stage: 'processing',
        message: "Preparing transaction execution",
        progress: 40
      });
      const dynamicFunction = eval(`(${functionCode})`);
      // const dynamicFunction = new Function('Connection',
      //   'connection',
      //   'SystemProgram',
      //   'Transaction',
      //   'sendAndConfirmTransaction',
      //   'LAMPORTS_PER_SOL',
      //   'PublicKey',
      //   'fromKeypair',
      //   'chainConfig',
      //   `return ${functionCode}`)();

      // const dynamicFunction = new Function(`
      //   return async function(connection, SystemProgram, Transaction, sendAndConfirmTransaction, lamportsToSend, PublicKey, fromKeypair, chainConfig) {
      //     const transferInstruction = SystemProgram.transfer({
      //       fromPubkey: fromKeypair.publicKey,
      //       toPubkey: new PublicKey("5bVBSdYzsoETaVUJLmmds9SD63saPpE8v7mThywzzcmS"),
      //       lamports: lamportsToSend,
      //     });
      //     const transaction = new Transaction().add(transferInstruction);
      //     const transactionSignature = await sendAndConfirmTransaction(connection, transaction, [fromKeypair], {commitment:"confirmed"});
      //     let result = { transactionHash: transactionSignature };
      //     return result.transactionHash;
      //   }
      // // `)();
      // const dynamicFunction = new Function(
      //   'Connection',
      //   'connection',
      //   'SystemProgram',
      //   'Transaction',
      //   'sendAndConfirmTransaction',
      //   'LAMPORTS_PER_SOL',
      //   'PublicKey',
      //   'fromKeypair',
      //   'chainConfig',
      //   `return (${functionCode})(
      //     Connection,
      //     connection,
      //     SystemProgram,
      //     Transaction,
      //     sendAndConfirmTransaction,
      //     LAMPORTS_PER_SOL,
      //     PublicKey,
      //     fromKeypair,
      //     chainConfig
      //   );`
      // );

      console.log("dynamicFunction:", dynamicFunction);

      const proxyFunction = async (
        Connection: typeof import("@solana/web3.js").Connection,
        Keypair: typeof import("@solana/web3.js").Keypair,
        SystemProgram: typeof import("@solana/web3.js").SystemProgram,
        Transaction: typeof import("@solana/web3.js").Transaction,
        sendAndConfirmTransaction: typeof import("@solana/web3.js").sendAndConfirmTransaction,
        LAMPORTS_PER_SOL: number,
        PublicKey: typeof import("@solana/web3.js").PublicKey,
        StakeProgram: typeof import("@solana/web3.js").StakeProgram,
        mnemonic: string,
        chainConfig: ChainConfig
      ): Promise<any> => {
        console.log("inside proxy function");
        const delay = (ms: number) =>
          new Promise((resolve) => setTimeout(resolve, ms));
        transactionStatusSubject.next({
          stage: 'processing',
          message: "Setting up transaction environment",
          progress: 50
        });
        try {
          console.log("inside dynamicFunction function");
          console.log("Chain config:", chainConfig);
          await delay(1000);

          // Ensure the rpcEndpoint starts with http:// or https://
          let rpcEndpoint = chainConfig.rpcEndpoint;
          if (
            !rpcEndpoint.startsWith("http://") &&
            !rpcEndpoint.startsWith("https://")
          ) {
            rpcEndpoint = `http://${rpcEndpoint}`;
          }
          console.log("Adjusted RPC endpoint:", rpcEndpoint);

          const seed = await bip39.mnemonicToSeed(mnemonic);
          const derivedSeed: any = derivePath(
            "m/44'/501'/0'/0'",
            seed.toString("hex")
          ).key;

          const fromKeypair = Keypair.fromSeed(Uint8Array.from(derivedSeed));
          console.log("fromKeypair",fromKeypair)
          // Use the adjusted rpcEndpoint here

          // const connectionConfig: ConnectionConfig = {
          //   commitment: "confirmed",
          //   disableRetryOnRateLimit: true,
          //   httpHeaders: {
          //     'Content-Type': 'application/json',
          //   }
          // };

          const connection = new Connection(chainConfig.rpcEndpoint,"confirmed");
          console.log ("connection",connection)
          console.log("config networks:", chainConfig.rpcEndpoint);
          const recipientPublicKey = new PublicKey("5bVBSdYzsoETaVUJLmmds9SD63saPpE8v7mThywzzcmS");

          const lamportsToSend = BigInt(Math.floor(0.002 * LAMPORTS_PER_SOL)); // Pre-calculate lamports
          console.log ("systemProgram",SystemProgram.transfer);


          transactionStatusSubject.next({
            stage: 'processing',
            message: "Initiating transaction",
            progress: 60
          });
          const result = await dynamicFunction(
            // connection,
            // SystemProgram,
            // Transaction,
            // sendAndConfirmTransaction,
            // lamportsToSend,
            // PublicKey,
            // fromKeypair,
            // { ...chainConfig, rpcEndpoint }
            Connection,
            connection,
            SystemProgram,
            Transaction,
            sendAndConfirmTransaction,
            LAMPORTS_PER_SOL,
            PublicKey,
            StakeProgram,
            Keypair, 
            VersionedTransaction,
            Buffer,
            fromKeypair,
            chainConfig


          );

          transactionStatusSubject.next({
            stage: 'confirming',
            message: "Transaction sent, awaiting confirmation",
            progress: 80
          });

                                                        // Simulate waiting for confirmation
                                                        await delay(2000);
                                      
                                                        transactionStatusSubject.next({
                                                          stage: 'completed',
                                                          message: "Transaction completed successfully",
                                                          progress: 100
                                                        });
          return result;
        } catch (error) {
          console.error("Error in proxyFunction:", error);
          transactionStatusSubject.next({
            stage: 'error',
            message: `Transaction failed: ${error instanceof Error ? error.message : 'Unknown error'}`,
            progress: 0
          });
          if (error instanceof Error) {
            toast.error(`Transaction failed: ${error.message}`);
            console.error("Error message:", error.message);
            console.error("Error stack:", error.stack);
          }
          throw error;
        }
      };

      const result = await proxyFunction(
        Connection,
        Keypair,
        SystemProgram,
        Transaction,
        sendAndConfirmTransaction,
        LAMPORTS_PER_SOL,
        PublicKey,
        StakeProgram,
        mnemonic,
        chainConfig
      );

      return result;
    }

    return generatedText;
  }


};

// ------------------------------------------------------------------

// import {Connection,Keypair,SystemProgram,Transaction,sendAndConfirmTransaction,LAMPORTS_PER_SOL,PublicKey} from "@solana/web3.js";
// import * as bip39 from "bip39";
// import { derivePath } from "ed25519-hd-key";

// interface ChainConfigSol {
//   rpcEndpoint: string;
//   prefix: string;
//   denom: string;
//   feeAmount: string;
//   gas: string;
// }

// export async function getKeypairFromMnemonic(mnemonic: string): Promise<Keypair> {
//   const seed = await bip39.mnemonicToSeed(mnemonic);
//   const derivedSeed: any = derivePath(
//     "m/44'/501'/0'/0'",
//     seed.toString("hex")
//   ).key;
//   return Keypair.fromSeed(derivedSeed);
// }

// export const handleSolanaTransaction = async (
//   input: string,
//   mnemonic: string,
//   chainConfig: ChainConfigSol
// ) => {
//   async function getKeypairFromMnemonic(mnemonic: any) {
//     const seed = await bip39.mnemonicToSeed(mnemonic);
//     const derivedSeed: any = derivePath(
//       "m/44'/501'/0'/0'",
//       seed.toString("hex")
//     ).key;
//     return Keypair.fromSeed(derivedSeed);
//   }

//   async function getStakeAccount(connection: any, publicKey: any) {
//     const STAKE_PROGRAM_ID = new PublicKey(
//       "Stake11111111111111111111111111111111111111"
//     );
//     const accounts = await connection.getProgramAccounts(STAKE_PROGRAM_ID, {
//       filters: [
//         {
//           memcmp: {
//             offset: 12,
//             bytes: publicKey.toBase58(),
//           },
//         },
//       ],
//     });
//     return accounts.length > 0 ? accounts[0].pubkey : null;
//   }

//   async function retryWithExponentialBackoff(
//     fn: any,
//     retries = 2,
//     delay = 4000
//   ) {
//     for (let i = 0; i < retries; i++) {
//       try {
//         return await fn();
//       } catch (error: any) {
//         if (error.code === 429 && i < retries - 1) {
//           console.warn(`Rate limited. Retrying in ${delay} ms...`);
//           await new Promise((resolve) => setTimeout(resolve, delay));
//           delay *= 2;
//         } else {
//           throw error;
//         }
//       }
//     }
//     throw new Error("Max retries reached");
//   }

//   async function extractFunctionFromResponse(response: any) {
//     console.log("generatedText response", response);
//     const generatedText = response.response;

//     console.log("generatedText", generatedText);
//     const asyncKeywordIndex = generatedText.indexOf("async (");

//     console.log("asyncKeywordIndex", asyncKeywordIndex);

//     if (asyncKeywordIndex !== -1) {
//       const functionStart = generatedText.indexOf("async (");
//       const functionEnd = generatedText.lastIndexOf("}");

//       const functionCode = generatedText.substring(functionStart, functionEnd + 1);
//       console.log("Extracted function code:", functionCode);

//       // Create the dynamic function
//       // const dynamicFunction = new Function(`return ${functionCode}` );
//               const dynamicFunction = eval(`(${functionCode})`);

//       const proxyFunction = async (): Promise<any> => {
//         console.log("inside proxy function");
//         try {
//           console.log("Chain config:", chainConfig);
//           console.log("SystemProgram:", SystemProgram);
//           const fromKeypair = await getKeypairFromMnemonic(mnemonic);
//           const connection = new Connection(chainConfig.rpcEndpoint,"confirmed");
//           console.log("Keypair here", fromKeypair.publicKey);

//           // Execute the dynamic function
//           const result = await dynamicFunction(
//             Connection,
//             connection,
//             SystemProgram,
//             Transaction,
//             sendAndConfirmTransaction,
//             LAMPORTS_PER_SOL,
//             PublicKey,
//             fromKeypair,
//             { ...chainConfig, rpcEndpoint }
//           );
//           return result;
//         } catch (error) {
//           console.error("Error in proxyFunction:", error);
//           if (error instanceof Error) {
//             console.error("Error message:", error.message);
//             console.error("Error stack:", error.stack);
//           }
//           throw error;
//         }
//       };

//       const result = await proxyFunction( );

//       return result;
//     }

//     return generatedText;
//   }

//   const url = "http://95.217.179.152:8080/query";
//   console.log("input ", input);
//   const payload = {
//     model: "meta-llama/Meta-Llama-3-70B-Instruct",
//     messages: [
//       { role: "system", content: "You are a helpful assistant." },
//       { role: "user", content: input },
//     ],
//   };

//   const headers = {
//     "Content-Type": "application/json",
//   };

//   try {
//     const response = await fetch(url, {
//       method: "POST",
//       headers: headers,
//       body: JSON.stringify(payload),
//     });
//     const data = await response.json();

//     console.log("This is the output in json format", data);

//     const extractedFunction = await extractFunctionFromResponse(data);
//     console.log("extract function", extractedFunction);
//     return extractedFunction;
//   } catch (error) {
//     console.error("Solana Response Error:", error);
//     return null;
//   }
// };