// import React from 'react';
import { SigningStargateClient } from "@cosmjs/stargate";
import { DirectSecp256k1HdWallet } from "@cosmjs/proto-signing";
// import { toast } from "sonner";

interface ChainConfig {
  rpcEndpoint: string;
  prefix: string;
  denom: string;
  feeAmount: string;
  gas: string;
}


export const handleCosmosTransaction  = async (
    input: string,
    mnemonic: string,
    chainConfig: ChainConfig
  ) => {
    async function extractFunctionFromResponse(response: any) {
      const generatedText = response[0].generated_text;
      console.log("generatedText", generatedText);
      const asyncKeywordIndex = generatedText.indexOf("async");
      console.log("asyncKeywordIndex", asyncKeywordIndex);
      const assistantKeywordIndex = generatedText.indexOf("<assistant>:");
      console.log("assistantKeywordIndex", assistantKeywordIndex);
      if (asyncKeywordIndex !== -1 && asyncKeywordIndex > assistantKeywordIndex) {
        const functionStart = generatedText.indexOf(
          "async",
          assistantKeywordIndex
        );
        const functionEnd =
          generatedText.indexOf("return result.transactionHash;") +
          "return result.transactionHash;".length;
  
        const functionCode =
          generatedText.substring(functionStart, functionEnd) + "\n}";
        console.log("Extracted function code:", functionCode);
  
        // const dynamicFunction = eval(`(${functionCode})`);
        const dynamicFunction = new Function(`return ${functionCode}`)();
        console.log("dynamicFunction:", dynamicFunction);
        const proxyFunction = async (
          DirectSecp256k1HdWallet: any,
          SigningStargateClient: any,
          mnemonic: string,
          chainConfig: any
        ): Promise<any> => {
          console.log("inside proxy function");
          const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

          try {
            console.log("inside dynamicFunction function");
            console.log("Chain config:", chainConfig); // Log the chain config
            await delay(1000); 
            const result = await dynamicFunction(
              DirectSecp256k1HdWallet,
              SigningStargateClient,
              mnemonic,
              chainConfig
            );
            return result;
          } catch (error) {
            console.error("Error in proxyFunction:", error);
            if (error instanceof Error) {
              console.error("Error message:", error.message);
              console.error("Error stack:", error.stack);
            }
            throw error;
          }
        };
  
        const result = await proxyFunction(
          DirectSecp256k1HdWallet,
          SigningStargateClient,
          mnemonic,
          chainConfig
        );
  
        return result;
      }
  
      return generatedText;
    }
  
    const url = "https://9ac5-18-213-200-192.ngrok-free.app/predict";
    const payload = {
      inputs: `<human>:${input} <assistant>:`,
    };
  
    const headers = {
      "Content-Type": "application/json",
    };
  
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      const data = await response.json();
  
      console.log("This is the output in json format", data);
      const extractedFunction = await extractFunctionFromResponse(data);
      console.log("extract function", extractedFunction);
      return extractedFunction;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };