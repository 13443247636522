import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import rehypeRaw from "rehype-raw";
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { FiRepeat, FiCopy, FiExternalLink, FiCheckCircle, FiAlertTriangle, FiChevronDown, FiChevronUp } from "react-icons/fi";
import { motion, AnimatePresence } from "framer-motion";
import { useTheme } from '../../contexts/ThemeContext';

// import cosmos3d from "../../Assets/Image/download-removebg-preview.png";

interface MessageProps {
  message: Message;
  messages: Message[];
  copyToClipboard: (text: string) => void;
  setExpandedImage: (url: string | null) => void;
  regenerateResponse: () => void;
  staticBotImageUrl: string;
  loadingGifUrl: string;
  completionGifUrl: string;
  profile: string;
  agent: string;
}

interface Message {
  type: string;
  text: string;
  time: string;
  transactionHash?: string;
  code?: string;
  language?: string;
  imageUrl?: string;
  attachments?: File[];
  error?: string;
}

const MessageComponent: React.FC<MessageProps> = ({
  message,
  messages,
  copyToClipboard,
  setExpandedImage,
  regenerateResponse,
  staticBotImageUrl,
  loadingGifUrl,
  completionGifUrl,
  profile,
  agent,
}) => {


  
  const [transactionType, setTransactionType] = useState("unknown");
  const [network, setNetwork] = useState("unknown");
// Inside the component, add this line near the top
const { darkMode } = useTheme();
const [expandedSections, setExpandedSections] = useState<{ [key: string]: boolean }>({});


  useEffect(() => {
    if (message.type === "transaction" || message.type === "error") {
      const userMessage = messages.find((msg) => msg.type === "user")?.text.toLowerCase() || "";

      // Detect transaction type
      if (userMessage.includes("send")) setTransactionType("send");
      else if (userMessage.includes("delegate")) setTransactionType("delegate");
      else if (userMessage.includes("stake")) setTransactionType("stake");
      else if (userMessage.includes("redelegate")) setTransactionType("redelegate");
      else if (userMessage.includes("undelegate")) setTransactionType("undelegate");
      else if (userMessage.includes("swap")) setTransactionType("swap");

      // Detect network
      if (userMessage.includes("cosmos")) setNetwork("cosmos");
      else if (userMessage.includes("osmosis")) setNetwork("osmosis");
      else if (userMessage.includes("juno")) setNetwork("juno");
      else if (userMessage.includes("solana")) setNetwork("solana");
    }
  }, [message, messages]);

  // const getBackgroundImage = (network: string) => {
  //   switch (network) {
  //     case "cosmos":
  //       return `url(${`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCF9uoytjwV2GIpl27KkRcVIyx5ySDPhu3lw&s`})`;
  //     case "osmosis":
  //       return `url(${"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQcuDv38IMXEB2LWVhwWz8cu8NR-kc5KCkOA&s"})`;
  //     case "juno":
  //       return `url(${"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAAM1BMVEX////oHz/zj5/uV2/+8fPpLUvxc4fsSWP71dv2q7f5x8/rO1f4ucPygZP84+f1navvZXs3skc6AAADqklEQVR4nO2d63qiUAxFWwWLIuj7P21n2hG8nBOSoznZMnv9lF72+kTSoE0+PgghL+aw6z9h6HeHQo3mHJ39nnNT4tEBPRsX2q7g+QD0+HN+2Z+TXXTmNGerRxedOIf1FT9EB86xM4p8RQfO0RtFovPmoQgaaxFp1yJiLSTRebPsVyJivfrCipj/aowOnMF6YoGKtHYPSJGSbiQlst24I/6FV+SREtmU/BwTe8njq6jPDRERW4dCjwgRsSfdFXrUF5Hv2VjbqZnaIo34On/iV1cWGUWPgvIxUVekawWNkjI4U1VE9igqHxM1RcTy8aRHTRGPMjhTT0QsH0971BPxKYMzlUSarejxgt+wfeSpq2AatzJYmc6tDNbFsQxW5eBYBmviWgYr8nwZbI7+vfcyYveh8RhB3xu8RlMG99ILDARNGRRPTBA0ZfAYHVKBqnxAfgbghvao8cA/sZTlA+5DMvf0yjIYnXMJdRcVHXQBfTcYnVTG0EVFRxWxdIPRWSVOBg9kEVsXFZ02i64M4ouYu6jowBm0ZRBdpOBmYnTkJEPBzcTozI9sh9GuEfSWswcUQYMiaFhEDo8UXWBcsIhAP3sUoYgTFKGIExShiBMUoYgTFKGIExShiBMUoYgTFKGIExShiBMUoYgTFKGIExShiBMUoYgTFKGIExShiBMUoYgTFKGIExShiBMUoYgTFKGIExShiBMUoYgTFFmZSIVR0gKnw8tEommnf7ROHHwnkXmkQuLQe4lchlwkDrybyO+uocTjbyfSr0Xk5+RKPPx+Itu1iHxSBI3/VQR6RVri4awI7NK6wSgyVk+oZDSKoC52HDIv4LyIPHc7it/RT4kDQteHaPJvhFXiiNS+NnBn12UUV+KQ3IePA9QY12kCYuIYzg2FH+T9BNOXoYvI+wmuJjmCi8hXl+tJjtgi4n6C20mO0CKW9TbIIuJ+gvtJjsAitrn+uCLG/QSwIsryMYEqYvUAFVGXwRlIEX0ZnEEUMZTBGUCRsi1veCJHSxmcgRMpXW+DJnIq9EATMZePCSyRcg8oEbl8LMz1T3xHn1hOVwXxrsbSXH/pe4FYnusfnVCHYq5/dEQVmrn+0Rk1qOb6R4dUoNtPEJ1yGeVamOiYi2jX20TnXEK93iY66AL69TbRSUUs622is0qY1tuItytisa23ERuZUIzrbRrUPZvmNT2gGyoLdmXDvU/7l6Kd35vo1I+YtrzNdGCXrnP5buZxH/rR8Bv2OMtZSDnfXDBQ9mWwSUUAAAAASUVORK5CYII="})`;
  //     case "solana":
  //       return `url(${"https://www.google.com/imgres?q=solana&imgurl=https%3A%2F%2Fs3.coinmarketcap.com%2Fstatic-gravity%2Fimage%2F5cc0b99a8dd84fbfa4e150d84b5531f2.png&imgrefurl=https%3A%2F%2Fcoinmarketcap.com%2Fcurrencies%2Fsolana%2F&docid=tx7h2f4cDKKleM&tbnid=DNyli9dUra7SrM&vet=12ahUKEwibsKu06ciHAxUoU2wGHRwqCkEQM3oECGcQAA..i&w=1200&h=1200&hcb=2&ved=2ahUKEwibsKu06ciHAxUoU2wGHRwqCkEQM3oECGcQAA"})`;
  //     default:
  //       return "linear-gradient(135deg, #667eea 0%, #764ba2 100%)";
  //   }
  // };
  // const getIcon = (type: string) => {
  //   switch (type) {
  //     case "send":
  //       return <FiSend className="w-8 h-8" />;
  //     case "delegate":
  //       return <FiRefreshCw className="w-8 h-8" />;
  //     case "redelegate":
  //       return <FiLink className="w-8 h-8" />;
  //     case "undelegate":
  //       return <FiActivity className="w-8 h-8" />;
  //     default:
  //       return <FiGlobe className="w-8 h-8" />;
  //   }
  // };
  const shortenAddress = (address: string, chars = 4) => {
    return `${address.slice(0, chars)}...${address.slice(-chars)}`;
  };

  const gasFee = "1.6";

  const detectLanguage = (code: string, specifiedLanguage?: string): string => {
    if (specifiedLanguage) {
      return specifiedLanguage.toLowerCase();
    }

    const languagePatterns = {
      cpp: /#include|using namespace|int main\s*\(/,
      python: /import\s+|def\s+\w+\s*\(|print\s*\(/,
      javascript:
        /function\s+\w+\s*\(|const\s+\w+\s*=|let\s+\w+\s*=|var\s+\w+\s*=/,
      java: /public\s+class|System\.out\.println/,
      bash: /#!/,
      golang: /package\s+main|func\s+main\s*\(\)/,
      console: /^\$\s|^>\s/,
      shell: /^\$\s|^>\s/,
    };

    for (const [lang, pattern] of Object.entries(languagePatterns)) {
      if (pattern.test(code)) {
        return lang;
      }
    }

    return "text";
  };

  const renderTransactionCard = () => {
    const userMessage = messages.find((msg) => msg.type === "user")?.text || "";
    const transactionRegex = /(\w+)\s+(\d+(\.\d+)?)\s+(\w+)\s+to\s+(\S+)/i;
    const match = userMessage.match(transactionRegex);

    // Extract transaction details
    const transactionType = match ? match[1].toLowerCase() : "unknown";
    const amount = match ? match[2] : "Unknown";
    const currency = match ? match[4] : ` ${agent}||ATOM`;
    const recipient = match ? match[5] : "Unknown";
    const transactionHash = message.transactionHash || (isTransactionHash(message.text) ? message.text : "Unknown");
    // const error = message.error; // Add this line to extract error information
    const gasFee = "0.005"; // This could be dynamically calculated based on the network

    const isSolanaTransaction = agent === "Solana";

    if (message.type === "error") {
      return (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="max-w-96 bg-gradient-to-br from-red-500 to-red-700 p-6 rounded-xl shadow-lg"
        >
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center">
              <FiAlertTriangle className="text-white w-8 h-8 mr-3" />
              <h2 className="text-2xl font-bold text-white">
                Transaction Failed
              </h2>
            </div>
          </div>

          <div className="space-y-4 text-white">
            <p className="text-lg font-semibold">Error: {message.error}</p>
            <p>Please check your transaction details and try again.</p>
          </div>

          <div className="mt-6 flex justify-center">
            <button
              onClick={() => copyToClipboard(message.error || "")}
              className="flex-1 flex items-center justify-center px-4 py-2 bg-white bg-opacity-20 hover:bg-opacity-30 rounded-xl transition-colors text-white"
            >
              <FiCopy className="mr-2" /> Copy Error
            </button>
          </div>
        </motion.div>
      );
    }


// if nothing work in tx card uncomment this
  //   if (message.type === "transaction") {
  //   if (isSolanaTransaction) {
  //     return (
  //       <motion.div
  //         initial={{ opacity: 0, y: 20 }}
  //         animate={{ opacity: 1, y: 0 }}
  //         transition={{ duration: 0.5 }}
  //         className="max-w-96 bg-gradient-to-br from-purple-500 to-purple-700 p-6 rounded-xl shadow-lg"
  //       >
  //         <div className="flex items-center justify-between mb-6">
  //           <div className="flex items-center">
  //             <FiCheckCircle className="text-green-300 w-8 h-8 mr-3" />
  //             <h2 className="text-2xl font-bold">Transaction Successful</h2>
  //           </div>
  //           {/* <span className="text-xs bg-white bg-opacity-20 px-2 py-1 rounded-full">
  //         {new Date().toLocaleTimeString()}
  //       </span> */}
  //         </div>

  //         <div className="space-y-4">
  //           <div className="bg-white bg-opacity-10 p-4 rounded-xl">
  //             <p className="text-sm text-white text-opacity-70">
  //               Amount Transferred
  //             </p>
  //             <p className="text-3xl font-bold">
  //               {amount} <span className="text-xl">{currency}</span>
  //             </p>
  //           </div>

  //           <div className="flex justify-between">
  //             <div>
  //               <p className="text-sm text-white text-opacity-70">Recipient</p>
  //               <p className="text-lg font-semibold">
  //                 {shortenAddress(recipient, 8)}
  //               </p>
  //             </div>
  //             <div>
  //               <p className="text-sm text-white text-opacity-70">Gas Fee</p>
  //               <p className="text-lg font-semibold">
  //                 {gasFee} <span className="text-sm">{currency}</span>
  //               </p>
  //             </div>
  //           </div>

  //           <div>
  //             <p className="text-sm text-white text-opacity-70">
  //               Transaction Signature
  //             </p>
  //             <p className="text-xs break-all bg-white bg-opacity-10 p-2 rounded-lg mt-1">
  //               {transactionHash}
  //             </p>
  //           </div>
  //         </div>

  //         <div className="flex justify-between mt-6 text-sm space-x-4">
  //           <button
  //             onClick={() => copyToClipboard(transactionHash)}
  //             className="flex-1 flex items-center justify-center px-4 py-2 bg-white bg-opacity-20 hover:bg-opacity-30 rounded-xl transition-colors"
  //           >
  //             <FiCopy className="mr-2" /> Copy Signature
  //           </button>
  //           <a
  //             href={`https://solscan.io/tx/${transactionHash}`}
  //             target="_blank"
  //             rel="noopener noreferrer"
  //             className="flex-1 flex items-center justify-center px-4 py-2 bg-white text-gray-800 rounded-xl hover:bg-opacity-90 transition-colors"
  //           >
  //             <FiExternalLink className="mr-2" /> View on Explorer
  //           </a>
  //         </div>
  //       </motion.div>
  //     );

  //   } else {
  //     return (
  //       <motion.div
  //         initial={{ opacity: 0, y: 20 }}
  //         animate={{ opacity: 1, y: 0 }}
  //         transition={{ duration: 0.5 }}
  //         className=" max-w-96 bg-gradient-to-br from-green-500 to-green-700 p-6 rounded-xl shadow-lg"
  //       >
  //         <div className="flex items-center justify-between mb-6">
  //           <div className="flex items-center">
  //             <FiCheckCircle className="text-green-300 w-8 h-8 mr-3" />
  //             <h2 className="text-2xl font-bold">Transaction Successful</h2>
  //           </div>
  //           {/* <span className="text-xs bg-white bg-opacity-20 px-2 py-1 rounded-full">
  //         {new Date().toLocaleTimeString()}
  //       </span> */}
  //         </div>

  //         <div className="space-y-4">
  //           <div className="bg-white bg-opacity-10 p-4 rounded-xl">
  //             <p className="text-sm text-white text-opacity-70">
  //               Amount Transferred
  //             </p>
  //             <p className="text-3xl font-bold">
  //               {amount} <span className="text-xl">{currency}</span>
  //             </p>
  //           </div>

  //           <div className="flex justify-between">
  //             <div>
  //               <p className="text-sm text-white text-opacity-70">Recipient</p>
  //               <p className="text-lg font-semibold">
  //                 {shortenAddress(recipient, 8)}
  //               </p>
  //             </div>
  //             <div>
  //               <p className="text-sm text-white text-opacity-70">Gas Fee</p>
  //               <p className="text-lg font-semibold">
  //                 {gasFee} <span className="text-sm">{currency}</span>
  //               </p>
  //             </div>
  //           </div>

  //           <div>
  //             <p className="text-sm text-white text-opacity-70">
  //               Transaction Hash
  //             </p>
  //             <p className="text-xs break-all bg-white bg-opacity-10 p-2 rounded-lg mt-1">
  //               {transactionHash}
  //             </p>
  //           </div>
  //         </div>

  //         <div className="flex justify-between mt-6 text-sm space-x-4">
  //           <button
  //             onClick={() => copyToClipboard(transactionHash)}
  //             className="flex-1 flex items-center justify-center px-4 py-2 bg-white bg-opacity-20 hover:bg-opacity-30 rounded-xl transition-colors"
  //           >
  //             <FiCopy className="mr-2" /> Copy Hash
  //           </button>
  //           <a
  //             href={`https://www.mintscan.io/${network}/txs/${transactionHash}`}
  //             target="_blank"
  //             rel="noopener noreferrer"
  //             className="flex-1 flex items-center justify-center px-4 py-2 bg-white text-gray-800 rounded-xl hover:bg-opacity-90 transition-colors"
  //           >
  //             <FiExternalLink className="mr-2" /> View on Explorer
  //           </a>
  //         </div>
  //       </motion.div>
  //     );
  //   }
  // }

  
  const cardProps = isSolanaTransaction
      ? {
          bgColorClass: "from-green-500 to-green-700",
          explorerUrl: `https://solscan.io/tx/${transactionHash}`,
          copyText: "Copy Signature",
        }
      : {
          bgColorClass: "from-green-500 to-green-700",
          explorerUrl: `https://www.mintscan.io/${network}/txs/${transactionHash}`,
          copyText: "Copy Hash",
        };

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className={`max-w-96 bg-gradient-to-br ${cardProps.bgColorClass} p-6 rounded-xl shadow-lg`}
      >
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <FiCheckCircle className="text-green-300 w-8 h-8 mr-3" />
            <h2 className="text-2xl font-bold">Transaction Successful</h2>
          </div>
        </div>
        <div className="space-y-4">
          {/* <div className="bg-white bg-opacity-10 p-4 rounded-xl">
            <p className="text-sm text-white text-opacity-70">Amount Transferred</p>
            <p className="text-3xl font-bold">
              {amount} <span className="text-xl">{currency}</span>
            </p>
          </div>
          <div className="flex justify-between">
            <div>
              <p className="text-sm text-white text-opacity-70">Recipient</p>
              <p className="text-lg font-semibold">{shortenAddress(recipient, 8)}</p>
            </div>
            <div>
              <p className="text-sm text-white text-opacity-70">Gas Fee</p>
              <p className="text-lg font-semibold">
                {gasFee} <span className="text-sm">{currency}</span>
              </p>
            </div>
          </div> */}
          <div>
            <p className="text-sm text-white text-opacity-70">
              {isSolanaTransaction ? "Transaction Signature" : "Transaction Hash"}
            </p>
            <p className="text-xs break-all bg-white bg-opacity-10 p-2 rounded-lg mt-1">
              {transactionHash}
            </p>
          </div>
        </div>
        <div className="flex justify-between mt-6 text-sm space-x-4">
          <button
            onClick={() => copyToClipboard(transactionHash)}
            className="flex-1 flex items-center justify-center px-4 py-2 bg-white bg-opacity-20 hover:bg-opacity-30 rounded-xl transition-colors"
          >
            <FiCopy className="mr-2" /> {cardProps.copyText}
          </button>
          <a
            href={cardProps.explorerUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="flex-1 flex items-center justify-center px-4 py-2 bg-white text-gray-800 rounded-xl hover:bg-opacity-90 transition-colors"
          >
            <FiExternalLink className="mr-2" /> View on Explorer
          </a>
        </div>
      </motion.div>
    );
  // return null;
  };

  const isTransactionHash = (text: string) => {
    // Check if the text matches the pattern of a transaction hash
    return /^[A-Za-z0-9]{64,}$/.test(text.trim());
  };

  const toggleSection = (id: string) => {
    setExpandedSections(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const renderMessageContent = () => {
    if (message.type === "transaction" || message.type === "error" || isTransactionHash(message.text)) {
      return renderTransactionCard();
    }

    return (
      <div
        className={`inline-block p-1 rounded-2xl text-pretty max-w-full ${
          message.type === "user"
            ? `space-y-3 pb-4 border md:pb-2 px-7 ${darkMode ? 'bg-teal-400/20 border-teal-600 text-white' : 'bg-white border-gray-200'} text-${darkMode ? 'white' : 'black'} font-normal md:font-normal`
            : `space-y-4 border pb-4 md:pb-3 px-5 font-normal md:font-normal tracking-normal ${darkMode ? 'bg-emerald-950/20 border-teal-800 text-white' : 'bg-gray-50 border-gray-300'} text-${darkMode ? 'white' : 'gray-800'}`
        }`}
      >
        {message.attachments && message.attachments.length > 0 && (
          <div className="flex flex-wrap gap-2 mb-3">
            {message.attachments.map((file, index) => (
              <div key={index} className={`px-3 py-1 rounded-full text-xs font-medium ${darkMode ? 'bg-blue-900 text-blue-200' : 'bg-blue-100 text-blue-800'}`}>
                {file.name}
              </div>
            ))}
          </div>
        )}
        <ReactMarkdown
          rehypePlugins={[rehypeRaw, rehypeKatex]}
          remarkPlugins={[remarkGfm, remarkMath]}
          components={{
            code: ({ node, inline, className, children, ...props }: any) => {
              const match = /language-(\w+)/.exec(className || "");
              const lang = !inline && match ? match[1] : "";
              const code = String(children).replace(/\n$/, "");
            
              if (inline) {
                return (
                  <span className="relative group inline-flex items-center">
                    <code
                      className="px-1.5 py-0.5 rounded font-mono text-sm bg-gray-800 text-gray-200 border border-gray-700 shadow-sm"
                      {...props}
                    >
                      {children}
                    </code>
                    <button
                      onClick={() => copyToClipboard(code)}
                      className="ml-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200 text-gray-400 hover:text-gray-200"
                      aria-label="Copy code"
                    >
                      <FiCopy size={14} />
                    </button>
                  </span>
                );
              }
            
              return (
                <div className="relative mt-4 mb-4 bg-gray-800 rounded-lg overflow-hidden">
                  <div className="flex justify-between items-center px-4 py-2 bg-gray-700 text-white">
                    <span className="text-sm font-mono">{lang || 'plaintext'}</span>
                    <button
                      onClick={() => copyToClipboard(code)}
                      className="p-1 hover:bg-gray-600 rounded transition-colors duration-200"
                    >
                      <FiCopy size={16} />
                    </button>
                  </div>
                  <SyntaxHighlighter
                    style={vscDarkPlus}
                    language={lang}
                    PreTag="div"
                    className="!m-0 !rounded-t-none"
                    showLineNumbers
                    {...props}
                  >
                    {code}
                  </SyntaxHighlighter>
                </div>
              );
            },
            h1: ({ children }) => (
              <h1 className="text-3xl font-bold mt-6 mb-4 pb-2 border-b border-gray-300 dark:border-gray-700">{children}</h1>
            ),
            h2: ({ children }) => (
              <h2 className="text-2xl font-semibold mt-5 mb-3">{children}</h2>
            ),
            h3: ({ children }) => (
              <h3 className="text-xl font-medium mt-4 mb-2">{children}</h3>
            ),
            p: ({ children }) => (
              <p className="my-2 leading-7">{children}</p>
            ),
            ul: ({ children }) => (
              <ul className="list-disc pl-5 space-y-2 my-3">{children}</ul>
            ),
            ol: ({ children }) => (
              <ol className="list-decimal pl-5 space-y-2 my-3">{children}</ol>
            ),
            li: ({ children }) => (
              <li className="mb-1">{children}</li>
            ),
            blockquote: ({ children }) => (
              <blockquote className={`border-l-4 pl-4 py-2 my-4 italic ${darkMode ? 'border-gray-600 text-gray-400' : 'border-gray-300 text-gray-600'}`}>
                {children}
              </blockquote>
            ),
            a: ({ node, ...props }) => (
              <a
                {...props}
                className="text-blue-500 hover:text-blue-600 underline transition-colors duration-200"
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.children}
              </a>
            ),
            img: ({ src, alt, ...props }) => (
              <img
                src={src}
                alt={alt}
                {...props}
                className="max-w-full h-auto rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 my-4"
                onClick={() => src && setExpandedImage(src)}
              />
            ),
            table: ({ children }) => (
              <div className="overflow-x-auto my-4">
                <table className="min-w-full divide-y divide-gray-700 bg-gray-800 text-gray-300">
                  {children}
                </table>
              </div>
            ),
            th: ({ children }) => (
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider bg-gray-700">
                {children}
              </th>
            ),
            td: ({ children }) => (
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {children}
              </td>
            ),
            hr: () => <hr className={`my-6 border-t ${darkMode ? 'border-gray-700' : 'border-gray-300'}`} />,
            details: ({ children }) => {
              if (!children) return null; // Handle case where children is null or undefined
              
              const childrenArray = React.Children.toArray(children);
              const summary = childrenArray[0];
              const content = childrenArray.slice(1);
              const id = Math.random().toString(36).substring(7);
              
              return (
                <div className={`border rounded-lg my-4 ${darkMode ? 'border-gray-700' : 'border-gray-300'}`}>
                  <button
                    className={`flex justify-between items-center w-full px-4 py-2 text-left font-medium ${darkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-gray-100 hover:bg-gray-200'}`}
                    onClick={() => toggleSection(id)}
                  >
                    {summary}
                    {expandedSections[id] ? <FiChevronUp /> : <FiChevronDown />}
                  </button>
                  <AnimatePresence>
                    {expandedSections[id] && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className={`px-4 py-2 ${darkMode ? 'bg-gray-900' : 'bg-white'}`}
                      >
                        {content}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              );
            },
            kbd: ({ children }) => (
              <kbd className={`px-2 py-1.5 text-xs font-semibold rounded-lg ${darkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-800'}`}>
                {children}
              </kbd>
            ),
          }}
        >
          {message.text}
        </ReactMarkdown>
      </div>
    );
  };

  return (
    <div className={`mb-4 ${message.type === "user" ? "text-right" : "text-left"}`}>
      {renderMessageContent()}
      <div className="text-xs text-gray-400 mt-1">
        {message.type === "user" ? (
          <div className="-mt-3 flex items-end pr-4">
            <div className={`pb-0.5 caption1  ml-auto font-medium ${darkMode? "text-gray-400" :"text-gray-500"} `}>{message.time}</div>
            <div className="relative ml-3 w-8 h-8 rounded-lg overflow-hidden border-2 border-teal-100">
              <img
                src={profile}
                alt="user"
                className="inline-block align-top transition-opacity object-cover bg-gray-200"
                loading="lazy"
              />
            </div>
          </div>
        ) : (
          <div className="-mt-3 flex items-end pl-4">
            <div className="relative shrink-0 w-[34px] h-[34px] mr-3 rounded-lg overflow-hidden border-2 border-teal-100">
              <img
                src={
                  message.type === "loading"
                    ? loadingGifUrl
                    : message.type === "transaction"
                    ? completionGifUrl
                    : staticBotImageUrl
                }
                alt="Bot"
                className="inline-block align-top transition-opacity bg-white opacity-100 object-contain"
                loading="lazy"
              />
            </div>
            <div className="-mt-9 flex items-end">
              <div className="caption1 text-n-4/50"></div>
              <button
                className="h-5 px-1 bg-n-3 font-medium rounded-md caption1 txt-n-6 transition-colors text-teal-950 hover:text-sky-500 dark:bg-n-7 bg-gray-200"
                onClick={() => copyToClipboard(message.text)}
              >
                <FiCopy size={12} />
              </button>
              <button
                className="h-5 ml-3 px-1 bg-n-3 rounded-md caption1 txt-n-6 font-medium transition-colors text-teal-950 hover:text-sky-500 dark:bg-n-7 bg-gray-200"
                onClick={regenerateResponse}
              >
                <FiRepeat size={12} />
              </button>
              <div className={`pb-0.5 caption1  ml-3 font-medium ${darkMode? "text-gray-400" :"text-gray-500"} `}>
                {message.time}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );


  // return (
  //   <div
  //     className={`mb-4 ${message.type === "user" ? "text-right" : "text-left"}`}
  //   >
  //     {message.type === "transaction" || message.type === "error" ? (
  //       renderTransactionCard()
  //     ) : (
  //       <div
  //         className={`inline-block p-5 rounded-2xl text-pretty max-w-full ${
  //           message.type === "user"
  //             ? "space-y-3 pb-4 border-2 md:pb-6 px-7 truncate ... border-gray-100 font-normal md:font-normal "
  //             : "space-y-4 border-2 pb-5 md:pb-6 px-5 truncate ... font-normal md:font-normal text-gray-600 bg-gray-100 border-none"
  //         }`}
  //       >
  //         {message.attachments && message.attachments.length > 0 && (
  //           <div className="flex flex-wrap gap-2 ">
  //             {message.attachments.map((file, index) => (
  //               <div key={index} className="bg-gray-200 rounded px-4 py-1">
  //                 <span className="text-sm">{file.name}</span>
  //               </div>
  //             ))}
  //           </div>
  //         )}

  //         <ReactMarkdown
  //           rehypePlugins={[rehypeRaw]}
  //           components={{
  //             code: ({ node, inline, className, children, ...props }: any) => {
  //               const match = /language-(\w+)/.exec(className || "");
  //               const code = String(children).replace(/\n$/, "");
  //               const specifiedLanguage = match ? match[1] : undefined;
  //               //   const language = match ? match[1] : detectLanguage(code);
  //               const language = detectLanguage(code, specifiedLanguage);
  //               return !inline ? (
  //                 <div className="relative mt-4 mb-4 text-pretty">
  //                   <div className="relative top-0 right-0 left-0 flex justify-between items-center px-4 py-1 bg-gray-800 text-white rounded-t-lg">
  //                     <span className="text-sm font-mono">{language}</span>
  //                     <button
  //                       onClick={() => copyToClipboard(code)}
  //                       className="p-1 hover:bg-gray-700 rounded"
  //                     >
  //                       <FiCopy size={16} />
  //                     </button>
  //                   </div>
  //                   <SyntaxHighlighter
  //                     style={vscDarkPlus}
  //                     language={language}
  //                     PreTag="div"
  //                     className="rounded-b-lg border-t-0 !mt-0"
  //                     showLineNumbers
  //                     {...props}
  //                   >
  //                     {code}
  //                   </SyntaxHighlighter>
  //                 </div>
  //               ) : (
  //                 <code className="bg-green-900 rounded px-1 py-0.5" {...props}>
  //                   {children}
  //                 </code>
  //               );
  //             },
  //             // img: ({node, ...props}) => (
  //             //   <img
  //             //     {...props}
  //             //     className="cursor-pointer max-w-full h-auto"
  //             //     onClick={() => props.src && setExpandedImage(props.src)}
  //             //   />
  //             img: ({ src, alt, ...props }) => (
  //               <img
  //                 src={src}
  //                 alt={alt}
  //                 {...props}
  //                 className="cursor-pointer max-w-full h-auto"
  //                 onClick={() => src && setExpandedImage(src)}
  //               />
  //             ),
  //           }}
  //         >
  //           {message.text}
  //         </ReactMarkdown>
  //       </div>
  //     )}
  //     <div className="text-xs text-gray-400 mt-1">
  //       {message.type === "user" ? (
  //         <div className="-mt-3 flex items-end pr-4">
  //           <div className="pb-0.5 caption1 text-n-4/50 ml-auto font-medium ">
  //             {message.time}
  //           </div>
  //           <div className="relative ml-3 w-9 h-9 rounded-lg border-1 border-gray-800 overflow-hidden shadow-[0_0_0_0.25rem_#FEFEFE] ">
  //             <img
  //               src={profile}
  //               alt="user"
  //               className="inline-block align-top transition-opacity object-cover bg-gray-300"
  //               loading="lazy"
  //             />
  //           </div>
  //         </div>
  //       ) : (
  //         <div className="-mt-3 flex items-end pl-4">
  //           <div className="relative shrink-0 w-9 h-9 mr-3 rounded-lg overflow-hidden shadow-[0_0_0_0.25rem_#FEFEFE] ">
  //             <img
  //               src={
  //                 message.type === "loading"
  //                   ? loadingGifUrl
  //                   : message.type === "transaction"
  //                   ? completionGifUrl
  //                   : staticBotImageUrl
  //               }
  //               alt="Bot"
  //               className="inline-block align-top transition-opacity bg-white opacity-100 object-contain"
  //               loading="lazy"
  //             />
  //           </div>
  //           <div className="-mt-9 flex items-center">
  //             <div className="caption1 text-n-4/50 "> </div>
  //             <button
  //               className="h-6 px-2 bg-n-3 font-medium rounded-md caption1 txt-n-6 transition-colors hover:text-sky-500 dark:bg-n-7 bg-gray-200"
  //               onClick={() => copyToClipboard(message.text)}
  //             >
  //               <FiCopy size={12} />
  //             </button>
  //             <button
  //               className="h-6 ml-3 px-2 bg-n-3 rounded-md caption1 txt-n-6 font-medium transition-colors hover:text-primary-1 hover:text-sky-500 dark:bg-n-7 bg-gray-200"
  //               onClick={regenerateResponse}
  //             >
  //               <FiRepeat size={12} />
  //             </button>
  //             <div className="ml-3 pb-0.5 caption1 text-n-4/50 font-medium">
  //               {message.time}
  //             </div>
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //   </div>
  // );
};

export default MessageComponent;
