import React, { useEffect, useState } from "react";
// import { Secp256k1HdWallet } from "@cosmjs/amino";
// import { StargateClient } from "@cosmjs/stargate";
// import axios from "axios";
import { Toaster, toast } from "sonner";
import {
  Switch,
  Tabs,
  Modal,
  Badge,
  // ProgressBar,
} from "./../../Components/ui/ui";
import {
  Sun,
  Moon,
  // Bell,
  // BellOff,
  // Eye,
  // EyeOff,
  // Plus,
  // Check,
  // ChevronRight,
  Settings,
  Lock,
  User,
  Camera,
  // Activity,
  Zap,
  Globe,
  Shield,
  // Coffee,
  // Briefcase,
  // DollarSign,
  // BarChart2,
  // GitBranch,
  // Database,
  Key,
  Smartphone,
} from "lucide-react";
import ProfileNav from "./ProfileNav";
import { Wallet } from "./../../Components/NewChatWindows/types";
import { motion, AnimatePresence } from "framer-motion";
import {
  LineChart,
  Line,
  // AreaChart,
  // Area,
  BarChart,
  Bar,
  PieChart,
  Pie,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
} from "recharts";

interface ProfilePageProps {
  profilePic: string;
}

const ProfilePage: React.FC<ProfilePageProps> = ({
  profilePic: initialProfilePic,
}) => {
  const [activeTab, setActiveTab] = useState<string>("myProfile");
  const [profileName, setProfileName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [bio, setBio] = useState<string>("");
  // const [isPrivateMode, setIsPrivateMode] = useState(true);
  // const [wallets, setWallets] = useState<Wallet[]>([]);
  // const [defaultWalletIndex, setDefaultWalletIndex] = useState<number>(0);
  const [apiUrl, setApiUrl] = useState<string>("");
  const [theme, setTheme] = useState<"light" | "dark">("light");
  const [notificationSettings, setNotificationSettings] = useState({
    email: true,
    push: false,
    sms: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode | null>(
    null
  );
  const [profilePic, setProfilePic] = useState<string>(initialProfilePic);
  const [agentPerformance, setAgentPerformance] = useState<any[]>([]);
  const [tokenBalances, setTokenBalances] = useState<any[]>([]);
  const [transactionHistory, setTransactionHistory] = useState<any[]>([]);
  const [securityScore, setSecurityScore] = useState<number>(0);
  const [aiModelPreferences, setAiModelPreferences] = useState<any>({});
  const [blockchainStats, setBlockchainStats] = useState<any>({});

  useEffect(() => {
    loadInitialData();
    loadWallets();
    loadAdvancedSettings();
    loadMockData(); // Load mock data for demonstration
  }, []);

  const loadInitialData = () => {
    // ... (keep existing implementation)
  };

  const loadWallets = () => {
    // ... (keep existing implementation)
  };

  const loadAdvancedSettings = () => {
    // ... (keep existing implementation)
  };

  const loadMockData = () => {
    // Mock data for agent performance
    setAgentPerformance([
      { name: "Cosmos", performance: 95, tasks: 1200 },
      { name: "Ethereum", performance: 88, tasks: 980 },
      { name: "Solana", performance: 92, tasks: 1050 },
      { name: "Polkadot", performance: 86, tasks: 750 },
    ]);

    // Mock data for token balances
    setTokenBalances([
      { name: "ATOM", balance: 1500, value: 22500 },
      { name: "ETH", balance: 5, value: 15000 },
      { name: "SOL", balance: 200, value: 10000 },
      { name: "DOT", balance: 1000, value: 8000 },
    ]);

    // Mock data for transaction history
    setTransactionHistory([
      { date: "2023-07-01", type: "Send", amount: 50, token: "ATOM" },
      { date: "2023-07-03", type: "Receive", amount: 2, token: "ETH" },
      { date: "2023-07-05", type: "Stake", amount: 100, token: "SOL" },
      { date: "2023-07-07", type: "Swap", amount: 500, token: "DOT" },
    ]);

    // Mock security score
    setSecurityScore(85);

    // Mock AI model preferences
    setAiModelPreferences({
      "GPT-4": 70,
      BERT: 20,
      "DALL-E": 10,
    });

    // Mock blockchain stats
    setBlockchainStats({
      totalTransactions: 15678,
      activeSmartContracts: 234,
      totalValueLocked: "$1.2B",
      averageBlockTime: "6.5s",
    });
  };

  const handleSaveProfile = () => {
    // ... (keep existing implementation)
  };

  const handleApiUrlChange = (newUrl: string) => {
    // ... (keep existing implementation)
  };

  const toggleTheme = () => {
    // ... (keep existing implementation)
  };

  const handleNotificationChange = (type: "email" | "push" | "sms") => {
    // ... (keep existing implementation)
  };

  const handleProfilePicChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // ... (keep existing implementation)
  };

  const renderMyProfileTab = () => (
    <div className="space-y-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white dark:bg-gray-800 shadow-xl rounded-2xl p-8"
      >
        <div className="flex items-center mb-6">
          <div className="relative">
            <img
              src={profilePic}
              alt="Profile"
              className="w-32 h-32 rounded-full mr-6 border-4 border-blue-500"
            />
            <label
              htmlFor="profile-pic-input"
              className="absolute bottom-0 right-0 bg-blue-500 p-3 rounded-full cursor-pointer hover:bg-blue-600 transition-colors"
            >
              <Camera size={24} color="white" />
            </label>
            <input
              id="profile-pic-input"
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleProfilePicChange}
            />
          </div>
          <div>
            <h2 className="text-3xl font-bold text-gray-800 dark:text-white">
              {profileName}
            </h2>
            <p className="text-xl text-gray-600 dark:text-gray-300">{bio}</p>
            <div className="mt-2 flex space-x-2">
              <Badge variant="outline" className="px-3 py-1">
                <Globe className="w-4 h-4 mr-1" />
                Blockchain Enthusiast
              </Badge>
              <Badge variant="outline" className="px-3 py-1">
                <Zap className="w-4 h-4 mr-1" />
                AI Specialist
              </Badge>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h3 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-white">
              Personal Information
            </h3>
            <div className="space-y-4">
              <div>
                <label className="block text-gray-600 dark:text-gray-300 mb-2">
                  Name
                </label>
                <input
                  type="text"
                  value={profileName}
                  onChange={(e) => setProfileName(e.target.value)}
                  className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
                />
              </div>
              <div>
                <label className="block text-gray-600 dark:text-gray-300 mb-2">
                  Email
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
                />
              </div>
              <div>
                <label className="block text-gray-600 dark:text-gray-300 mb-2">
                  Phone
                </label>
                <input
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
                />
              </div>
              <div>
                <label className="block text-gray-600 dark:text-gray-300 mb-2">
                  Bio
                </label>
                <textarea
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
                  rows={3}
                />
              </div>
            </div>
          </div>
          <div>
            <h3 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-white">
              Agent Performance
            </h3>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={agentPerformance}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <RechartsTooltip />
                <Bar dataKey="performance" fill="#8884d8" />
                <Bar dataKey="tasks" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <button
          onClick={handleSaveProfile}
          className="mt-6 bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition-colors text-lg font-semibold"
        >
          Save Changes
        </button>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="bg-white dark:bg-gray-800 shadow-xl rounded-2xl p-8"
      >
        <h3 className="text-2xl font-semibold mb-6 text-gray-800 dark:text-white">
          Blockchain Activity
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h4 className="text-xl font-semibold mb-4 text-gray-700 dark:text-gray-300">
              Token Balances
            </h4>
            <ResponsiveContainer width="100%" height={200}>
              <PieChart>
                <Pie
                  data={tokenBalances}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label
                />
                <RechartsTooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div>
            <h4 className="text-xl font-semibold mb-4 text-gray-700 dark:text-gray-300">
              Transaction History
            </h4>
            <ResponsiveContainer width="100%" height={200}>
              <LineChart>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <RechartsTooltip />
                <Line type="monotone" dataKey="amount" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </motion.div>
    </div>
  );

  const renderSecurityTab = () => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white dark:bg-gray-800 shadow-xl rounded-2xl p-8"
    >
      <h3 className="text-2xl font-semibold mb-6 text-gray-800 dark:text-white">
        Security Settings
      </h3>
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <div>
            <h4 className="text-lg font-semibold text-gray-700 dark:text-gray-300">
              Two-Factor Authentication
            </h4>
            <p className="text-gray-600 dark:text-gray-400">
              Add an extra layer of security to your account
            </p>
          </div>
          <Switch checked={false} onCheckedChange={() => {}} />
        </div>
        <div className="flex items-center justify-between">
          <div>
            <h4 className="text-lg font-semibold text-gray-700 dark:text-gray-300">
              Password Reset
            </h4>
            <p className="text-gray-600 dark:text-gray-400">
              Change your account password
            </p>
          </div>
          <button className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors">
            Change Password
          </button>
        </div>
        <div className="flex items-center justify-between">
          <div>
            <h4 className="text-lg font-semibold text-gray-700 dark:text-gray-300">
              Login History
            </h4>
            <p className="text-gray-600 dark:text-gray-400">
              View your recent login activity
            </p>
          </div>
          <button className="bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-white px-4 py-2 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors">
            View History
          </button>
        </div>
        <div>
          <h4 className="text-lg font-semibold text-gray-700 dark:text-gray-300 mb-2">
            Security Score
          </h4>
          <div className="flex items-center space-x-4">
            {/* <ProgressBar progress={securityScore} className="w-64" /> */}
            <span className="text-2xl font-bold text-blue-500">
              {securityScore}%
            </span>
          </div>
          <p className="text-gray-600 dark:text-gray-400 mt-2">
            Your account security is good, but there's room for improvement.
          </p>
        </div>
      </div>

      <div className="mt-8">
        <h4 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">
          Blockchain Security
        </h4>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
            <h5 className="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300">
              Multi-Signature Wallet
            </h5>
            <p className="text-gray-600 dark:text-gray-400 mb-2">
              Enable multi-sig for enhanced security
            </p>
            <Switch checked={false} onCheckedChange={() => {}} />
          </div>
          <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
            <h5 className="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300">
              Hardware Wallet Integration
            </h5>
            <p className="text-gray-600 dark:text-gray-400 mb-2">
              Connect a hardware wallet for ultimate security
            </p>
            <button className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors">
              Connect Wallet
            </button>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <h4 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">
          Recent Security Events
        </h4>
        <ul className="space-y-4">
          {[
            {
              event: "Failed login attempt",
              date: "2023-07-15 14:30",
              icon: <Shield className="text-red-500" />,
            },
            {
              event: "Password changed",
              date: "2023-07-10 09:15",
              icon: <Key className="text-green-500" />,
            },
            {
              event: "New device added",
              date: "2023-07-05 18:45",
              icon: <Smartphone className="text-blue-500" />,
            },
          ].map((item, index) => (
            <li
              key={index}
              className="flex items-center space-x-4 bg-gray-100 dark:bg-gray-700 p-3 rounded-lg"
            >
              {item.icon}
              <div>
                <p className="font-semibold text-gray-700 dark:text-gray-300">
                  {item.event}
                </p>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {item.date}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </motion.div>
  );

  const renderAdvancedTab = () => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="space-y-8"
    >
      <div className="bg-white dark:bg-gray-800 shadow-xl rounded-2xl p-8">
        <h3 className="text-2xl font-semibold mb-6 text-gray-800 dark:text-white">
          Advanced Settings
        </h3>
        <div className="space-y-6">
          <div>
            <label className="block text-gray-700 dark:text-gray-300 mb-2">
              API URL:
            </label>
            <input
              type="text"
              value={apiUrl}
              onChange={(e) => handleApiUrlChange(e.target.value)}
              className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
              placeholder="Enter API URL"
            />
          </div>
          <div className="flex items-center justify-between">
            <span className="text-gray-700 dark:text-gray-300">Theme</span>
            <button
              onClick={toggleTheme}
              className="flex items-center bg-gray-200 dark:bg-gray-700 p-2 rounded-full"
            >
              {theme === "light" ? (
                <Moon className="text-gray-800 dark:text-white" />
              ) : (
                <Sun className="text-yellow-400" />
              )}
            </button>
          </div>
          <div>
            <h4 className="text-lg font-semibold text-gray-700 dark:text-gray-300 mb-2">
              Notification Settings
            </h4>
            <div className="space-y-2">
              {Object.entries(notificationSettings).map(([key, value]) => (
                <div key={key} className="flex items-center justify-between">
                  <span className="text-gray-600 dark:text-gray-400">
                    {key.charAt(0).toUpperCase() + key.slice(1)} Notifications
                  </span>
                  <Switch
                    checked={value}
                    onCheckedChange={() =>
                      handleNotificationChange(key as "email" | "push" | "sms")
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 shadow-xl rounded-2xl p-8">
        <h3 className="text-2xl font-semibold mb-6 text-gray-800 dark:text-white">
          AI Model Preferences
        </h3>
        <div className="space-y-4">
          {Object.entries(aiModelPreferences).map(([model, usage]) => (
            <div key={model} className="flex items-center justify-between">
              <span className="text-gray-700 dark:text-gray-300">{model}</span>
              <div className="flex items-center space-x-2">
                {/* <ProgressBar progress={usage} className="w-32" /> */}
                {/* <span className="text-gray-600 dark:text-gray-400">{usage}%</span> */}
              </div>
            </div>
          ))}
        </div>
        <button className="mt-4 bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-purple-600 transition-colors">
          Customize AI Models
        </button>
      </div>

      <div className="bg-white dark:bg-gray-800 shadow-xl rounded-2xl p-8">
        <h3 className="text-2xl font-semibold mb-6 text-gray-800 dark:text-white">
          Blockchain Statistics
        </h3>
        <div className="grid grid-cols-2 gap-6">
          {Object.entries(blockchainStats).map(([stat, value]) => (
            <div
              key={stat}
              className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg"
            >
              <h5 className="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300">
                {stat.split(/(?=[A-Z])/).join(" ")}
              </h5>
              {/* <p className="text-2xl font-bold text-blue-500">{value}</p> */}
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 shadow-xl rounded-2xl p-8">
        <h3 className="text-2xl font-semibold mb-6 text-gray-800 dark:text-white">
          Data Management
        </h3>
        <div className="space-y-4">
          <div>
            <h4 className="text-lg font-semibold text-gray-700 dark:text-gray-300 mb-2">
              Data Export
            </h4>
            <p className="text-gray-600 dark:text-gray-400 mb-2">
              Download all your data in a portable format
            </p>
            <button className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition-colors">
              Export Data
            </button>
          </div>
          <div>
            <h4 className="text-lg font-semibold text-gray-700 dark:text-gray-300 mb-2">
              Account Deletion
            </h4>
            <p className="text-gray-600 dark:text-gray-400 mb-2">
              Permanently delete your account and all associated data
            </p>
            <button className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors">
              Delete Account
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <Toaster richColors closeButton position="top-center" />

      <ProfileNav
        name={profileName}
        role={bio}
        email={email}
        country="United States"
        subscriptionDate="January 1, 2024"
        subscriptionPlan="Pro Plan"
        profilePic={profilePic}
      />

      <div className="container mx-auto px-4 py-8 mt-20">
        <h1 className="text-4xl font-bold mb-8 text-gray-800 dark:text-white">
          Account Settings
        </h1>

        <Tabs value={activeTab} onValueChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Trigger
              value="myProfile"
              icon={<User className="mr-2 h-4 w-4" />}
            >
              My Profile
            </Tabs.Trigger>
            <Tabs.Trigger
              value="security"
              icon={<Lock className="mr-2 h-4 w-4" />}
            >
              Security
            </Tabs.Trigger>
            <Tabs.Trigger
              value="advanced"
              icon={<Settings className="mr-2 h-4 w-4" />}
            >
              Advanced
            </Tabs.Trigger>
          </Tabs.List>

          <Tabs.Content value="myProfile" className="mt-6">
            {renderMyProfileTab()}
          </Tabs.Content>

          <Tabs.Content value="security" className="mt-6">
            {renderSecurityTab()}
          </Tabs.Content>

          <Tabs.Content value="advanced" className="mt-6">
            {renderAdvancedTab()}
          </Tabs.Content>
        </Tabs>

        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          {modalContent}
        </Modal>
      </div>
    </div>
  );
};

export default ProfilePage;
