interface ChainConfig {
    rpcEndpoint: string;
    prefix: string;
    denom: string;
    feeAmount: string;
    gas: string;
  }
  
  const chainConfig: ChainConfig = {
    // rpcEndpoint: ' https://axelar-rpc.quickapi.com:443',
    rpcEndpoint: 'https://cosmos-rpc.comdex.one:443/cosmos',
    // rpcEndpoint: 'https://cosmos-rpc.quickapi.com:443',

    prefix: 'cosmos',
    denom: 'uatom', // smallest unit of ATOM
    feeAmount: '10000', // in uatom
    gas: '200000'
  };
  
export default chainConfig;