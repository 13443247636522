import React, { useState, useEffect } from "react";
// import "./UserInfoPage.scss";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { Toaster, toast } from "sonner";
// import { cn } from "../../lib/utils";
import { BorderBeam } from "../../magicui/border-beam";
// import GlassCard from "../WelcomePage/GlassCard";
// import AnimatedGridPattern from "../../magicui/animated-grid-pattern";
import background from "../../Assets/Image/Rectangle 40256.png";

import head from "../../Assets/Image/Group 1321314365.svg";
import pill from "../../Assets/Image/Group 1321314367.svg";
import {
  FiUser,
  // FiMail,
  FiLock,
  // FiShield,
  FiEye,
  FiEyeOff,
  FiLock as FiAuthenticator,
  FiCopy,
  FiArrowLeft,
} from "react-icons/fi";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import QRCode from "qrcode.react";
import "./Onboarding2.scss";
// import safebox from "../../Assets/Image/Safe_Box-removebg-preview (1).png";
// import mnemonicgene from "../../Assets/Image/mnemonicgeneration.gif";
// import { Link } from "react-router-dom";
import ShimmerButton from "../../magicui/shimmer-button";
import { Wallet } from "ethers";
import { getWalletInfoFromMnemonic } from "../../Components/NewChatWindows/walletUtils";

interface UserInfoPageProps {
  onNext: () => void;
}
declare global {
  interface Window {
    google: any;
  }
}

interface StepContent {
  heading: string;
  description: string;
}

//multiple account variable.
interface Account {
  id: string;
  name: string;
  profileImage: string;
  mnemonic: string;
  address: string;
  balance: string;
}

const UserInfoPage: React.FC<UserInfoPageProps> = ({ onNext }) => {
  const navigate = useNavigate();

  const [step, setStep] = useState<
    "initial" | "google2fa" | "newAccount" | "importAccount" | "walletSetup"
  >("initial");
  const [isSignIn, setIsSignIn] = useState(true);
  // const [showCustomForm, setShowCustomForm] = useState(false);
  const [walletname, setWalletname] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [user, setUser] = useState<any>(null);
  // const [showAuthenticator, setShowAuthenticator] = useState(false);
  const [authCode, setAuthCode] = useState("");
  const [qrCodeData, setQRCodeData] = useState("");
  // const [enableAuthenticator, setEnableAuthenticator] = useState(false);
  const [mnemonic, setMnemonic] = useState<string>("");
  const [nextEnabled, setNextEnabled] = useState<boolean>(false);
  const [mnemonicGenerated, setMnemonicGenerated] = useState<boolean>(false);
  // const [currentStep, setCurrentStep] = useState<number>(0);
  // const [twoFASecret, setTwoFASecret] = useState("");
  const [importedMnemonic, setImportedMnemonic] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  // const [currentCardIndex, setCurrentCardIndex] = useState(0);

  // const steps: StepContent[] = [
  //   {
  //     heading: "Configuring your System Agents",
  //     description:
  //       "We're setting up your AI-driven system by configuring essential system agents. These agents are being automatically set up to manage background tasks, ensuring your system operates smoothly and efficiently.",
  //   },
  //   {
  //     heading: "Creating and Securing Your Wallet",
  //     description:
  //       "We're in the process of creating a secure digital wallet for you. This vital tool for managing your assets is being set up automatically, ensuring a safe environment for your transactions and cryptocurrency storage.",
  //   },
  //   {
  //     heading: "Configuring Core Agents",
  //     description:
  //       "We're now configuring the core agents essential for your AI system's primary operations. These agents are being automatically set up to manage the main functionalities.",
  //   },
  // ];

  // const cardData = [
  //   {
  //     image:
  //       "https://solana.com/_next/image?url=https%3A%2F%2Fcdn.builder.io%2Fapi%2Fv1%2Fimage%2Fassets%252Fce0c7323a97a4d91bd0baa7490ec9139%252F9259fc4ba3594dcda2391ba6680446b7&w=750&q=75",
  //     title: "AI-Powered Insights",
  //     description:
  //       "Get real-time analytics and predictions for your blockchain transactions.",
  //     backgroundColor: "#FF6B6B",
  //   },
  //   {
  //     image:
  //       "https://solana.com/_next/image?url=https%3A%2F%2Fcdn.builder.io%2Fapi%2Fv1%2Fimage%2Fassets%252Fce0c7323a97a4d91bd0baa7490ec9139%252F9259fc4ba3594dcda2391ba6680446b7&w=750&q=75",
  //     title: "Secure Transactions",
  //     description:
  //       "Experience unparalleled security with our advanced blockchain technology.",
  //     backgroundColor: "#4ECDC4",
  //   },
  //   {
  //     image:
  //       "https://solana.com/_next/image?url=https%3A%2F%2Fcdn.builder.io%2Fapi%2Fv1%2Fimage%2Fassets%252Fce0c7323a97a4d91bd0baa7490ec9139%252F9259fc4ba3594dcda2391ba6680446b7&w=750&q=75",
  //     title: "Smart Contracts",
  //     description:
  //       "Automate your agreements with our intelligent contract system.",
  //     backgroundColor: "#45B7D1",
  //   },
  // ];

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentCardIndex((prevIndex) => (prevIndex + 1) % cardData.length);
  //   }, 5000); // Change card every 5 seconds

  //   return () => clearInterval(interval);
  // }, []);

  // useEffect(() => {
  //   setMnemonicGenerated(false);
  //   const timeout = setTimeout(generateMnemonic, 1000);
  //   return () => clearTimeout(timeout);
  // }, []);

  useEffect(() => {
    // Load accounts from local storage
    const storedAccounts = localStorage.getItem("accounts");
    if (storedAccounts) {
      setAccounts(JSON.parse(storedAccounts));
    }
  }, []);

  // useEffect(() => {
  //   if (currentStep < steps.length - 1) {
  //     const stepInterval = setTimeout(() => {
  //       setCurrentStep((prevStep) => prevStep + 1);
  //     }, 2000); // Change step every 5 seconds

  //     return () => clearTimeout(stepInterval);
  //   }
  // }, [currentStep]);

  // const generateAndStoreMnemonic = async () => {
  //   const randomMnemonic = Wallet.createRandom().mnemonic?.phrase;
  //   if (randomMnemonic) {
  //     setMnemonic(randomMnemonic);
  //     localStorage.setItem("mnemonic", randomMnemonic);
  //     try {
  //       const wallets = await getWalletInfoFromMnemonic(
  //         randomMnemonic,
  //         "Account 1"
  //       );
  //       localStorage.setItem("accounts", JSON.stringify(wallets));
  //       localStorage.setItem("defaultAccountIndex", "0");
  //       const selectedAccount = wallets[0];
  //       localStorage.setItem("mnemonic", selectedAccount.mnemonic);
  //       localStorage.setItem("account", selectedAccount.address);
  //       localStorage.setItem("balance", selectedAccount.balance);
  //       // toast.success("Mnemonic phrase created and default wallet set!");
  //     } catch (error) {
  //       console.error("Error creating default wallet:", error);
  //       toast.error("Failed to create default wallet.");
  //     }
  //     // setMnemonicGenerated(true);
  //     // setNextEnabled(true);
  //   } else {
  //     toast.error("Failed to create mnemonic phrase.");
  //   }
  // };

  const generateMnemonic = async () => {
    const randomMnemonic = Wallet.createRandom().mnemonic?.phrase;
    if (randomMnemonic) {
      setMnemonic(randomMnemonic);
      localStorage.setItem("mnemonic", randomMnemonic);
      try {
        const wallets = await getWalletInfoFromMnemonic(
          randomMnemonic,
          "Account 1"
        );
        localStorage.setItem("accounts", JSON.stringify(wallets));
        localStorage.setItem("defaultAccountIndex", "0");
        const selectedAccount = wallets[0];
        localStorage.setItem("mnemonic", selectedAccount.mnemonic);
        localStorage.setItem("account", selectedAccount.address);
        localStorage.setItem("balance", selectedAccount.balance);
        toast.success("Mnemonic phrase created and default wallet set!");
      } catch (error) {
        console.error("Error creating default wallet:", error);
        toast.error("Failed to create default wallet.");
      }
      setMnemonicGenerated(true);
      setNextEnabled(true);
    } else {
      toast.error("Failed to create mnemonic phrase.");
    }
  };

  const handleEnable2FA = async () => {
    setIsLoading(true);
    try {
      const userId = user?.id || `temp_${Date.now()}`;
      const response = await axios.post(
        "https://solana-backend.zenscape.one/proxy/api/enable_2fa",
        { userId }
      );
      setQRCodeData(response.data.qrCodeUrl);
      localStorage.setItem("2faSecret", response.data.secret);
      setStep("google2fa");
    } catch (error) {
      console.error("Error enabling 2FA:", error);
      toast.error("Failed to enable 2FA. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerify2FA = async () => {
    setIsLoading(true);
    try {
      const userId = user?.id || `temp_${Date.now()}`;
      const secret = localStorage.getItem("2faSecret");
      const response = await axios.post(
        "https://solana-backend.zenscape.one/proxy/api/verify_2fa",
        {
          userId,
          token: authCode,
          secret,
        }
      );
      if (response.data.success) {
        toast.success("2FA enabled successfully!");
        localStorage.removeItem("2faSecret");
        setStep("walletSetup");
      } else {
        toast.error("Invalid code. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying 2FA:", error);
      toast.error("Failed to verify 2FA. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // const handleNext = () => {
  //   const storedAccounts = localStorage.getItem("accounts");
  //   if (!storedAccounts) {
  //     toast.error(
  //       "Failed to create default account. Please try again the onboarding process."
  //     );
  //     return;
  //   }
  //   onNext();
  // };
  // const [confirmPassword, setConfirmPassword] = useState("");
  // const [securityQuestion, setSecurityQuestion] = useState("");
  // const [securityAnswer, setSecurityAnswer] = useState("");
  // const [selectedQuestion, setSelectedQuestion] = useState("");
  // const [answer, setAnswer] = useState("");

  const pageVariants = {
    initial: { opacity: 0, x: "100%" },
    in: { opacity: 1, x: 0 },
    out: { opacity: 0, x: "-100%" },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5,
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.google) {
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleCredentialResponse,
        });
        window.google.accounts.id.renderButton(
          document.getElementById("google-signin-button"),
          { theme: "outline", size: "large", width: 250 }
        );
        window.google.accounts.id.prompt();
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const calculatePasswordStrength = (pass: string) => {
    let strength = 0;
    if (pass.length > 6) strength++;
    if (pass.match(/[a-z]/) && pass.match(/[A-Z]/)) strength++;
    if (pass.match(/\d/)) strength++;
    if (pass.match(/[^a-zA-Z\d]/)) strength++;
    setPasswordStrength(strength);
  };

  useEffect(() => {
    calculatePasswordStrength(password);
  }, [password]);

  const handleCredentialResponse = (response: any) => {
    const decoded: any = jwtDecode(response.credential);
    setUser(decoded);
    setWalletname(decoded.name);
    localStorage.setItem("user", JSON.stringify(decoded));
    toast.success(`Welcome, ${decoded.name}!`);
    onNext();
    navigate("/home");
  };

  useEffect(() => {
    // Initialize Google One Tap
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID!, // Make sure to set this in your .env file
        callback: handleCredentialResponse,
      });
      window.google.accounts.id.prompt();
    }
  }, []);

  // useEffect(() => {
  //   if (window.google) {
  //     window.google.accounts.id.initialize({
  //       client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
  //       callback: handleCredentialResponse,
  //     });
  //     window.google.accounts.id.renderButton(
  //       document.getElementById("google-one-tap-button"),
  //       { type: "standard", theme: "outline", size: "large", width: "100%" }
  //     );
  //     window.google.accounts.id.prompt();
  //   }
  // }, []);

  const handleCreateAccount = async (e: React.FormEvent) => {
    e.preventDefault();
    // Validate that both question and answer are filled out
    if (!walletname || !password) {
      // toast.error("Please fill in all fields");
      return;
    }
    if (passwordStrength < 3) {
      toast.error("Please use a stronger password");
      return;
    }
    setIsLoading(true);
    try {
      await generateMnemonic();
      const newAccount: Account = {
        id: Date.now().toString(),
        name: walletname,
        profileImage: `https://avatars.dicebear.com/api/initials/${walletname}.svg`,
        mnemonic: mnemonic,
        address: "", // You'll need to generate this
        balance: "0",
      };
      const updatedAccounts = [...accounts, newAccount];
      setAccounts(updatedAccounts);
      localStorage.setItem("useraccounts", JSON.stringify(updatedAccounts));
      const userData = { walletname, email: user?.email };
      localStorage.setItem("user", JSON.stringify(userData));
      localStorage.setItem("walletname", walletname);
      localStorage.setItem("password", password);
      localStorage.setItem("lastActivity", "SignUp");
      toast.success("Account created successfully!");
      onNext();
      navigate("/home");
    } catch (error) {
      console.error("Error creating account:", error);
      toast.error("Failed to create account. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = async (account: Account) => {
    setSelectedAccount(account);
    // Implement your login logic here, including 2FA if enabled
    // You may need to add a new state for 2FA token input
  };

  const handleImportAccount = async () => {
    if (!importedMnemonic || !walletname || !password) {
      // toast.error("Please fill in all fields");
      return;
    }
    if (passwordStrength < 3) {
      toast.error("Please use a stronger password");
      return;
    }
    setIsLoading(true);
    try {
      const wallets = await getWalletInfoFromMnemonic(
        importedMnemonic,
        walletname
      );
      localStorage.setItem("accounts", JSON.stringify(wallets));
      localStorage.setItem("defaultAccountIndex", "0");
      const selectedAccount = wallets[0];
      localStorage.setItem("mnemonic", selectedAccount.mnemonic);
      localStorage.setItem("account", selectedAccount.address);
      localStorage.setItem("balance", selectedAccount.balance);
      localStorage.setItem("walletname", walletname);
      localStorage.setItem("password", password);
      localStorage.setItem("lastActivity", "ImportWallet");
      toast.success("Wallet imported successfully!");
      onNext();
      navigate("/home");
    } catch (error) {
      console.error("Error importing wallet:", error);
      toast.error(
        "Failed to import wallet. Please check your mnemonic and try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard!");
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setIsLoading(true);
      try {
        const userInfo = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
        );
        setUser(userInfo.data);
        setWalletname(userInfo.data.name);
        await generateMnemonic();
        toast.success(`Welcome, ${userInfo.data.name}!`);
        onNext();
        navigate("/home");
      } catch (error) {
        console.error("Error during Google Sign-Up:", error);
        toast.error("Failed to sign up with Google. Please try again.");
      } finally {
        setIsLoading(false);
      }
    },
    onError: () => {
      toast.error("Google Sign-Up failed. Please try again.");
    },
  });

  const GoogleSignInButton = () => (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className="w-full bg-white/10 text-white font-semibold py-2 px-4 border border-white/15 rounded-lg shadow-sm flex items-center justify-center space-x-2 mb-4"
      onClick={() => googleLogin()}
    >
      <img
        src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
        alt="Google logo"
        className="w-5 h-5"
      />
      <span>Sign-Up with Google</span>
    </motion.button>
  );

  // Toggle button component
  const ToggleButton = () => (
    <div className="flex justify-center mb-14">
      <button
        className={`px-16 py-2 rounded-l-lg    ${
          isSignIn
            ? "bg-white/10 text-[#00FFF2] border border-white/10"
            : "bg-white/5 text-white "
        }`}
        onClick={() => setIsSignIn(true)}
      >
        Sign Up
      </button>
      <button
        className={`px-16 py-2 rounded-r-lg ${
          isSignIn
            ? "bg-white/5 text-white "
            : "bg-white/10 text-[#00FFF2] border border-white/10"
        }`}
        onClick={() => setIsSignIn(false)}
      >
        Log In
      </button>
    </div>
  );

  const renderAccountCards = () => (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
      {accounts.map((account) => (
        <div
          key={account.id}
          className="bg-white/20 rounded-lg text-white shadow-md p-4 cursor-pointer hover:shadow-lg transition-shadow"
          onClick={() => handleLogin(account)}
        >
          <img
            src={account.profileImage}
            alt={account.name}
            className="w-16 h-16 rounded-full mx-auto mb-2"
          />
          <h3 className="text-lg font-semibold text-center">{account.name}</h3>
          <p className="text-sm text-gray-500 text-center">{account.address}</p>
        </div>
      ))}
    </div>
  );

  const renderStep = () => {
    switch (step) {
      case "initial":
        return (
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
            className=" relative w-full  h-[100%] flex fade-in-right"
          >
            <motion.form
              onSubmit={handleCreateAccount}
              className="flex justify-center flex-col w-[100%]"
              initial="hidden"
              animate="visible"
            >
              <motion.h1 className="text-4xl text-gray-200 font-semibold mb-2">
                {isSignIn ? "Create Account" : "Welcome Back"}
              </motion.h1>
              <motion.p className="text-sm text-gray-300 mb-6">
                {isSignIn ? "Sign up to get started" : "Log in to your account"}
              </motion.p>

              {renderAccountCards()}

              <ToggleButton />

              <GoogleSignInButton />

              <div className="flex items-center justify-center w-full my-4">
                <div className="flex-grow h-px bg-gray-700"></div>
                <div className="mx-4 text-sm text-gray-400 uppercase">Or</div>
                <div className="flex-grow h-px bg-gray-700"></div>
              </div>

              {isSignIn && (
                <motion.button className="w-full font-medium mb-4">
                  <div className="w-full mb-4">
                    <p className="text-sm text-gray-500 mb-2 text-left">
                      Two-Factor Authentication
                    </p>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="w-full py-2 px-4  rounded-md flex items-center justify-center bg-white/10 text-white/80 border border-white/5"
                      onClick={handleEnable2FA}
                    >
                      <FiLock className="mr-2" />
                      Enable 2FA
                    </motion.button>
                  </div>
                  <motion.div className="shadow-md mt-10 relative z-10 rounded-xl text-center cursor-pointer flex items-center justify-center">
                    <button
                      className="py-3 px-4 w-full focus:outline-none focus:shadow-outline text-center text-base font-medium leading-none tracking-tight bg-white/10 text-white/80 border border-white/5 hover:bg-white/20 transition-colors duration-200 rounded-md"
                      onClick={() => setStep("newAccount")}
                    >
                      Create New Account
                    </button>
                    {/* <BorderBeam size={90} duration={12} delay={9} /> */}
                  </motion.div>
                </motion.button>
              )}

              {!isSignIn && (
                <>
                  <motion.div className="mb-6">
                    <label
                      className="block  text-white/50   text-sm mb-2"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <div className="relative">
                      <FiLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                      <input
                        className="shadow appearance-none  w-full py-3 pl-10 pr-12 text-base leading-tight focus:outline-none focus:shadow-outline bg-white/10 text-white border border-white/5 hover:bg-white/20 transition-colors duration-200  rounded-md"
                        id="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <button
                        type="button"
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <FiEyeOff /> : <FiEye />}
                      </button>
                    </div>
                    {isSignIn && (
                      <>
                        <div className="mt-2 h-2 bg-gray-200 rounded-full">
                          <div
                            className={`h-full rounded-full ${
                              passwordStrength === 0
                                ? "bg-red-500"
                                : passwordStrength === 1
                                ? "bg-orange-500"
                                : passwordStrength === 2
                                ? "bg-yellow-500"
                                : passwordStrength === 3
                                ? "bg-green-500"
                                : "bg-blue-500"
                            }`}
                            style={{ width: `${passwordStrength * 25}%` }}
                          ></div>
                        </div>
                        <p className="text-xs text-gray-500 mt-1">
                          {passwordStrength === 0 && "Very weak"}
                          {passwordStrength === 1 && "Weak"}
                          {passwordStrength === 2 && "Medium"}
                          {passwordStrength === 3 && "Strong"}
                          {passwordStrength === 4 && "Very strong"}
                        </p>
                      </>
                    )}
                  </motion.div>

                  {/* <motion.div  className="mb-6">
                <label className="block text-gray-700 text-sm mb-2" htmlFor="securityQuestion">
                  Security Question
                </label>
                <div className="relative">
                  <FiShield className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <select
                    className="shadow appearance-none border rounded-lg w-full py-4 pl-10 pr-6 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-200"
                    id="securityQuestion"
                    value={securityQuestion}
                    onChange={(e) => setSecurityQuestion(e.target.value)}
                  >
                    <option value="">Select a security question</option>
                    <option value="mother">What is your mother's maiden name?</option>
                    <option value="pet">What was the name of your first pet?</option>
                    <option value="city">In what city were you born?</option>
                    <option value="movie">What is your favorite movie?</option>
                    <option value="teacher">What is the name of your favorite teacher?</option>
                  </select>
                </div>
              </motion.div>

              <motion.div  className="mb-6">
                <label className="block text-gray-700 text-sm mb-2" htmlFor="answer">
                  Answer
                </label>
                <input
                  className="shadow appearance-none border rounded-lg w-full py-4 px-6 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-200"
                  id="answer"
                  type="text"
                  placeholder="Enter your answer"
                  value={securityAnswer}
                  onChange={(e) => setSecurityAnswer(e.target.value)}
                />
              </motion.div> */}

                  <motion.div className="shadow-md mt-4 relative z-10 rounded-xl text-center cursor-pointer flex items-center justify-center">
                    <button
                      className="py-3 px-4 w-full focus:outline-none focus:shadow-outline text-center text-base font-medium leading-none tracking-tight bg-teal-500 text-white border border-white/5 hover:bg-white/20 transition-colors duration-200  rounded-md"
                      type="submit"
                    >
                      {isSignIn ? "Create Account" : "Log In"}
                    </button>
                    {/* <BorderBeam size={90} duration={12} delay={9} /> */}
                  </motion.div>
                </>
              )}
            </motion.form>
          </motion.div>
        );

      case "google2fa":
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            <h2 className="text-2xl font-semibold mb-4">Scan QR Code</h2>
            <div className="flex justify-center mb-4">
              {qrCodeData && <QRCode value={qrCodeData} size={200} />}
            </div>
            <p className="text-sm text-gray-600 mb-4">
              Scan this QR code with Google Authenticator app, then enter the
              6-digit code below.
            </p>
            <input
              type="text"
              value={authCode}
              onChange={(e) => setAuthCode(e.target.value)}
              placeholder="Enter 6-digit code"
              className="w-full px-3 py-2 border rounded-lg mb-4"
            />
            <ShimmerButton
              className="w-full"
              onClick={handleVerify2FA}
              disabled={isLoading}
            >
              {isLoading ? "Verifying..." : "Verify Code"}
            </ShimmerButton>
          </motion.div>
        );

      case "newAccount":
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            <h2 className="text-4xl font-semibold text-white mb-14">
              Create New Account
            </h2>
            <button
              className="w-full mb-8 py-3 px-4 focus:outline-none focus:shadow-outline text-center text-base font-medium leading-none tracking-tight bg-white/10 text-white border border-white/5 hover:bg-white/20 transition-colors duration-200  rounded-md"
              onClick={() => {
                generateMnemonic();
                setStep("walletSetup");
              }}
            >
              Quick Create Account
            </button>
            <button
              className="w-full mb-4 py-3 px-4 focus:outline-none focus:shadow-outline text-center text-base font-medium leading-none tracking-tight bg-white/10 text-white border border-white/5 hover:bg-white/20 transition-colors duration-200  rounded-md"
              onClick={() => setStep("importAccount")}
            >
              Import Account
            </button>
          </motion.div>
        );

      case "importAccount":
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            <h2 className="text-4xl font-semibold text-white mb-14">
              Import Account
            </h2>
            <textarea
              value={importedMnemonic}
              onChange={(e) => setImportedMnemonic(e.target.value)}
              placeholder="Enter your mnemonic phrase"
              className="w-full mb-4 py-7 px-6 focus:outline-none focus:shadow-outline text-center text-base font-normal leading-none tracking-tight bg-white/10 text-white border border-white/5 hover:bg-white/20 transition-colors duration-200  rounded-md relative h-32"
            />
            {renderWalletSetupFields()}
            <button
              className="w-full mb-4 py-3 px-4 focus:outline-none focus:shadow-outline text-center text-base font-medium leading-none tracking-tight bg-teal-500 text-gray-900 border border-white/5 hover:bg-white/20 transition-colors duration-200  rounded-md"
              onClick={handleImportAccount}
              disabled={isLoading}
            >
              {isLoading ? "Importing..." : "Import Account"}
            </button>
          </motion.div>
        );

      case "walletSetup":
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            <h2 className="text-4xl text-gray-200 font-semibold mb-8">
              Set Up Your Wallet
            </h2>
            {mnemonic && (
              <div className="mb-4">
                <p className="block text-gray-300  font-semibold text-sm mb-2">
                  Your Mnemonic Phrase:
                </p>
                <div className="w-full mb-4 py-7 px-6 focus:outline-none focus:shadow-outline text-center text-base font-normal leading-none tracking-tight bg-white/10 text-white border border-white/5 hover:bg-white/20 transition-colors duration-200  rounded-md relative">
                  <p className="break-all">{mnemonic}</p>
                  <button
                    onClick={() => copyToClipboard(mnemonic)}
                    className="absolute top-2 right-2 text-blue-500 hover:text-blue-700"
                  >
                    <FiCopy />
                  </button>
                </div>
                <p className="text-sm text-yellow-500 mt-2">
                  Please store this phrase securely.
                </p>
              </div>
            )}
            {renderWalletSetupFields()}
            <button
              className="w-full mb-4 py-3 px-4 focus:outline-none focus:shadow-outline text-center text-base font-medium leading-none tracking-tight bg-teal-500 text-gray-900 border border-white/5 hover:bg-white/20 transition-colors duration-200  rounded-md"
              onClick={handleCreateAccount}
              disabled={isLoading}
            >
              {isLoading ? "Creating Account..." : "Create Account"}
            </button>
          </motion.div>
        );
    }
  };

  const renderWalletSetupFields = () => (
    <>
      <div className="mb-4">
        <label
          className="block text-gray-300 text-sm mb-2"
          htmlFor="walletname"
        >
          User Name
        </label>
        <div className="relative">
          <FiUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            className="shadow appearance-none border w-full py-3 px-10 bg-white/10 text-white/80  border-white/5 hover:bg-white/20 transition-colors duration-200 rounded-md leading-tight focus:outline-none focus:shadow-outline"
            id="walletname"
            type="text"
            placeholder="Enter User name"
            value={walletname}
            onChange={(e) => setWalletname(e.target.value)}
          />
        </div>
      </div>
      <div className="mb-4 mt-2">
        <label className="block text-gray-300 text-sm mb-2" htmlFor="password">
          Password
        </label>
        <div className="relative">
          <FiLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            className="shadow appearance-none border  w-full py-3 px-10 bg-white/10 text-white/80  border-white/5 hover:bg-white/20 transition-colors duration-200 rounded-md leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type={showPassword ? "text" : "password"}
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="button"
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <FiEyeOff /> : <FiEye />}
          </button>
        </div>
        <div className="mt-2">
          <div className="h-2 bg-gray-500 rounded-full">
            <div
              className={`h-full rounded-full ${
                passwordStrength === 0
                  ? "bg-red-500"
                  : passwordStrength === 1
                  ? "bg-orange-500"
                  : passwordStrength === 2
                  ? "bg-yellow-500"
                  : passwordStrength === 3
                  ? "bg-green-500"
                  : "bg-blue-500"
              }`}
              style={{ width: `${passwordStrength * 25}%` }}
            ></div>
          </div>
          <p className="text-xs text-gray-500 mt-1">
            {passwordStrength === 0 && "Very weak"}
            {passwordStrength === 1 && "Weak"}
            {passwordStrength === 2 && "Medium"}
            {passwordStrength === 3 && "Strong"}
            {passwordStrength === 4 && "Very strong"}
          </p>
        </div>
      </div>
    </>
  );

  return (
    <>
      <motion.div
        className="flex h-screen  bg-gradient-to-br from-slate-900/20 via-[#031316]/80 to-black bg-clip-padding userinfo overflow-hidden"
        initial="initial"
        animate="in"
        exit="out"
      >
        <Toaster richColors closeButton position="top-center" />

        <div className="flex flex-col justify-center lg:flex-row h-screen glass-effect text-slate-900 w-screen">
          <motion.div
            className="relative w-full lg:w-1/2 flex items-center justify-center"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3 }}
          >
            {/* Base */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.5 }}
              className="flex justify-center items-center w-[60%]  h-full"
            >
              <div className=" relative rounded-3xl flex flex-col w-full justify-center">
                {step !== "initial" && (
                  <button
                    onClick={() => setStep("initial")}
                    className="absolute -top-14 left-0 text-gray-200 hover:text-gray-300 px-4 py-1 border-2 border-white/70 rounded-2xl"
                  >
                    <FiArrowLeft size={20} />
                  </button>
                )}
                <AnimatePresence mode="wait">{renderStep()}</AnimatePresence>
              </div>
            </motion.div>
          </motion.div>

          <div id="google-one-tap-button" className="mb-4"></div>

          {/* Right side box */}
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
            className="flex justify-center items-center p-4 md:p-6 w-full lg:w-[45%] h-screen relative  "
          >
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
              className="bg-[#000405] relative rounded-md shadow-lg px-8  w-full h-[100%] flex fade-in-right overflow-hidden  items-center justify-center"
            >
              {/* Network Visualization Background */}
              <div className="absolute inset-0 opacity-10">
                {/* Add a canvas or SVG here for the network visualization */}
                <img
                  src={background}
                  alt="Background"
                  className="object-cover w-full h-full"
                />
              </div>

              <motion.div
                className="relative w-400 h-96"
                animate={{
                  y: [0, -10, 0],
                  rotateY: [0, 5, 0],
                }}
                transition={{
                  duration: 5,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              >
                <img
                  src={head}
                  alt="3D Head Projection"
                  className="w-full h-full mt-20"
                />
              </motion.div>

              {/* Floating Elements */}
              <motion.div
                className="absolute top-1/4 left-8 bg-gray-800 gap-3 text-gray-300 px-4 py-2 rounded-md border-teal-500 border flex items-center"
                whileHover={{ scale: 1.1 }}
                animate={{
                  x: [0, 10, 0],
                  y: [0, -5, 0],
                }}
                transition={{
                  duration: 4,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              >
                <img src={pill} alt="Floating Elements" />
                AI Insights
              </motion.div>
              <motion.div
                className="absolute top-16 right-1/3 bg-gray-800 gap-3 text-gray-300 px-4 py-2 rounded-md border-teal-500 border flex items-center"
                whileHover={{ scale: 1.1 }}
                animate={{
                  x: [0, -10, 0],
                  y: [0, 5, 0],
                }}
                transition={{
                  duration: 4.5,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              >
                <img src={pill} alt="Floating Elements" />
                Secure Transactions
              </motion.div>
              <motion.div
                className="absolute top-1/3 right-12 bg-gray-800 gap-3 text-gray-300 px-4 py-2 rounded-md border-teal-500 border flex items-center"
                whileHover={{ scale: 1.1 }}
                animate={{
                  x: [0, 5, 0],
                  y: [0, -10, 0],
                }}
                transition={{
                  duration: 5,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              >
                <img src={pill} alt="Floating Elements" />
                Smart Contract
              </motion.div>
              <BorderBeam size={250} duration={12} delay={9} />
            </motion.div>
          </motion.div>
        </div>
        <Toaster richColors closeButton position="top-center" />
      </motion.div>
    </>
  );
};

export default UserInfoPage;
